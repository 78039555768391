import * as React from 'react';

export default class AccordionItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            maxHeight: '0',
        }
    }

    handleClick() {
        const {onSelected} = this.props;
        this.setState({maxHeight: `${this.content.scrollHeight}px`});
        onSelected();
    }

    render() {
        const {active, title, body, key} = this.props;

        return (
            <div
                key={key}
                className="accordion_item"
                onClick={() => this.handleClick()}
            >
                <div className="accordion_item_header">
                    <div
                        className={`accordion_item_title${
                            active ? ' accordion_item_title--active' : ''
                        }`}
                    >
                        <span>{title}</span>
                    </div>
                </div>
                <div
                    ref={(elm) => {
                        this.content = elm;
                    }}
                    style={active ? this.state : {maxHeight: '0'}}
                    className={`accordion_item_body`}
                >
                    <div
                        className="accordion_item_container"
                        dangerouslySetInnerHTML={{__html: body}}
                    />
                </div>
            </div>
        );
    }
}
