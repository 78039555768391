import * as React from 'react';
import {useSelector} from 'react-redux';
import _ from 'lodash';

import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {ProductImage} from '@partssourceinc/react-ui-core';
import {leadTimeDescription, leadTimeDescriptionRepair} from 'productUtility';

import {formatMoney} from 'utility';

const SingleCatalogItem = (props) => {
    const user = useSelector(state => state.user);
    const hideNoEsdMessaging = useSelector(state => state.system.siteSettings.hideNoEsdMessaging);

    const item = {attributes: [], thumbnailUrl: '', thumbnailAltText: 'Text', detailUrl: '', title: 'Text', partNumber: 'Aaa', matchReason: 1, options: []};

    const hideOemPricing = user && user.settings && user.settings.hideOEMPricing;
    const productCrumbs = '';

    const {attributes, thumbnailUrl, thumbnailAltText, id, detailUrl, title, partNumber, matchReason, compatibleWith, options, isVariantProduct} = props.item;

    let sortedOptions = _.orderBy(options.filter(x => x.price > 0 || (x.isContractProOption && x.price >= 0)), ['price'], ['asc']);
    let option = sortedOptions[0];
    let contractProOption = sortedOptions.filter(o => o.isContractProOption === true);

    let sortedLeadTimeOptions = _.orderBy(options, ['leadTimeDays'], ['asc']);
    let leadTimeOption;

    if (!sortedOptions.every(o => o.backorderDateEta)) {
        leadTimeOption = sortedLeadTimeOptions.filter(o => !o.backorderDateEta)[0];
    } else {            
        leadTimeOption = sortedLeadTimeOptions[0];
    }

    const countOptions = options && options.length;

    const renderAttribute = (name, value) => {
        return name.toLowerCase().startsWith('feature')
            ? (<li key={`${name}`}>{value}</li>)
            : (<li key={`${name}`}>{`${name}: ${value}`}</li>);
    }

    function onProductClick() {
        props.closeParentModal();
    }
        
    return (
        <div>
            <div style={{paddingTop: '20px', paddingBottom: '20px', fontSize: '16px', fontWeight: '600'}}> This item is available for purchase from our catalog</div>
            <div key={id} className="catalog_rfq-search-result no-right-gutter">
                <div className="image-wrapper">
                    <Link to={{pathname: detailUrl, breadCrumbs: productCrumbs, showBackButton: true}} key={id} onClick={() => this.onProductClick(item, (option || {}).price)}>
                        <ProductImage url={thumbnailUrl} altText={thumbnailAltText || title} style={{width: '200px'}} />
                    </Link>
                </div>
                <div className="catalog_rfq-search-result_details">
                    {
                        contractProOption && contractProOption.length > 0 ?
                            <div style={{marginBottom: '10px'}} className="contract-badge"><span>CONTRACT</span></div> : 
                            null
                    }
                    <Link
                        to={{
                            pathname: detailUrl,
                            breadCrumbs: productCrumbs,
                            showBackButton: true,
                        }}
                        key={id} onClick={() => onProductClick(item, (option || {}).price)}>
                        {title}
                    </Link>
                    <div className="result-part-info">
                        <div className="catalog_search-result_oem-info">
                            {partNumber ? <div>OEM #: {partNumber}</div> : null}
                            {matchReason ? <div className="bold">{matchReason}</div> : null}
                        </div>
                        <ul>
                            {Object.keys(attributes || {}).map(k => renderAttribute(k, attributes[k]))}
                        </ul>
                    </div>
                    <div className="result-price-info">
                        {sortedOptions && (sortedOptions.length > 1 || isVariantProduct) ? <div className="starting-at">Starting at</div> : null}

                        {sortedOptions && sortedOptions.length > 0 ?
                            <div>
                                <span className="price">
                                    {formatMoney(option.price)}
                                    {option.unitOfMeasurement ?
                                        <span className="uom"> / {option.unitOfMeasurement.toLowerCase().replace(/[()]/g, '')}</span>
                                        : null}
                                </span>
                                {item.outrightListPrice > option.price && !hideOemPricing ?
                                    <span className="savings-wrapper">
                                        <span className="oem-price">{formatMoney(item.outrightListPrice)}</span>
                                            Savings {formatMoney(item.outrightListPrice - option.price)}
                                        <span className="percent">({Math.round(((item.outrightListPrice - option.price) / item.outrightListPrice) * 100)}%)</span>
                                    </span> : null}

                                {countOptions > 1 ? <div className="buying-options">Total Buying Options: <span>{countOptions}</span></div> : null}

                                {
                                    !option.suppressLeadTime 
                                        ? <span className="lead-time">
                                            {option.purchaseChoice === 3 
                                                ? leadTimeDescriptionRepair(leadTimeOption.leadTimeDays)
                                                : leadTimeDescription(leadTimeOption.estimatedShipDate, leadTimeOption.backorderDateEta, leadTimeOption.shipCutoffUtc, hideNoEsdMessaging)}
                                        </span>
                                        : null
                                }

                            </div> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SingleCatalogItem;
