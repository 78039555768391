import * as React from 'react';
import ErrorMenu from 'components/cms/ErrorMenu';

import 'less/error.less';
import {BrComponent} from '@bloomreach/react-sdk';

export default class Error extends React.Component {
    render() {
        return (<div className="error-page">
            <div className="center-block" style={{textAlign: 'center'}}>
                <BrComponent path={'error/container'} />
            </div>
            <BrComponent path={'error/error-menu'}>
                <ErrorMenu />
            </BrComponent>
        </div>)
    }
}
