import {useEffect, useContext} from 'react';
import {useDispatch} from 'react-redux';
import * as ParamsStore from 'stores/Params';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';

const CmsResource = (props) => {
    const component = useContext(BrComponentContext);
    const page = useContext(BrPageContext);
    const models = component.getModels() || {};
    const resources = models && models.bundle && page.getContent(models.bundle) || null;
    const dispatch = useDispatch();
    const {setResources} = ParamsStore.actionCreators;

    useEffect(() => {
        dispatch(setResources(resources));
    }, [resources]);

    return null;
}

export default CmsResource;
