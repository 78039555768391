import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import AddToCartConfirmation from 'components/AddToCartConfirmation';
import AddressDialog, {AddressFormType} from 'components/AddressDialog';
import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';
import {QuantitySelector, TextField, Checkbox, FileUploader, TextFieldSelect, RadioButtonList, Loader, Button, brandFont, theme} from '@partssourceinc/react-ui-core';
import * as CartStore from 'stores/Cart';
import * as CompaniesStore from 'stores/Companies';
import * as UserStore from 'stores/User';
import * as ParamsStore from 'stores/Params';
import {getCompanyAndVendorFields} from 'productUtility';
import {logEvent, getFormattedPhoneNumber} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import 'less/requestquote.less';
import SingleCatalogItem from './SingleCatalogItem';
import styled from 'styled-components';
import $ from 'jquery';

const Footer = styled.div`    
    border-top: 1px solid rgb(151, 151, 151);
    height: 2px;
    margin-top: 28px;
    text-align: right;

    > a, button {
        margin-top: 18px;
    }
`;

const SecondaryLink = styled.a`
    ${brandFont('14px')};
    color: ${theme.grey3};
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    margin-right: 20px !important;
    outline: none;

    &:hover {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }

    &:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }

    &:active:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }
`;

const LoaderContainer = styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ExclamationIcon = styled.span`
    background: url('/images/icn_orange-exclamation@2x.png');
    background-size: 30px;
    margin-right: 10px;
    vertical-align: middle;
    position:relative;
    height: 30px;
    width: 30px;
    display: inline-block;
`;

const InvalidPhotoMessage = styled.span`
    margin-top: 20px;
    display: flex;
    justify-content: start;
    color: #f00;
    font-size: 14px;
`

// CT.CT_SLS_ORDER_TYPES
export const View = {
    QuoteSelection: 0,
    Part: 1,
    DepotRepair: 4,
    AddToCartConfirmation: 5,
};

export const OnSiteServiceType = {
    UnSelected: 'Unselected',
    SingleAsset: 'SingleAsset',
    MultiAsset: 'MultiAsset',
};

@withRouter
@connect(
    state => ({user: state.user, cart: state.cart, sessionStorageAvailable: state.network.sessionStorageAvailable, oems: state.companies.oems, params: state.params}),
    _.merge({}, CartStore.actionCreators, UserStore.actionCreators, CompaniesStore.actionCreators)
)
export default class RequestQuote extends React.Component {
    static propTypes = {
        label: PropTypes.string,
        onCancel: PropTypes.func.isRequired,
        getCompanyFields: PropTypes.func,
        user: UserStore.StateShape,
        cart: CartStore.StateShape,
        oems: CompaniesStore.OemsShape,
        ...CartStore.ActionShape,
        ...UserStore.ActionShape,
        ...CompaniesStore.ActionShape,
        ...ReactRouterPropTypes,
        params: ParamsStore.StateShape,
        // showSingleView: PropTypes.oneOf([View.Part, View.DepotRepair]),
    };

    constructor(props) {
        super(props);

        this.state = {
            acceptFiles: ['image/jpeg', 'image/png'],
            oemId: null,
            oemName: null,
            selectedRequester: null,
            requesterList: [],
            selectedFacility: null,
            partNum: '',
            partDescr: '',
            productTypeId: null,
            partQty: 1,
            showErrors: false,
            reqFields: [],
            isFileSelected: false,
            product: {},
            imagePreviewUrl: '',
            isUrgency: false,
            currentView: View.QuoteSelection,
            models: [],
            selectedCategory: {},
            selectedModel: {},
            serviceType: '',
            turnaroundTime: '',
            serviceNeeded: '',
            locationDetail: '',
            rfqSetup: {
                serviceCategories: [],
                serviceTypes: [],
                turnaroundTimes: [],
                repairCategories: [],
            },
            saving: false,
            additionalServiceFields: [],
            showShippingFields: false,
            selectedShipSpeedId: '4',
            shippingAddresses: {},
            selectedShipAddress: {},
            ShowAddressDialog: false,
            AddressEdit: {},
            AddressFormDisplay: AddressFormType.AddEdit,
            ProceedAfterValidation: false,
            catalogItem: null,
            rfqPartChecked: false,
            allowRFQ: false,
            ruleId: null,
            isLoading: false,
            disableConfirm: false,
            showShipMethodDescription: false,
            repairFields: [],
            addLoaner: false,
            onSiteServiceType: OnSiteServiceType.UnSelected,
            invalidField: [],
            isNoPartnumber: false,
            isMinReqError: false,
            equipmentSerial: '',
            equipmentSerialError: false,
            qtErrorMessage: '',
            workOrderFields: [],
        };

        this.closePopup = this.closePopup.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.updateStatePart = this.updateStatePart.bind(this);
        this.processFiles = this.processFiles.bind(this);
        this.handleAddToQuoteCancel = this.handleAddToQuoteCancel.bind(this);
        this.handleAddToCartConfirm = this.handleAddToCartConfirm.bind(this);
        this.onSelectFacility = this.onSelectFacility.bind(this);
        this.onFieldUpdate = this.onFieldUpdate.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.onAddressSave = this.onAddressSave.bind(this);
    }
    getFacilitySettings = (facility) => {
        const {loadFacilityAddresses} = this.props;
        Promise.all([axios.get(`/SettingsService/api/v1/companySettings/${facility.facilityId}`),
            loadFacilityAddresses(facility.facilityId)]).then(x => {
            let addss = x[1].response.data.shippingAddresses;
            this.setState({showShippingFields: x[0].data.settings.fullyBurdenedQuoting, shippingAddresses: addss});
        });
    }
    componentDidMount() {
        const {user: {info, facility, facilities}, oems, getOems, showSingleView, sessionStorageAvailable} = this.props;
        let sFacility = (facility && facility.facilityId) ? facility.facilityId : 0;
        const companyFields = (sessionStorageAvailable && sessionStorage.fields) ? getCompanyAndVendorFields(false, false, null,sFacility) : [];

        let selectedFacility = facility.showTfyp ? facility : (facilities.filter(x => x.showTfyp) || {})[0];
        this.setState({
            selectedRequester: info,
            reqFields: companyFields.filter(x => x.isRequired && !x.vendorField),
            selectedFacility: selectedFacility,
        });

        if ((oems || []).length === 0) {
            getOems();
        }

        if (facility.facilityId && facility.facilityId > 0) {
            axios.get(`/ShoppingService/api/v1/company/contacts/${facility.facilityId}`).then(x => {
                this.setState({requesterList: x.data});
            });
        }

        this.getFacilitySettings(selectedFacility);

        if (!companyFields.filter(x => x.isRequired && !x.vendorField).some(x => x.fieldUid === '11111111-1111-1111-1111-111111111111')) {
            let additionalServiceFields = [];
            let assetField = companyFields.find(x => x.fieldUid === '11111111-1111-1111-1111-111111111111');
            if (assetField) {
                assetField.isRequired = true;
            } else {
                assetField = {
                    fieldType: 1,
                    fieldUid: '11111111-1111-1111-1111-111111111111',
                    isDefault: true,
                    isRequired: true,
                    lineItemId: 0,
                    orderId: 0,
                    prompt: 'Asset Id',
                    value: '',
                    usage: 0,
                    companyField: true,
                }
            }    
            additionalServiceFields.push(assetField);
            this.setState({additionalServiceFields});
        }

        // Hard Code Repair Fields
        let repairFields = [];
        let serialNo = companyFields.filter(x => x.fieldUid === '44444444-4444-4444-4444-444444444444' && x.isRequired == false);
        let repairReason = companyFields.filter(x => x.fieldUid === '77777777-7777-7777-7777-777777777777' && x.isRequired == false);

        // hard code work order number
        let workOrderFields = [];
        let workOrder = companyFields.filter(x => x.fieldUid === '22222222-2222-2222-2222-222222222222');
        if (workOrder.length > 0) {
            workOrderFields.push(workOrder[0]);
        }
        this.setState({workOrderFields});


        if (serialNo.length > 0) {
            serialNo[0].isRequired = true;
            repairFields.push(serialNo[0]);
        }

        if (repairReason.length > 0) {
            repairReason[0].isRequired = true;
            repairFields.push(repairReason[0]);
        } else {
            repairFields.push({
                fieldType: 1,
                fieldUid: '77777777-7777-7777-7777-777777777777',
                isDefault: true,
                isRequired: true,
                lineItemId: 0,
                orderId: 0,
                prompt: 'Repair Reason',
                value: '',
                usage: 32,
                vendorField: true,
            });

        }
        this.setState({repairFields});

        if (showSingleView) {
            this.setState({currentView: showSingleView});
        }

        this.loadData();

    }

    loadData = () => {
        axios.get(`/ShoppingService/api/v1/quotes/ods`).then(x => {
            this.setState(
                {rfqSetup: x.data});
        });
    }

    closePopup = () => {
        // const { onCancel } = this.props;
        // onCancel();
    }

    onSelectFacility = (facility) => {
        const {selectedRequester: {contactId}} = this.state;
        this.setState({selectedFacility: facility});

        axios.get(`/ShoppingService/api/v1/company/contacts/${facility.facilityId}`).then(x => {
            const selectedRequester = x.data.find(y => y.contactId === contactId);
            this.setState({requesterList: x.data, selectedRequester});
        });
        this.getFacilitySettings(facility);
    }

    onModelChange = (model) => {
        this.setState({selectedModel: model});
    }

    onSubmit = (e, viewOptions = false) => {
        e.target.blur();
        const {loadQuotes, params, location: {pathname}, searchRequestQuote, user: {settings}} = this.props;
        const {imagePreviewUrl, oemId, selectedRequester, selectedFacility, partQty, isUrgency, onSiteServiceType,
            oemName, selectedCategory, selectedModel, currentView, serviceType, turnaroundTime, serviceNeeded, locationDetail,
            additionalServiceFields, showShippingFields, selectedShipSpeedId, selectedShipAddress, rfqPartChecked, catalogItem, repairFields, addLoaner, workOrderFields} = this.state;
        let {partNum, partDescr,reqFields, equipmentSerial, isNoPartnumber} = this.state;
        let minReq = (settings.isProgramAccountMember) ? 1 : 2;
        let quoteType = (currentView === View.Part) ? 1 : (currentView === View.DepotRepair) ? 32 : 0;
        if (currentView === View.Part && isNoPartnumber) {
            let reqCount = 0;
            if (equipmentSerial)                        
                reqCount++;                        
            if (selectedModel && selectedModel.modelId > 0)                        
                reqCount++;                        
            if (imagePreviewUrl && imagePreviewUrl !== '')   
                reqCount++;
            if (reqCount < minReq) {
                this.setState({isMinReqError: true, showErrors: true});
                return;
            } else {
                this.setState({isMinReqError: false});
            }
        }
        if (currentView === View.Part && !rfqPartChecked) {
            if (!oemId || (!partNum && !isNoPartnumber) || !selectedFacility || !(selectedFacility.facilityId > 0) || !partDescr) {
                this.setState({showErrors: true});
            } else {
                if (currentView === View.Part && !rfqPartChecked) {                    
                    this.onPartRFQCheck();
                }
                // reqFields override
                let arg = {
                    CustomerId: selectedFacility.facilityId,
                    RequestorId: selectedRequester.contactId,
                    VendorId: 0,
                    PurchaseChoice: 0,
                    FieldUsage: quoteType,
                    PartNumber: partNum || 'N/A',
                }        
                axios.post('/ShoppingService/api/v1/lineItems/requredfields', arg)
                    .then(x => {                
                        if (x.data.fields && x.data.fields.length > 0) {  
                            let reqs = Array.from(x.data.fields);
                            if (workOrderFields.length > 0) {
                                let field = reqs.find(x => x.fieldUid === '22222222-2222-2222-2222-222222222222');
                                if (field) { 
                                    field.value = workOrderFields[0].value;
                                }
                            }
                            if (isNoPartnumber && equipmentSerial) 
                                this.setState({reqFields: reqs.filter(r => r.fieldUid !== '44444444-4444-4444-4444-444444444444')}); 
                            else                   
                                this.setState({reqFields: reqs});   
                        }                                              
                    });
                return;
            }
        } else if ((currentView === View.Part && rfqPartChecked && catalogItem && viewOptions)) {
            const {history} = this.props;
            history.push(catalogItem.detailUrl);
            this.closePopup();
        }

        const hasAssetMissingFields = reqFields.filter(x => !x.value && x.isRequired).length > 0;
        let hasInvalidFormat = false;
        let invalidFormatField = [];
        if (reqFields && reqFields.length > 0) {
            reqFields.forEach(f => {
                if (f.formatRegex) {
                    let rg = new RegExp(`^${f.formatRegex}$`, '');
                    if (!rg.test(f.value)) {
                        hasInvalidFormat = true;
                        invalidFormatField.push(f);
                        return;
                    }
                }
            })
        }
        const WORK_ORDER_KEY = '22222222-2222-2222-2222-222222222222';
        const ASSET_KEY = '11111111-1111-1111-1111-111111111111';
        const SERIAL_KEY = '44444444-4444-4444-4444-444444444444';

        const hasPopulatedAsset = (reqFields.some(x => x.fieldUid == ASSET_KEY && x.value)) || (additionalServiceFields && additionalServiceFields.some(x => x.fieldUid == ASSET_KEY && x.value));

        if (!oemId || !selectedRequester || !selectedFacility || !(selectedFacility.facilityId > 0) || hasAssetMissingFields || hasInvalidFormat) {
            this.setState({showErrors: true, invalidField: invalidFormatField});
            return;
        } else {
            this.setState({invalidField: [], equipmentSerialError: false, isMinReqError: false});
        }

        if (currentView === View.Part && ((!partNum && !isNoPartnumber) || !partDescr || !partQty)) {
            this.setState({showErrors: true});
            return;
        }

        if (currentView === View.Part && showShippingFields) {
            if (!selectedShipAddress.addressId) {
                this.setState({showErrors: true});
                return;
            }
            if (!selectedShipAddress.isValidated && settings.canAddAddress) {
                this.setState({
                    ShowAddressDialog: true,
                    AddressFormDisplay: AddressFormType.Validate,
                    AddressEdit: selectedShipAddress,
                    ProceedAfterValidation: true,
                });
                return;
            }
        }

        const {user: {info: {email}}} = this.props;      
        
        let fields = _.merge(_.pickBy({
            [ASSET_KEY]: params.asset,
            [WORK_ORDER_KEY]: params.wo,
            [SERIAL_KEY]: (params.serial || equipmentSerial),
        }, _.identity), reqFields.reduce(function (map, obj) {
            map[obj.fieldUid] = obj.value;
            return map;
        }, additionalServiceFields.reduce(function (map, obj) {
            map[obj.fieldUid] = obj.value;
            return map;
        }, {})));
        
        if (currentView === View.Part && isNoPartnumber) {
            if (equipmentSerial)
                fields['44444444-4444-4444-4444-444444444444'] = equipmentSerial;
        }
        const quoteRequest = {
            PartNumber: partNum || 'N/A',
            OemId: oemId,
            FacilityId: selectedFacility.facilityId,
            Description: partDescr,
            Quantity: partQty,
            RequestorId: selectedRequester.contactId,
            Facility: selectedFacility.facilityName,
            UploadedImage: imagePreviewUrl || '',
            EmailAddress: email,
            GroupId: selectedFacility.groupId,
            Fields: fields,
            PhoneNumber: selectedFacility.phoneNumber,
            UrgencyId: isUrgency,
            CustomerOrderKey: params.wo_id,
            QuoteTypeId: parseInt(currentView),
            LocationDetail: locationDetail,
            Category: selectedCategory.name,
            ServiceType: serviceType.name,
            TurnaroundTime: turnaroundTime.name,
            ServiceNeeded: serviceNeeded,
            ModelId: (selectedModel || {}).modelId,
            Model: (selectedModel || {}).model,
            Modality: selectedCategory.modality,
            ShippingSpeedId: showShippingFields ? selectedShipSpeedId : '',
            ShippingAddressId: showShippingFields ? selectedShipAddress.addressId : '',
            AddLoaner: addLoaner,
            RequestType: onSiteServiceType,
        };

        this.setState({saving: true});
        axios.post('/ShoppingService/api/v1/quotes/request', quoteRequest)
            .then((x) => {
                loadQuotes();
                logEvent('Request Quote', {
                    'Item Number': partNum,
                    'Qty': partQty,
                    'OEM': oemName,
                    'Ref #': x.data,
                    'Source': searchRequestQuote ? 'Search result' : 'Header',
                    'Referrer': pathname,
                });
                this.setState({currentView: View.AddToCartConfirmation, saving: false, qtErrorMessage:''});
            }).catch(() => this.setState({saving: false, qtErrorMessage: 'There was an error submitting your quote to the server'}));
    }

    onFieldUpdate = (fields) => {      
        const {additionalServiceFields, reqFields, repairFields} = this.state;

        if (additionalServiceFields.length > 0) {
            fields.forEach(d => {
                let field = additionalServiceFields.find(
                    x => x.fieldUid && x.fieldUid === d.fieldUid);
                if (field) {
                    field.value = d.value;
                }
            });
            this.setState({additionalServiceFields: additionalServiceFields});
        }
        if (reqFields.length > 0) {
            fields.forEach(d => {
                let field = reqFields.find(
                    x => x.fieldUid && x.fieldUid === d.fieldUid);
                if (field) {
                    field.value = d.value;
                }
            });
            this.setState({reqFields: reqFields});
        }
        if (repairFields.length > 0) {
            fields.forEach(d => {
                let field = repairFields.find(
                    x => x.fieldUid && x.fieldUid === d.fieldUid);
                if (field) {
                    field.value = d.value;
                }
            });
            this.setState({repairFields: repairFields});
        }
    }
    updateStatePart = (event, data) => {
        const {reqFields} = this.state;
        if (data.id === 'equipmentSerial' && data.value) {
            if (reqFields.length > 0) {
                let field = reqFields.find(
                    x => x.fieldUid && x.fieldUid === '44444444-4444-4444-4444-444444444444');
                if (field) {
                    if (field.formatRegex) {
                        let rg = new RegExp(`^${field.formatRegex}$`, '');
                        if (!rg.test(data.value)) {
                            this.setState({equipmentSerialError: true});
                            return;
                        }
                    }
                    field.value = data.value;
                }
                this.setState({reqFields: reqFields, [data.id]: data.value, equipmentSerialError: false, isMinReqError: false});
            } else
                this.setState({[data.id]: data.value});          
        } else
            this.setState({[data.id]: data.value});
    }

    processFiles = (e) => {
        this.setState({errorMessage: null, showErrorMessage: false});
        e.preventDefault();
        const {acceptFiles} = this.state;
        let files = e.target.files;
        let cleanup = false;
        if (!files) {
            this.setState({errorMessage: 'Your file selected was not uploaded. Please try again.', showErrorMessage: true});
            cleanup = true;
        }
        // Loop through the FileList and render image files as thumbnails.

        // This is a nice shortcut - we'll keep it
        // eslint-disable-next-line no-cond-assign
        for (let i = 0, f; f = files[i]; i++) {
            // Only process image files.
            console.log(f);
            if (!acceptFiles.includes(f.type)) {
                this.setState({errorMessage: 'Acceptable photo formats are .jpg and .png', fileError: 'Acceptable photo formats are .jpg and .png', showErrorMessage: true});
                cleanup = true;
            }
        }
        if (cleanup) {
            this.setState({isFileSelected: false, fileDisplay: '', fileInput: '', imagePreviewUrl: ''});
            return;
        }
        this.setState({isFileSelected: true, fileDisplay: $(e.target).val().split('/').pop().split('\\').pop()});
        let reader = new FileReader();
        let file = files[0];

        reader.onload = () => { 
                this.setState({
                    file: file,
                    imagePreviewUrl: reader.result,
                    errorMessage: '', fileError: '', showErrorMessage: false
                });
            };
        reader.readAsDataURL(file);
    }

    removeFile = () => this.setState({isFileSelected: false, fileDisplay: '', fileInput: '', imagePreviewUrl: ''});

    handleAddToCartConfirm = () => {
        const {history} = this.props;
        this.setState({redirectToCart: true, requestQuote: false});
        history.push('/quotes');
        this.closePopup();
    }

    handleAddToQuoteCancel = () => {
        const {location, history} = this.props;
        if (location && location.state && location.state.from) {
            const prevPage = location.state.from;
            history.push(prevPage === '/login' ? '/' : prevPage);
        } else {
            history.push('/');
        }
    }

    handleQuoteTypeChange = (e, data) => {
        this.setState({currentView: parseInt(data.value), showErrors: false, catalogItem: null, rfqPartChecked: false, allowRFQ: false, ruleId: null,
           disableConfirm: false, isFileSelected: false, fileDisplay: '', fileInput: '', imagePreviewUrl: ''});
    }

    handleServiceTypeChange = (e, data) => {        
        this.setState({onSiteServiceType: data.value, showErrors: false});
    }

    onSelectOem = (oem) => {
        this.setState({models: [], model: ''});
        this.getModelOptions(oem); 
    };

    getModelOptions(oem) {
        this.setState({models: [], model: ''});
        axios.get(`CatalogService/api/v1/${oem.id}/models`).then(x => {
            let models = x.data;
            if (!models.some(x => x.modelId === -1)) {
                models = [{modelId: -1, model: '(MODEL NOT LISTED)'}, ...models];
            }
            this.setState({
                oemId: oem.id,
                oemName: oem.name,
                selectedModel: {},
                models: models,
                rfqPartChecked: false,
                disableConfirm: false,
            });
        });
    }
    onAddressSave = (address, isNew) => {
        let {loadFacilityAddresses} = this.props;
        const {selectedFacility} = this.state;
        if (isNew) {
            loadFacilityAddresses(selectedFacility.facilityId).then(x => {
                this.setState({ShowAddressDialog: false, shippingAddresses: x.response.data.shippingAddresses, selectedShipAddress: address});
            });
        } else {
            this.setState({ShowAddressDialog: false, selectedShipAddress: address});
        }
    }

    onPartRFQCheck = () => {
        const {selectedFacility, partNum, oemId, oemName} = this.state;

        let req = {
            partNumber: partNum.trim(),
            oemId: oemId,
            oemName: oemName,
            facilityId: selectedFacility.facilityId,
        }

        this.setState({isLoading: true})

        axios.post('/CatalogService/api/v1/rfqCheck', req)
            .then((x) => {
                if (!x.data.product && !x.data.allowRFQ)
                    this.setState({catalogItem: x.data.product, ruleId: x.data.ruleId, isLoading: false, allowRFQ: x.data.allowRFQ, rfqPartChecked: true, showErrors: false, disableConfirm: true});
                else
                    this.setState({catalogItem: x.data.product, ruleId: x.data.ruleId, isLoading: false, allowRFQ: x.data.allowRFQ, rfqPartChecked: true, showErrors: false});

                let logResult = null;

                if (x.data.product != null)
                    logResult = 'PDP';
                else if (!x.data.allowRFQ && !x.data.catalogItem)
                    logResult = 'Not Available';
                else if (x.data.allowRFQ)
                    logResult = 'Quote Submitted';

                logEvent('Request Quote', {
                    'RFQ_RULE_ID': x.data.ruleId ? x.data.ruleId : 'N/A',
                    'RFQ_RULE_NAME': x.data.ruleName ? x.data.ruleName : 'N/A',
                    'RFQ_RULE_RESULT': logResult,
                });
            });
    }

    renderFooter = () => {
        const {currentView, catalogItem, allowRFQ, disableConfirm, saving} = this.state;
        const allowRFQWithOptions = currentView === View.Part && allowRFQ && catalogItem;
        const showViewOptions = currentView === View.Part && catalogItem && !allowRFQWithOptions;
        const showFindOptions = currentView === View.Part && !allowRFQ;
        const disableSubmit = disableConfirm || currentView === View.QuoteSelection;
        const isDepotRepair = currentView == View.DepotRepair;

        return (<Footer>
            {allowRFQWithOptions && <SecondaryLink tabIndex={0} onClick={(e) => this.onSubmit(e, true)}>
                    VIEW OPTIONS
            </SecondaryLink>}
            {!isDepotRepair && <Button tabIndex={0} disabled={disableSubmit} onClick={(e) => this.onSubmit(e, showViewOptions)} loading={saving}>
                {showViewOptions ? 'VIEW OPTIONS' : showFindOptions ? 'FIND OPTIONS' : 'REQUEST QUOTE'}
            </Button>}
            {isDepotRepair && <Button onClick={this.handleForDepotRepair}>Initiate</Button>}
        </Footer>);
    }

    handleForDepotRepair = () => {
        const {history} = this.props;

        history.push({
            pathname: '/request-quoted-repair',
            state: {
                quantity: 1,
                isQuote: true,
            },
        });
    }

    renderLoanerCheckbox = (id, label, settings) => { 
        const {addLoaner} = this.state;

        const onBoxChecked = (e) => {
            this.setState({addLoaner: e.checked});
            let {onCheckBoxUpdate} = this.props;
            if (onCheckBoxUpdate) onCheckBoxUpdate(e);
        }

        if (settings.allowLoaner) {
            return (
                <Checkbox
                    id = {id}
                    label = {label}
                    checked = {addLoaner}
                    onChange = {onBoxChecked}
                    className = "checkbox" 
                />
            );
        }
    }
    toggleNoPartNumber(checked) {
        const {oemId} = this.state;
        if (checked && !oemId) {
            this.setState({isNoPartnumber: checked, showErrors: true});
        } else
            this.setState({isNoPartnumber: checked});
    }
    renderNoPartNumberBlock = (settings) => {
        const {isMinReqError, isFileSelected, imagePreviewUrl, fileDisplay, models, selectedModel, equipmentSerial, reqFields, equipmentSerialError, fileError} = this.state;
        let min = (!settings.isProgramAccountMember) ? 2 : 1;

        let header = `Please complete ${min} of the 3 options below.`;
        let errStyle = (isMinReqError && (!imagePreviewUrl || imagePreviewUrl === ''));
        let esfield = reqFields.filter(f => f.fieldUid === '44444444-4444-4444-4444-444444444444');
        let esPlaceholder = 'Equipment Serial #';
        let esFormatRegex = '';
        let esErrorMsg = '';
        if (esfield[0]) {
            esPlaceholder = esfield[0].placeholder;
            esFormatRegex = esfield[0].formatRegex;
            esErrorMsg = equipmentSerialError ? esfield[0].errorMsg : '';
        }

        return (
            <div style={{marginTop: 12, padding: 24, background: '#F1F1F1'}}>
                <span>{header}</span>
                <TextField
                    id="equipmentSerial"
                    label="Equipment Serial #"
                    placeholder={esPlaceholder}
                    pattern = {esFormatRegex}
                    errorMessage={esErrorMsg}
                    onBlur={this.updateStatePart}
                    showErrorMessage={(isMinReqError && !equipmentSerial) || equipmentSerialError}
                    className="txt-request-quote"
                    text={equipmentSerial}
                    required={false} 
                    tabIndex={0} />
                <TextFieldSelect
                    id="modelNumber"
                    keyField="modelId"
                    valueField="model"
                    alwaysShowKey={true}
                    listItems={models}
                    label="Model Number"
                    placeHolder="Model Number"
                    className="tfs-request-quote"
                    onSelect={(model) => this.onModelChange(model)}
                    showErrorMessage={isMinReqError && (!selectedModel.modelId)}
                    submitDisabled={false}
                    selectedItem={selectedModel}
                    allowCreate={false}
                    required={true} disableClear={true} tabIndex={0} /> 
                <div className="request-quote_optional" style={{... (errStyle && {color: 'red'})}}>
                    <FileUploader
                        label="Upload Photo"
                        acceptFiles="image/*"
                        isFileSelected={isFileSelected}
                        imagePreviewUrl={imagePreviewUrl}
                        fileDisplay={fileDisplay}
                        onChange={this.processFiles}
                        handleRemove={this.removeFile}
                    />
                </div>                   
                {fileError && <InvalidPhotoMessage>  { fileError } </InvalidPhotoMessage>}
                {isMinReqError && !imagePreviewUrl && selectedModel.modelId < 0 && <div style={{... ({color: 'red'})}}>Please include a photo to better fulfill your quote request</div>}
            </div>
        )
    }
    render() {
        const {user: {facilities, settings}, label, oems, showSingleView} = this.props;
        const {oemId, requesterList, selectedRequester, selectedFacility, partDescr, partNum, partQty, productTypeId,
            reqFields, showErrors, isFileSelected, imagePreviewUrl, fileDisplay, isUrgency, currentView,
            showShippingFields, shippingAddresses, selectedShipAddress, ShowAddressDialog,
            AddressEdit, AddressFormDisplay, allowRFQ, isLoading, disableConfirm, rfqPartChecked, showShipMethodDescription, 
            invalidField, isNoPartnumber, showErrorMessage, errorMessage, catalogItem, qtErrorMessage} = this.state;

        const title = label || 'Request A Quote';

        let product = {description: partDescr, displayPartNumber: partNum, partImages: {}, quantity: partQty === 0 ? 1 : partQty, productTypeId: productTypeId};

        const dedicatedPhoneNumber = settings.dedicatedPhoneNumber ? 
            getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : defaultPhoneNumber;
            
        // Do this in render to avoid making a copy of this large array in state twice.
        let displayOems = oems;
        if (!displayOems.some(x => x.id === 26799)) {
            displayOems = [{id: 26799, name: '(OEM NOT LISTED)'}, ...displayOems];
        }

        let quoteTypes = [
            {value: View.Part, label: 'Part'},
            {value: View.DepotRepair, label: 'Depot Repair'},

        ];
    
        let onSiteServiceTypes = [
            {value: OnSiteServiceType.SingleAsset, label: 'OSS requests for 5 or fewer assets'},
            {value: OnSiteServiceType.MultiAsset, label: 'OSS requests for 6 or more assets'},
        ];

        let selectedOem = displayOems.filter(x => x.id === oemId);
        selectedOem = selectedOem.length > 0 ? selectedOem[0] : {};
        let shipSpeeds = [
            {id: '1', name: 'Ground'},
            {id: '2', name: '5+'},
            {id: '3', name: '3 Day'},
            {id: '4', name: '2 Day'},
            {id: '5', name: 'Overnight'},

        ];
        let selectedShipSpeed = shipSpeeds[0];
        let shipMethodDescription = "We'll do our best to quote based on your selected shipping, and we'll provide the closest alternative when the same is not available. All available shipping options for the order will be available in the cart when you checkout should you prefer a different method then.";
        let serviceFields = reqFields.filter(r => r.isRequired);     
        return (
            <div className="quote-selection">               
                <div className="request-quote">
                    {isLoading && <LoaderContainer> <Loader /> </LoaderContainer>}
                    <div className="request-quote-form">
                        <div className="icon-wrapper">
                            <img src="/images/icn_message.png" />
                        </div>                                                                        
                        <h1 style={{textAlign: 'center'}}>{title}</h1>
                        {!showSingleView &&
                                <div className="quote-types">
                                    <div className="quote-type">Quote Type</div>
                                    <RadioButtonList name="priority" tabIndex="0" selectedValue={currentView} options={quoteTypes} onChange={this.handleQuoteTypeChange} style={{marginTop: 0, marginBottom: 0}} />
                                </div>}
                        {!showSingleView && (currentView === View.Part) && <div className="required-label">Required</div>}                            
                    </div>
                    <React.Fragment>
                        {currentView != View.QuoteSelection && currentView !== View.DepotRepair &&
                            <React.Fragment>
                                <TextFieldSelect
                                    id="tfsOemId"
                                    keyField="id"
                                    valueField="name"
                                    listItems={displayOems}
                                    alwaysShowKey={'26799'}
                                    label="OEM"
                                    placeHolder="OEM"
                                    className="tfs-request-quote"
                                    onSelect={(oem) => this.onSelectOem(oem)}
                                    showErrorMessage={showErrors && !oemId}
                                    selectedItem={selectedOem}
                                    required={true} disableClear={true} tabIndex={0} />
                                {currentView === View.Part && <div><TextField
                                    id="partNum"
                                    label="Item Number"
                                    placeholder="ItemNumber"
                                    onBlur={this.updateStatePart}
                                    showErrorMessage={showErrors && (!partNum && !isNoPartnumber)}
                                    className="txt-request-quote"
                                    maxLength={60}
                                    text={partNum}
                                    required={true} tabIndex={0}
                                    onChange={() => {
                                        if (rfqPartChecked || disableConfirm) this.setState({rfqPartChecked: false, disableConfirm: false}) 
                                    }} />
                               
                                <Checkbox label="I don't know the item number"
                                    id="nopartnumber"
                                    checked={isNoPartnumber}
                                    onChange={(i) => this.toggleNoPartNumber(i.checked)} />
                                </div>}
                                {isNoPartnumber && oemId ? this.renderNoPartNumberBlock(settings) : null}

                                {currentView === View.Part && <TextField
                                id="partDescr"
                                className="request-quote_required_textarea"
                                multiLine={true}
                                rows={4}
                                required={true}
                                label="Model (if not listed)/Description of Item"
                                placeholder="Model (if not listed)/Description of Item"
                                showErrorMessage={showErrors && !partDescr}
                                text={partDescr}
                                onBlur={this.updateStatePart} tabIndex={0}
                                maxLength={500} />
                            }
                                {facilities && currentView !== View.DepotRepair && <TextFieldSelect
                                    id="facilityId"
                                    keyField="facilityId"
                                    valueField="facilityName"
                                    onSelect={this.onSelectFacility}
                                    listItems={facilities.filter(x => x.showTfyp)}
                                    selectedItem={selectedFacility}
                                    className="tfs-request-quote"
                                    label="Your Facility"
                                    required={true}
                                    disableClear={true}
                                    placeHolder="Your Facility"
                                    showErrorMessage={showErrors && (!selectedFacility || !(selectedFacility.facilityId > 0))} tabIndex={0} />}
                            </React.Fragment>
                        }
                        {allowRFQ &&
                                <React.Fragment>
                                    <TextFieldSelect id="requesterId"
                                        keyField="contactId"
                                        valueField="fullName"
                                        onSelect={(contact) => this.setState({selectedRequester: contact})}
                                        listItems={requesterList}
                                        label="Requester"
                                        placeHolder="Requester"
                                        className="tfs-request-quote"
                                        required={true}
                                        selectedItem={selectedRequester}
                                        disableClear={true}
                                        showErrorMessage={showErrors && !selectedRequester} tabIndex={0} />

                                    <span className="text-field_title qty-label">Quantity</span>
                                    <QuantitySelector className="field qty-field" id="partQty" label="Quantity" placeHolder="Quantity" required={true} onChange={this.updateStatePart} tabIndex={0} selectedValue={partQty} />
                                    {reqFields.length > 0 && <AssetInformationForm
                                        fields={reqFields}
                                        formType={AssetFormType.Both}
                                        showRequester={false}
                                        showErrors={showErrors}
                                        invalidField={invalidField}
                                        hideSaveButton={true}
                                        hideTitle={true}
                                        onFieldUpdate={this.onFieldUpdate}
                                        onCheckBoxUpdate={(i) => this.setState({isUrgency: i.checked})}
                                        priority={isUrgency}
                                        displayInline = {true}
                                        hideRequiredTitle = {true} />}
                                    {(settings.statOrder && reqFields.length === 0) && <Checkbox
                                        label="Critical Hard Down"
                                        id="urgencyId"
                                        checked={isUrgency}
                                        onChange={(i) => this.setState({isUrgency: i.checked})} />}
                                    <div className="request-quote_optional">
                                        <FileUploader
                                            label="Upload Photo"
                                            acceptFiles="image/*"
                                            isFileSelected={isFileSelected}
                                            imagePreviewUrl={imagePreviewUrl}
                                            fileDisplay={fileDisplay}
                                            onChange={this.processFiles}
                                            handleRemove={this.removeFile}
                                        />
                                        {showErrorMessage && errorMessage && <InvalidPhotoMessage>  { errorMessage } </InvalidPhotoMessage>}
                                        {showShippingFields &&
                                            <div>
                                                <div className="required-label">Shipping Location</div>
                                                <TextFieldSelect
                                                    id="shippingAddresses"
                                                    keyField="addressId"
                                                    valueField="description"
                                                    listItems={shippingAddresses}
                                                    label="Shipping Address"
                                                    placeHolder="Select an address"
                                                    className="tfs-request-quote"
                                                    onSelect={(sa) => this.setState({selectedShipAddress: sa})}
                                                    showErrorMessage={showErrors && !selectedShipAddress.addressId}
                                                    selectedItem={selectedShipAddress}
                                                    required={true} disableClear={true} tabIndex={0} />
                                                {settings.canAddAddress &&
                                                    <div className="add-address">
                                                        <span className="add-address-label" onClick={() => this.setState({ShowAddressDialog: true, AddressEdit: {country: 'US', addressTypeId: 6}, AddressFormDisplay: AddressFormType.AddEdit})}>+ Add Shipping Address</span>
                                                    </div>}
                                                {ShowAddressDialog && <AddressDialog onSave={this.onAddressSave} onCancel={() => this.setState({ShowAddressDialog: false})} controlInline={true} address={AddressEdit} addressFormType={AddressFormDisplay} facilityId={selectedFacility.facilityId} />}

                                                <div className="required-label">Shipping Method <i id="ttConditionDescription" style={{cursor: 'pointer'}} onMouseOver={() => this.setState({showShipMethodDescription: true})}
                                                    onMouseOut={() => this.setState({showShipMethodDescription: false})} className="fa fa-question-circle product_tooltip">
                                                    {showShipMethodDescription && <span>{shipMethodDescription}</span>}</i>
                                                <TextFieldSelect
                                                    id="shippingSpeed"
                                                    keyField="id"
                                                    valueField="name"
                                                    listItems={shipSpeeds}
                                                    alwaysShowKey={true}
                                                    label="Shipping Method"
                                                    placeHolder="Shipping Method"
                                                    className="tfs-request-quote-short"
                                                    onSelect={(ss) => this.setState({selectedShipSpeedId: ss.id})}
                                                    selectedItem={selectedShipSpeed}
                                                    required={true} disableClear={true} tabIndex={0} />
                                                </div>
                                            </div>}
                                    </div>
                                </React.Fragment>}
                    </React.Fragment>
                    {!this.state.allowRFQ && !this.state.catalogItem && this.state.rfqPartChecked &&
                            <div style={{display: 'inline-block', marginTop: '20px'}}>
                                <ExclamationIcon /> Item <span style={{fontWeight: 600}}>"{partNum}"</span> is currently unavailable.
                            </div>}
                    {catalogItem && <SingleCatalogItem item={catalogItem} closeParentModal={this.closePopup} />}  
                    <div className="request-quote_optional" style={{color: '#FF0000'}}>{qtErrorMessage}</div>
                   {this.renderFooter()}

                </div>
                {currentView === View.AddToCartConfirmation &&
                    <AddToCartConfirmation
                        show={true}
                        product={product}
                        isQuote={true}
                        quantity={partQty}
                        option={null}
                        onCancel={this.handleAddToQuoteCancel}
                        onConfirm={this.handleAddToCartConfirm}
                        hasOnSiteService={false}
                        addLoaner={this.state.addLoaner}
                        onSiteService={null}
                        confirmButtonText="View Quotes" />}
            </div>);
    }
}
