import React, {useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {useDispatch} from 'react-redux';
import * as KevelStore from 'stores/Kevel';
import {RichContent} from '@partssourceinc/react-ui-core/cms';
import {Ad} from '@partssourceinc/react-ui-core/cms';

const AdRichContent = (props) => {
    const {preview} = props;
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(preview ? false : true);
    const [adZones, setAdZones] = useState([]);

    const richContent = useRef(null);
    const {getDecisions, fireClickImpression} = KevelStore.actionCreators;

    const loadDecisions = async (zoneIds, adTypeIds) => {
        const decisions = await dispatch(getDecisions(zoneIds, adTypeIds, []));
        if (decisions && decisions.length)
            setAdZones(decisions);
        else
            setIsLoading(false);
    };

    useEffect(() => {
        if (richContent.current && adZones && adZones.length) {
            let zones = richContent.current.querySelectorAll("a[href*='k-zone-id']");
            const zoneList = Array.from(zones);

            (zoneList || []).map(zone => {
                let zoneId = (new URL(zone.href)).searchParams.get('k-zone-id');
                const ad = adZones.find(a => a.zoneId === zoneId);

                if (ad) {
                    zone.href = ad.clickUrl;
                    let adZone = <Ad content={ad.content} clickUrl={ad.clickUrl} impressionUrl={ad.impressionUrl} fireImpression={(impressionUrl) => dispatch(fireClickImpression(impressionUrl))} />
                    const img = zone.querySelector('img');
                    zone.removeChild(img);
                    ReactDOM.render(adZone, zone);
                }
            });

            setIsLoading(false);
        }
    }, [adZones]);

    useEffect(() => {
        if (richContent.current && !preview) {
            let zones = richContent.current.querySelectorAll("a[href*='k-zone-id']");
            const zoneList = Array.from(zones);
            let zoneIds = [];
            let adTypeIds = [];

            (zoneList || []).map(zone => {
                const url = new URL(zone.href);
                const zoneId = url.searchParams.get('k-zone-id');
                let adTypeId = parseInt(url.searchParams.get('k-ad-type-id'));

                if (zoneId && adTypeId && !isNaN(adTypeId)) {
                    zoneIds.push(zoneId);
                    adTypeIds.push(adTypeId)
                }
            });

            // Remove duplicates, if any
            zoneIds = [...new Set(zoneIds)];
            adTypeIds = [...new Set(adTypeIds)];

            if (zoneIds.length && adTypeIds.length) {
                loadDecisions(zoneIds, adTypeIds);
            } else {
                setIsLoading(false);
            }
        }
    }, []);

    return (<div ref={richContent} style={{display: isLoading ? 'none' : 'block'}}>
        <RichContent {...props} />
    </div>);
};

export default AdRichContent;

