import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import Home from 'icons/Home.svg';
import Orders from 'icons/Orders.svg';
import Quotes from 'icons/Quotes.svg';
import Approvals from 'icons/Approvals.svg';
import AwaitingPO from 'icons/AwaitingPO.svg';
import Loaners from 'icons/Loaners.svg';
import Returns from 'icons/Returns.svg';
import Service from 'icons/Service.svg';
import Community from 'icons/Community.svg';
import OnSiteService from 'icons/OnSiteService.svg';
import OneSourceDocs from 'icons/OneSourceDocs.svg';
import VisualAnalytics from 'icons/VisualAnalytics.svg';
import Formulary from 'icons/Formulary.svg';
import PlusProgram from 'icons/PlusProgram.svg';
import Admin from 'icons/Admin.svg';
import Settings from 'icons/Settings.svg';
import {matchPath} from 'react-router-dom';

const useMenu = () => {
    const {isLoggedIn} = useSelector(state => state.network);
    const {leftMenuforCommandCenter} = useSelector(state => state.user.settings);
    const {isImpersonation} = useSelector(state => state.network.tokenInfo);
    const {
        hasDashboardv2Access, 
        approver, 
        enableLoanerFunctionality, 
        allowLoaner, 
        enableOnSiteService, 
        hasPSPlusAccess, 
        hasReportsAccess, 
        psCommunityAccess, 
        oneSource, 
        customerAdminPermission,
        viewFormularyRules,
        editFormularyRules,
        showAwaitingPoMenu,
    } = useSelector(state => state.user.settings);
    
    const menuItems = [{
        url: '/command-center',
        Icon: Home,
        title: 'Command Center',
        enabled: hasDashboardv2Access,
        order: 1,
    },
    {
        url: '/orders',
        Icon: Orders,
        title: 'Orders',
        enabled: true,
        order: 2,
    },
    {
        url: '/quotes',
        Icon: Quotes,
        title: 'Parts Quotes',
        enabled: true,
        order: 3,
    },
    {
        url: '/approvals',
        Icon: Approvals,
        title: 'Approvals',
        enabled: approver,
        order: 4,
    },
    {
        url: '/orders/po',
        Icon: AwaitingPO,
        title: 'Awaiting PO',
        enabled: showAwaitingPoMenu,
        order: 4,
    },
    {
        url: '/repairs',
        Icon: Service,
        title: 'Depot Repair & Quotes',
        enabled: true,
        order: 5,
    },
    {
        url: '/loaners',
        Icon: Loaners,
        title: 'Loaners',
        enabled: (enableLoanerFunctionality && allowLoaner),
        order: 6,
    },
    {
        url: '/on-site-service',
        Icon: OnSiteService,
        title: 'On-Site Service',
        enabled: enableOnSiteService,
        order: 7,
    },
    {
        url: '/orders/rga',
        Icon: Returns,
        title: 'Exchanges & Returns',
        enabled: true,
        order: 8,
    },
    {
        url: '/reports',
        pathMatch: [
            '/reports', 
            '/reports/:reportId',
            '/reports/:reportId/:useRemiAuth',
        ],
        Icon: VisualAnalytics,
        title: 'Visual Analytics',
        enabled: hasReportsAccess,
        order: 9,
    },
    {
        url: '/community',
        Icon: Community,
        title: 'Community',
        enabled: psCommunityAccess,
        order: 10,
    },
    {
        url: '/onesource-document-search',
        Icon: OneSourceDocs,
        title: 'oneSOURCE Docs',
        enabled: oneSource,
        order: 11,
        newTab: true,
    },
    {
        url: '/formulary',
        Icon: Formulary,
        title: 'Formulary',
        enabled: (viewFormularyRules || editFormularyRules || isImpersonation),
        order: 12,
    },
    {
        url: '/psplus',
        Icon: PlusProgram,
        title: 'Plus+ Program',
        pathMatch: ['/psplus', '/management-tracker/month-over-month', '/management-tracker/prior-rebates'],
        enabled: hasPSPlusAccess,
        order: 13,
    },
    {
        url: '/settings',
        Icon: Settings,
        title: 'Settings',
        pathMatch: ['/settings', '/settings/personal', '/settings/notifications', '/settings/payment', '/settings/addresses'],
        enabled: true,
        order: 14,
    },
    {
        url: '/customeradmin',
        pathMatch: [
            '/customeradmin', 
            '/customeradmin/users/:userid?',
            '/customeradmin/locations', 
            '/customeradmin/location/:locationid',
            '/customeradmin/users/add',
            '/customeradmin/locations/add',
        ],
        Icon: Admin,
        title: 'Admin',
        enabled: customerAdminPermission,
        order: 18,
    }];

    const {pathname} = useLocation();

    const menuItem = menuItems.find(i => matchPath(pathname, {
        path: i.pathMatch || i.url,
        exact: true,
    }));

    const showMenu = menuItem && leftMenuforCommandCenter && isLoggedIn;
    const title = menuItem?.title || '';

    return {menuItems, showMenu, title};
};

export default useMenu;

