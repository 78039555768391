import {useSelector, useDispatch} from 'react-redux';

import {disconnectWebsocket} from './../store/socket';
import {stopNotifications} from './../store/Dashboard';

import {logEvent, clearTokenInfo} from 'utility';

const useLogout = (authHandler) => {
    const dispatch = useDispatch();
    const {hasReportsAccess} = useSelector(state => state.user.settings);
    const {sessionStorageAvailable} = useSelector(state => state.network);

    const logOut = async () => {
        if (hasReportsAccess) {
            sisenseLogout();
        }

        dispatch(disconnectWebsocket());
        await finishLogout();
    }

    const finishLogout = async () => {
        if (sessionStorageAvailable) {
            sessionStorage.removeItem('prevUrl');
        }
        
        await dispatch(stopNotifications());
        clearTokenInfo();
        logEvent('My Account', {'menu item': 'logOut'})

        authHandler.triggerLogout();
        location.href = '/login';
    }

    const sisenseLogout = () => {
        return new Promise(resolve => {
            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', 'sso-logout'); // assign an id
            iframe.style.visibility = 'hidden';
            iframe.addEventListener('load', resolve);
            document.body.appendChild(iframe); // to place at end of document
            iframe.setAttribute('src', `${process.env.REACT_APP_SISENSE_URL}/api/auth/logout`);

            resolve();
        });
    }

    return {logOut};
}

export default useLogout;
