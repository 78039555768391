import * as React from 'react';
import {Route} from 'react-router';
import InfoList from 'components/cms/Accordion';
import InfoListNavigationButton from 'components/cms/InfoListNavigationButton';
import {pathJoin} from 'utility';

import 'less/cms/carouselnavigationwrapper.less';

export default class InfoListNavigationWrapper extends React.Component {
    render() {
        const {component, page} = this.props
        const {document: documentRef} = component.getModels() || {};
        const document = documentRef && page.getContent(documentRef);
        const items = document && document.getData().items || [];
        const sideBar = document && document.getData().sideBar || [];
        const params = component.getParameters() || {};
        const {title: heading, rootPath} = params;

        return (<div className="row justify-content-around">
            <h1 className="col-12">{heading}</h1>
            <div className="col-12 col-lg-9 row mb-5">
                {items.map(({link, icon, linkTitle, body}) => (<InfoListNavigationButton
                    title={linkTitle}
                    icon={icon}
                    body={body}
                    key={link}
                    path={pathJoin(rootPath, link)}
                />))}
                {items.map(({link, infoList, listTitle}) => (<Route
                    exact={true}
                    key={link}
                    path={pathJoin(rootPath, link)}
                    render={() => <InfoList infoList={infoList} title={listTitle} />}
                />))}
            </div>
            <div className="col-12 col-lg-3">
                <ul className="list-group list-group-flush">
                    {sideBar.map(({icon, title, subtitle}) => (<li key={title} className="list-group-item mb-3 border-0">
                        <div className="d-flex flex-row">
                            <i className={`fa fa-${icon} mt-2 mr-4`} />
                            <div className="d-flex flex-column flex-grow-1">
                                <h4 className="mb-1 font-weight-bold info-list-side-bar" dangerouslySetInnerHTML={{__html: title}} />
                                <p className="mb-1">{subtitle}</p>
                            </div>
                        </div>
                    </li>))}
                </ul>
            </div>
        </div>);
    }
}
