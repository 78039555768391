import React, {useContext} from 'react';
import {BrManageContentButton, BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import styled from 'styled-components';
import {SimpleContent} from '@partssourceinc/react-ui-core/cms';

const Title = styled.h1`
    font-weight: 400 !important;
    margin-bottom: 20px;
`;

const StyledSimpleContent = styled(SimpleContent)`
    a {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
    }
`;

const PolicyContent = () => {
    const component = useContext(BrComponentContext);
    const page = useContext(BrPageContext);
    const {document: documentRef} = component.getModels() || {};
    const content = documentRef && page.getContent(documentRef).getData() || null;
    const html = page.rewriteLinks(content.content.value);

    if (!content) {
        return null;
    }
    return (
        <div className="simple-content">
            {content.content && content.content && content.content.value &&
                <>
                    <div>
                        {content.title && (<Title>{content.title}</Title>)}
                        <StyledSimpleContent html={html} showBorder={true} />
                    </div>
                </>}
        </div>);
}

export default PolicyContent;
