import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Button} from '@partssourceinc/react-ui-core';
import * as CompaniesStore from 'stores/Companies';
import {logEvent, slugify} from 'utility';
import {BrManageContentButton} from '@bloomreach/react-sdk';
import Select from 'react-select'
import {AsyncPaginate} from 'react-select-async-paginate';

import 'less/cms/guidedsearch.less';

@withRouter
@connect((state) => ({oems: state.companies.oems}), CompaniesStore.actionCreators)
export default class GuidedSearch extends React.Component {
    static propTypes = {
        ...CompaniesStore.ActionShape,
        oems: CompaniesStore.OemsShape,
    };

    state = {
        selectedOem: null,
        models: [],
        selectedModel: null,
        showModelList: false,
        oemData: null,
        searchTerm: '',
        skip: 0,
    };

    onSelectOem = async (oem) => {
        if (!oem) {
            this.setState({selectedOem: oem, selectedModel: null, models: []});
        } else {
            this.setState({
                selectedOem: oem,
                selectedModel: null,
                models: [],
            });
            axios.get(`CatalogService/api/v1/${oem.id}/models`).then(x => {
                this.setState({
                    models: x.data,
                });
            });
        }
    };

    onSelectModel = (model) => {
        this.setState({
            selectedModel: model,
        });
    };

    onSearch = async () => {
        const {history} = this.props;
        const {selectedOem, selectedModel} = this.state;

        const oemName = selectedOem && selectedOem.name ? slugify(selectedOem.name) : null;

        if (selectedModel && selectedModel.slugName) {
            history.push(`/catalog/${oemName}/${selectedModel.slugName}`);
        } else if (oemName) {
            history.push(`/catalog/${oemName}`);
        }

        logEvent('Shop by Device', {
            'OEM': oemName,
            'Model': (selectedModel || {}).slugName,
        });
    };

    getMoreOems = async (search, prevOptions) => {
        const {searchTerm, skip} = this.state;

        let startAt = searchTerm === search.toUpperCase() ? skip + 50 : 0;

        const response = await axios.get(`/ShoppingService/api/v1/companies/oemsInfinite?limit=${50}&start=${startAt}&query=${search}`);
        const {data} = response;

        this.setState({searchTerm: search.toUpperCase(), skip: startAt});

        return {
            options: data.oems,
            hasMore: data.hasMore,
        };
    }

    render() {
        const {models, selectedOem, selectedModel} = this.state;
        const {component, page} = this.props;
        const {document: documentRef} = component.getModels() || {};
        const content = documentRef && page.getContent(documentRef).getData();

        if (!content) {
            return null;
        }

        return (
            <div className="guided-search row">
                <div style={{position: 'relative'}}><BrManageContentButton content={page.getContent(documentRef)} /></div>
                <h3 className="col-xs-12 col-md-12 col-lg-2 lbl-bold" style={{fontWeight: '600', color: '#005da6', whiteSpace: 'nowrap'}}>
                    {content.title || 'I Need to Find a Part for:'}
                </h3>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    <AsyncPaginate
                        debounceTimeout={300}
                        isMulti={false}
                        isClearable={true}
                        cacheOptions={true}
                        loadOptions={this.getMoreOems}
                        getOptionValue={option => option.id}
                        value={selectedOem}
                        getOptionLabel={option => option.name}
                        noOptionsMessage={() => null}
                        placeholder="Manufacturer"
                        classNamePrefix="select"
                        onChange={this.onSelectOem}
                        className="custom-multi-select" />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    <div style={{display: 'flex'}}>
                        <div style={{flex: 2}}>
                            <Select
                                isMulti={false}
                                isClearable={true}
                                cacheOptions={true}
                                getOptionValue={option => option.modelId}
                                options={models || []}
                                value={selectedModel}
                                getOptionLabel={option => option.model}
                                noOptionsMessage={() => null}
                                placeholder="Model"
                                classNamePrefix="select"
                                onChange={this.onSelectModel}
                                className="custom-multi-select" />
                        </div>
                        <div className="ps-hidden-xs ps-hidden-sm ps-hidden-lg ps-hidden-xl">
                            <Button
                                style={{width: '60px', padding: '0px', marginLeft: '20px'}}
                                onClick={this.onSearch}
                                secondary={true}
                                disabled={!selectedOem}
                            >
                                {content.subtitle || 'FIND'}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-2 ps-hidden-md">
                    <Button
                        style={{width: '90px', padding: '0px'}}
                        onClick={this.onSearch}
                        secondary={true}
                        disabled={!selectedOem}
                    >
                        {content.subtitle || 'FIND'}
                    </Button>
                </div>
            </div>
        );
    }
}
