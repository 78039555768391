import * as React from 'react';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';

import {pathMatches} from 'utility';

@withRouter
export default class InfoListNavigationButton extends React.Component {
    render() {
        const {title, icon, body, path, location: {pathname}} = this.props;

        return (<div className="col-6 col-lg-4 p-0 w-100 p-3">
            <Link
                to={path}
                onMouseEnter={this.startHover}
                onMouseLeave={this.endHover}
                className={`info-list-nav-button d-block h-100 ${pathMatches(path, pathname) ? 'active' : ''}`}
            >
                <div className="info-list-nav-button-body p-3 text-center">
                    <i className={`fa fa-2x fa-${icon} my-3 ${pathMatches(path, pathname) ? 'active' : ''}`} />
                    <h4 className="font-weight-bold">{title}</h4>
                    <p>{body}</p>
                </div>
            </Link>
        </div>);
    }
}
