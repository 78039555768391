import {
    SimpleContent,
    ImageGrid,
    ImageCarousel,
    FeaturedParts,
    CategoryMenu,
    ContactUs,
    CTABlock,
    FilteredPosts,
    IconDetailsList,
    IconGridBlock,
    IconList,
    ImageBlock,
    Partners,
    Stats,
    StatsWithColumns,
    SubNav,
    TextBlock,
    MediaBlock,
    Spacer,
} from '@partssourceinc/react-ui-core/cms';

import FlyoutMenu from 'components/cms/FlyoutMenu';
import Accordion from 'components/cms/Accordion';
import InfoListNavigationWrapper from 'components/cms/InfoListNavigationWrapper';
import ProductCarousel from 'components/cms/ProductCarousel';
import {ProductGrid} from 'components/cms/ProductGrid';
import GuidedSearch from 'components/cms/GuidedSearch';
import Seo from 'components/cms/Seo';
import ReadMore from 'components/cms/ReadMore';
import RotatingBanner from 'components/cms/RotatingBanner';
import OneSourceDocumentSearchLink from 'components/cms/OneSourceDocumentSearchLink';
import RFQFormLink from 'components/cms/RFQFormLink';
import RSAFormLink from 'components/cms/RSAFormLink';
import PolicyContent from 'components/cms/PolicyContent';
import AdBanner from 'components/cms/AdBanner';
import AdBannerCluster from 'components/cms/AdBannerCluster';
import AdRichContent from 'components/cms/AdRichContent';
import OemShowcaseMenu from 'components/cms/OemShowcaseMenu';

// any component shared between CMS and React need to go here
export const _cmsComponentDefinitions = {
    'Oem Showcase Menu': OemShowcaseMenu,
    'Simple Content': SimpleContent,
    'Image Grid': ImageGrid,
    'Icon Block List Section': IconGridBlock,
    'Image Carousel': ImageCarousel,
    'Featured Parts': FeaturedParts,
    'Category Menu': CategoryMenu,
    'Contact Us': ContactUs,
    'Call to Action Block': CTABlock,
    'Filtered Posts Block': FilteredPosts,
    'Icon Block Section': IconDetailsList,
    'Icon List With Image Section': IconList,
    'Image Block Section': ImageBlock,
    'Partners Block Section': Partners,
    'Stat Block With Copy': Stats,
    'Stats Block Section': StatsWithColumns,
    'Sub Nav Block': SubNav,
    'Text Block 4 Columns': TextBlock,
    'Media Block Section': MediaBlock,
    'Spacer': Spacer,
    'Banner': AdBanner,
    'Banner Cluster': AdBannerCluster,
    'Rich Content': AdRichContent,
    'Flyout Menu': FlyoutMenu,
    'Product Carousel': ProductCarousel,
    'Product Grid': ProductGrid,
    'Guided Search': GuidedSearch,
    'SEO': Seo,
    'Info List': Accordion,
    'Info List Navigation Wrapper': InfoListNavigationWrapper,
    'Read More': ReadMore,
    'Rotating Banner': RotatingBanner,
    'OneSOURCE Document Search Link': OneSourceDocumentSearchLink,
    'RFQ Form Link': RFQFormLink,
    'RSA Form Link': RSAFormLink,
    'Policy Component': PolicyContent,
};
