import * as React from 'react';
import {NavLink} from 'react-router-dom';

import 'less/header.less';

export class SecureHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="row secure-header" data-ea-zone="Header~Secure-Header">
                    <div className="col-md-12">
                        <div className="secure-header-content">
                            <a href="/">
                                <img src="/images/partssourcelogo300.png" alt="PartsSource logo" style={{height: '16px', width: '200px', marginTop: '25px'}} />
                            </a>
                            <span className="secure">
                                <span>Secure</span>
                                <i className="fa fa-expeditedssl" aria-hidden="true" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
