import React, {useState, useContext, useEffect} from 'react';
import 'less/cms/flyoutmenu.less';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import * as UserStore from 'stores/User';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import {useHistory} from 'react-router';

const MobileFlyoutMenu = (props) => {
    const history = useHistory();
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const dispatch = useDispatch();
    const component = useContext(BrComponentContext);
    const page = useContext(BrPageContext);
    const menuTitle = useSelector(state => state.user.mobileMenuOpen);

    const {user: {settings: {showTeamContactCenterPage}}} = useSelector((state) => ({
        user: state.user,
    }));

    const menuRef = component.getModels().menu || null;
    const menu = menuRef ? page.getContent(menuRef) : null;
    const items = menu.getItems();

    const siteMenuItems = items.filter(x => x.model.name !== 'menu-title') || [];
    const [highlightedMenuItems, menuItems] = _.partition(siteMenuItems, 'parameters.highlighted');

    useEffect(() => {
        if (!menuTitle && (selectedMenuItem && selectedMenuItem.getName())) {
            setSelectedMenuItem(null);
        }
    }, [menuTitle, selectedMenuItem]);

    const onGoBack = () => {
        const {closeMobileMenu} = UserStore.actionCreators;
        dispatch(closeMobileMenu());
    }

    const onGoToLink = (item) => {
        const {closeMobileMenu} = UserStore.actionCreators;
        const link = item.getLink() && item.getLink().href;

        if (link) {
            const [, href, openInNewTab, queryString = ''] = /([^?]*)(\?newtab)?(\?.*)?/.exec(link);
            const isExternal = /^https?:\/\//.test(href);

            dispatch(closeMobileMenu());

            if (isExternal && openInNewTab) {
                window.open(href + queryString, '_blank');
            } else if (isExternal) {
                window.location.href = href + queryString;
            } else {
                history.push(href + queryString);
            }
        }
    }

    return (<>
        <div className="sub-menu-title left-chevron" onClick={() => onGoBack()}>Main Menu</div>
        <div className="hamburger-divider" />
        <div className="sub-menu-header">{menu.name}</div>
        {menuItems.map((x, i) => (<div className="hamburger-item" key={`${menu.name}-${i}`}>
            {(x.children || []).length > 0
                ? <span
                    onClick={() => setSelectedMenuItem(x)}
                    className="hamburger-item-desc right-chevron"
                >
                    {x.getName()}
                </span>
                : (x.getLink().href === '/teamcontactcenter' && !showTeamContactCenterPage) ? '' : <span className="hamburger-item-desc" onClick={() => onGoToLink(x)}>{x.getName()}</span>}
        </div>))}

        {highlightedMenuItems.length > 0 && <div className="highlight-menu-items">
            {highlightedMenuItems.map((x, i) => (<div className="hamburger-item highlight" key={`${menu.name}-${i}`}>
                {(x.children || []).length > 0
                    ? <span
                        onClick={() => setSelectedMenuItem(x)}
                        className="hamburger-item-desc right-chevron"
                    >
                        {x.getName()}
                    </span>
                    : <span className="hamburger-item-desc" onClick={() => onGoToLink(x)}>{x.getName()}</span>}
            </div>))}
        </div>}

        {selectedMenuItem && <div className={selectedMenuItem.getName() ? 'sub-menu open' : 'sub-menu'}>
            <div className="sub-menu-title left-chevron" onClick={() => setSelectedMenuItem(null)}>
                {menuTitle}
            </div>
            <div className="hamburger-divider" />
            <div className="sub-menu-header" style={{cursor: 'pointer'}} onClick={() => onGoToLink(selectedMenuItem)}>
                {selectedMenuItem.getName()}
            </div>
            {(selectedMenuItem.children || []).filter(x => x.getName() !== 'menu-image').map((x, i) => (
                <div className="hamburger-item" key={`${x.name}-${i}`}>
                    <span className="hamburger-item-desc" onClick={() => onGoToLink(x)}>{x.getName()}</span>
                </div>))}
        </div>}
    </>);
}

export default MobileFlyoutMenu;
