import * as React from 'react';
import AccordionItem from 'components/cms/AccordionItem';

import 'less/cms/accordion.less';

export default class Accordion extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: null,
        };
        this.extractProps = this.extractProps.bind(this);
    }

    extractProps() {
        const {infoList} = this.props;

        if (infoList) {
            return {...this.props, collapsible: true};
        } else {
            const {component, page} = this.props;
            const params = component && component.getParameters() || {};
            const {collapsible, title} = params;
            const {document: documentRef} = component && component.getModels() || {};
            const info = documentRef && page.getContent(documentRef).getData();

            return {collapsible, title, infoList: info};
        }
    }

    handleClick(index) {
        const {selectedIndex} = this.state;
        this.setState({selectedIndex: selectedIndex === index ? null : index});
    }

    render() {
        const {selectedIndex} = this.state;
        const {title: header, infoList} = this.extractProps();

        return (
            <div className="accordion">
                <div className="accordion_header">
                    <h2>{header}</h2>
                </div>
                {infoList.map(({title, body}, i) => (
                    <AccordionItem
                        onSelected={() => this.handleClick(i)}
                        active={selectedIndex === i}
                        key={`ai_${i}`}
                        title={title}
                        body={body}
                    />
                ))}
            </div>
        );
    }
}
