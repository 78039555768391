import * as React from 'react';
import ErrorMenu from 'components/cms/ErrorMenu';
import {BrComponent} from '@bloomreach/react-sdk';

export class PageNotFound extends React.Component {
    render() {
        if (typeof document !== 'undefined')
            document && this.insertTitle();
        return (<React.Fragment>
            <div className="center-block" style={{textAlign: 'center'}}>
                <BrComponent path={'main/container'} />
            </div>
            <BrComponent path={'error/error-menu'}><ErrorMenu /></BrComponent>
        </React.Fragment>)
    }

    insertTitle() {        
        if (Array.from(document.querySelectorAll("head>title")).length >= 1) return;
        var titleElement = document.createElement('title');
        titleElement.innerHTML = "Page Not Found : PartsSource - Healthcare Products and Solutions";
        document.querySelector("head").appendChild(titleElement);
    }       
   
}
