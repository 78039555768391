import * as React from 'react';
import {withRouter} from 'react-router';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import * as ReactRouterPropTypes from 'react-router-prop-types';

import 'less/footer.less';

@withRouter
export class SecureFooter extends React.Component {
    static propTypes = ReactRouterPropTypes;
    
    constructor(props) {
        super(props);
    }

    render() {
        const {location} = this.props;
        const isCheckout = location.pathname === '/checkout' || location.pathname === '/checkout/';

        return (<div className="secure-footer-wrapper">
            <div className="container-fluid">
                <div className="row secure-footer" data-ea-zone="Footer~Secure-Footer">
                    <div className="col-md-12">
                        <div className="secure-footer-content">
                            <div style={{fontSize: '15px', fontWeight: '600'}}>Phone Assistance</div>
                            <div>
                                <i className="fa fa-phone" /><span style={{marginLeft: '5px'}}><a href={'tel:+1' + defaultPhoneNumber}>{defaultPhoneNumber}</a></span>
                            </div>
                            {isCheckout && <div style={{fontSize: '12px', fontWeight: 'bold', marginTop: '10px'}}>
                                    Terms and Conditions of sale
                            </div>}
                            {isCheckout && <div style={{fontSize: '12px', maxWidth: '535px', lineHeight: '18px'}}>
                                    This order incorporates by reference the PartsSource Terms and Conditions of Sale,
                                    available on our Terms and Conditions page or by hard copy upon request. By clicking above
                                    you acknowledge that you accept such Terms and Conditions.
                            </div>}
                            <div style={{marginTop: '15px'}}>
                                <span style={{marginRight: '15px', textDecoration: 'underline', cursor: 'pointer'}}><a href="/policies/accessibility" target="_blank" rel="noopener noreferrer">Accessibility Policy</a></span>
                                <span style={{
                                    marginRight: '15px',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                }}>
                                    <a href="/policies/termsofuse" target="_blank" rel="noreferrer noopener">
                                            Terms of Use
                                    </a>
                                </span>
                                <span style={{marginRight: '15px', textDecoration: 'underline', cursor: 'pointer'}}>
                                    <a href="/policies/termsandconditionofsale" target="_blank" rel="noreferrer noopener">
                                            Terms and Conditions
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
