import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';

import {getOneSourceOemMappings, refreshOneSourceOems} from 'stores/Companies';

export default function useOneSourceOemMappings() {
    const oneSourceMappings = useSelector(state => state.companies.oneSourceMappings);
    const dispatch = useDispatch();

    useEffect(() => {
        if (oneSourceMappings.length === 0) {
            dispatch(getOneSourceOemMappings());
        }
    }, [oneSourceMappings.length]);

    async function refreshMappings() {
        await dispatch(refreshOneSourceOems());
        await dispatch(getOneSourceOemMappings());
    }

    return [oneSourceMappings, refreshMappings];
}
