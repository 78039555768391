import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useMenu from 'hooks/useMenu';
import { useLocation } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const MyAccountLogo = styled.div`
    display: flex;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
`;

const Logo = styled.div`
  width: 33.5px;
  height: 33.5px;
  background-color: #003663;
  border-radius: 50%;
  flex-shrink: 0;
`;

const MyAccountTitleText = styled.div`
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.24px;
  text-align: left;
`;

const MyAccountTitle = () => {
    const {title} = useMenu();

    return (
        <Container>
            <MyAccountLogo>
                <Logo />
            </MyAccountLogo>
            <MyAccountTitleText>{title}</MyAccountTitleText>
        </Container>
    );
};

export default MyAccountTitle;
