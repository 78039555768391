import axios from 'axios';
import PropTypes from 'prop-types'
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import {formatMoney, slugify, getCookie, logEventVerbose} from 'utility';
import {getCompanyAndVendorFields, getPartCondition, getPurchaseChoice} from 'productUtility';
import AddToCartConfirmation from 'components/AddToCartConfirmation';
import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';
import {QuantitySelector, TextField, Button, RadioButton, TextFieldSelect} from '@partssourceinc/react-ui-core';
import {ProductImage} from '@partssourceinc/react-ui-core';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';
import {getProps65Message} from 'productUtility';
import Acknowledgement from 'components/Acknowledgement';
import _ from 'lodash';
import 'less/quickorder.less';
import {ExchangeAcknolwedgementDialog} from 'components/Settings/ExchangeAcknolwedgementDialog';

@withRouter
@connect((state) => ({user: state.user}), _.merge({}, CartStore.actionCreators, UserStore.actionCreators))
export default class QuickOrder extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        maxParts: PropTypes.number,
        show: PropTypes.bool,
        ...CartStore.ActionShape,
        user: UserStore.StateShape,
    };

    static defaultProps = {
        maxParts: 5,
    };

    constructor(props) {
        super(props);

        const {user: {facility, settings: {requiredFields}}} = this.props;

        this.state = {
            left: '0px',
            top: '0px',
            parts: [],
            productsLoaded: false,
            numberOfParts: 3,
            selectedFacility: facility,
            showAddToCartConfirmation: false,
            showErrors: false,
            acknowledgementState: false,
            companyFields: requiredFields || [],
            isExchangeTermsChecked: false,
            showExchangeAcknowledgement: false,
            selectedExchangeOptions: [],
            isLoading: false
        };
    }

    onCancel() {
        const {onCancel} = this.props;
        onCancel();
    }   

    onFieldContentUpdate(fields, index) {
        const {parts} = this.state;
        parts[index] = _.merge(parts[index], {fields: fields});
        this.setState({parts: parts});
    }
    onUrgencyUpdate(checked, index) {
        const {parts} = this.state;
        parts[index] = _.merge(parts[index], {isUrgency: checked});
        this.setState({parts: parts});
    }
    onPartNumberChange(event, index) {
        const {parts} = this.state;
        parts[index] = _.merge(parts[index], {partNumber: event.target.value});

        this.setState({parts: parts});
    }

    onQuantityChange(index, quantity) {
        const {parts} = this.state;
        parts[index] = _.merge(parts[index], {quantity: parseInt(quantity)});
        this.setState({parts: parts});
    }

    onEntryRowEntered(index) {
        const {numberOfParts} = this.state;
        const {maxParts} = this.props;

        if (index + 1 === numberOfParts && numberOfParts < maxParts) {
            this.setState({numberOfParts: numberOfParts + 1});
        }
    }

    handleKeyUp(event) {
        const {productsLoaded, parts, selectedFacility, showAddToCartConfirmation} = this.state;
        const hasParts = parts && parts.length && parts.filter(p => p.partNumber).length > 0;
        const hasMissingFields = _.flatMap(parts).filter(x => x && !x.value && x.isRequired).length > 0;
        const hasInvalidFields = _.flatMap(parts).filter(x => x.value && x.formatRegex && !new RegExp(`^${x.formatRegex}$`, '').test(x.value)).length > 0;
        let submitDisabled = hasMissingFields || hasInvalidFields || (productsLoaded && !parts.some(p => p.selectedOptionIndex || p.selectedOptionIndex === 0) || !productsLoaded && (!hasParts || !selectedFacility || selectedFacility.facilityId === -1));

        if (event.key === 'Enter' && !submitDisabled && !showAddToCartConfirmation) {
            this.onSubmit();
        }
    }

    renderEntryRow(index) {
        const {selectedFacility, parts} = this.state;
        let partNumber = parts && parts[index] && parts[index].partNumber || '';
        let qty = parts && parts[index] && parts[index].quantity || 1;
        return (<div key={`part_row_${index}`} className="quick-order_entry">
            <TextField text={partNumber} id={`item_${index}`} key={`item_${index}`} onFocus={(e) => this.onEntryRowEntered(index)} showSearchIcon={true} tabIndex={index + 1} autoFocus={index === 0 && selectedFacility ? true : false} label="Item #" onChange={(e) => this.onPartNumberChange(e, index)} placeholder="Enter Item #" /> 
            <QuantitySelector className="field" tabIndex={index + 2} onFocus={(e) => this.onEntryRowEntered(index)} key={`qty_${index}`} selectedValue={qty} onChange={(e) => this.onQuantityChange(index, e.target.value)} />
        </div>)
    }

    onProductOptionChanged(e, index) {
        const {parts,selectedFacility} = this.state;
        const selectedOptionIndex = parseInt(e.target.value);
        let selectedFacilityId = (selectedFacility && selectedFacility.facilityId) ? selectedFacility.facilityId : 0;
        const requiredFields = getCompanyAndVendorFields(false, false, parts[index].options[selectedOptionIndex], selectedFacilityId).filter(x => x.isRequired || !_.isEmpty(x.value)) || [];

        parts[index] = Object.assign({}, parts[index], {selectedOptionIndex: selectedOptionIndex, fields: requiredFields});
        
        this.setState({parts: parts});
    }

    renderProductOption(option, quantity, index, selectedIndex, productIndex) {
        const {user: {settings: {hidePricing}}} = this.props;
        let bulkPricingValues = option.bulkPricingSchedule ? option.bulkPricingSchedule.filter(bp => bp.tierPrice < option.price) : null;
        let bulkPrice = bulkPricingValues && bulkPricingValues.filter(x => x.minQtyRange <= quantity && (x.maxQtyRange >= quantity || x.minQtyRange === x.maxQtyRange));
        let displayPrice = (bulkPrice.length && bulkPrice[0].tierPrice) || option.price;
 
        return (<RadioButton 
            checked={index == selectedIndex} 
            name={`${productIndex}`}
            id={`option_${productIndex}_${index}`} 
            style={{zIndex: '50000'}} 
            key={`option_${productIndex}_${index}`} 
            value={index}
            onChange={(e) => this.onProductOptionChanged(e, productIndex)}>
            {option.purchaseChoice === 2 && <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '15px', padding: '0 2px 0 0'}} />}
            {getPartCondition(option.lineItemCondition)} - {getPurchaseChoice(option.purchaseChoice)}
            {!hidePricing && <span className="lbl-bold">{` ${formatMoney(displayPrice)}`}</span>}
        </RadioButton>)
    }

    removePart(index) {
        const {parts: prevParts} = this.state;
        let parts = prevParts.splice(0);

        if (parts.length === 1) {
            this.reset();
        } else {
            parts.splice(index, 1);
            this.setState({parts: parts});
        }
    }

    renderPartRow(part, index) {
        const {quantity, options, product, selectedOptionIndex, fields} = part;
        const {showErrors, parts, acknowledgementState, selectedFacility} = this.state;
        const hasMissingFields = (fields || []).filter(x => !x.value && x.isRequired).length > 0;
        const invalidFields = (fields || []).filter(x => x.value && x.formatRegex && !new RegExp(`^${x.formatRegex}$`, '').test(x.value));
        const hasInvalidFields = invalidFields && invalidFields.length > 0;       
        let selectedFaciityId = (selectedFacility && selectedFacility.facilityId) ? selectedFacility.facilityId : 0;
        let selectedOption = options && options[selectedOptionIndex];
        let chemicals = (selectedOption && selectedOption.attributes && selectedOption.attributes.filter(a => a.name.toLowerCase() === 'prop65r' || a.name.toLowerCase() === 'prop65c')) || null;
        const imageUrl = product && product.images && product.images.length > 0 ? product.images[0].thumbnail : null;
        const altText = product && product.images && product.images.length > 0 ? product.images[0].altText : '';
        const companyAndVendorFields = part && part.options ? getCompanyAndVendorFields(false, false, part.options[selectedOptionIndex], selectedFaciityId) || [] : [];
        const requiredFields = companyAndVendorFields.map(item1 => ({
            ...item1,
            ...(fields.find(item2 => item2.fieldUid === item1.fieldUid) || {})
          }));

        return (
            <div className="quick_order_wrapper">
                <div className="quick-order_part" key={`part_row_${index}`}>
                    <div>
                        <div className="quick-order_part_header">
                            <ProductImage url={imageUrl} style={{width: '80px'}} altText={altText} />
                            <div>
                                {product && <NavLink onClick={::this.onCancel} to={`/parts/${product.oemSeo}/${product.partNumber}`}>{product.title}</NavLink>}
                                <div>{`Item # ${(product && product.partNumber) || part.partNumber}`}</div>
                            </div>
                        </div>

                        {options && options.length ? <div className="quick-order_part_options">
                            {options.map((o, i) => this.renderProductOption(o, quantity, i, selectedOptionIndex, index))}
                        </div> : <div className="quick-order_part_message">Item not available for Quick Order, please use site search instead</div>}
                        
                        {fields && fields.length > 0 ? <div style={{display: 'inline'}}>

                            <AssetInformationForm 
                                fields={requiredFields} 
                                formType={AssetFormType.Both} 
                                key={`aif_${index}`} 
                                ref={`assetForm_${index}_${selectedOptionIndex}`} 
                                hideSaveButton={true} 
                                autoFocus={index === 0} 
                                onFieldUpdate={(requiredFields) => this.onFieldContentUpdate(requiredFields, index)} 
                                onCheckBoxUpdate={(i) => this.onUrgencyUpdate(i.checked, index)}
                                priority={false} 
                                isRepairOption={selectedOption?.isRepairOption}
                                showErrors={showErrors && (hasMissingFields || hasInvalidFields)}
                                hideTitle={true}
                                invalidField = {invalidFields}
                                displayInline={true} />
                        </div> : null}
                    </div>

                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        {options && options.length > 0 && <QuantitySelector className="quantity_wrapper_qty-field" key={`qty_${index}`} tabIndex={0} selectedValue={quantity} onChange={(e) => this.onQuantityChange(index, e.target.value)} />}
                        <i className="fa fa-times-circle" aria-hidden="true" onClick={() => this.removePart(index)} />
                    </div>
                </div>
                {chemicals && chemicals.length 
                    ? <div className="note_warning" style={{padding: '10px'}}>
                        <div className="content">
                            <img src="/images/prop65warning.png" alt="warning" />
                            <p className="lbl-info">{getProps65Message(chemicals)}</p>
                        </div>
                    </div> 
                    : null}                 
            </div>);
    }

    acknowledge() {
        if (!this.props.confirmAcknowledgement()) {
            return;
        } else {
            this.setState({acknowledgementState: false});
            this.addToCart();
        }
    }

    render() {
        const {user: {facilities}, show} = this.props;
        const {acknowledgementState, productsLoaded, parts, numberOfParts, selectedFacility, showAddToCartConfirmation, top, left, showExchangeAcknowledgement, selectedExchangeOptions, isLoading} = this.state;

        if (!show) return null;
        const hasParts = parts && parts.length && parts.filter(p => p.partNumber).length > 0;
        const hasMissingFields = _.flatMap(parts).filter(x => x && !x.value && x.isRequired).length > 0;
        let submitDisabled = hasMissingFields || (productsLoaded && !parts.some(p => p.selectedOptionIndex || p.selectedOptionIndex === 0) || !productsLoaded && (!hasParts || !selectedFacility || !selectedFacility.facilityId));
        const itemNumbers = parts && parts.length && parts.map(p => p.partNumber);   

        if (showExchangeAcknowledgement && !showAddToCartConfirmation) 
            return (
                <ExchangeAcknolwedgementDialog 
                    onCancel={() => this.setState({showExchangeAcknowledgement: false, isExchangeTermsChecked: false})} 
                    onConfirm={() => {this.addToCart(true)}}
                    parts={selectedExchangeOptions} 
                />)

        return !showAddToCartConfirmation ? 
        <React.Fragment>
            <div className="modal-backdrop" onMouseUp={this.handleClick} />
            <div ref={(popup) => {
                this.popup = popup; 
            }} className="quick-order" style={{top, left}}>
                <div className="quick-order_content">
                    <div className="quick-order_header">
                        <button type="button" className="close" onClick={::this.onCancel}>
                            <span>×</span>
                        </button>
                    </div>           
                    <div className="quick-order_main">
                        <h1>Quick Order</h1>
                        {!productsLoaded && <TextFieldSelect id="facilityId" selectedItem={selectedFacility} autoFocus={!selectedFacility || selectedFacility.facilityId === -1} required={true} disableClear={true} keyField="facilityId" valueField="facilityName" onSelect={::this.onSelectFacility} listItems={facilities} label="Facility" placeHolder="Facility" />}
                        {!productsLoaded ? Array(numberOfParts).fill().map((e, i) => this.renderEntryRow(i)) : parts.map((p, i) => this.renderPartRow(p, i))}
                        {acknowledgementState && <Acknowledgement acknowledgeRisk={::this.acknowledge} itemNumbers={itemNumbers} onCancel={() => this.setState({acknowledgementState: false})} />}
                    </div>

                    <div className="quick-order_footer">
                        <a onClick={::this.reset} tabIndex={0}>RESET</a>
                        <Button disabled={submitDisabled} onClick={::this.onSubmit} tabIndex={7} loading={isLoading}>{productsLoaded ? 'Add to cart' : 'Submit'}</Button>
                    </div>
                </div>
            </div>
        </React.Fragment> : <AddToCartConfirmation show={true} isList={false} isQuickOrder={true} multipleProducts={true} quantity={parts.length} onCancel={::this.handleAddToCartCancel} onConfirm={::this.handleAddToCartConfirm} />
    }

    handleAddToCartConfirm() {
        const {onCancel, history} = this.props;
        onCancel();
        history.push('/cart');
    }

    handleAddToCartCancel() {
        const {onCancel} = this.props;
        this.reset();
        this.setState({showAddToCartConfirmation: false, selectedFacility: null});
        onCancel();
    }

    onSelectFacility(facility) {
        this.setState({selectedFacility: facility});       
    }

    reset() {
        this.setState({parts: [], productsLoaded: false, numberOfParts: 3, hasParts: false, isExchangeTermsChecked: false, selectedExchangeOptions: []});
    }

    onSubmit() {
        const {productsLoaded} = this.state;

        if (productsLoaded) {
            this.checkForProp65Approval().then(resp => {
                this.setState({acknowledgementState: true});
                return;            
            })
                .catch(err => {
                    this.setState({acknowledgementState: false});
                    this.addToCart();
                });           
        } else {
            this.getProducts();         
        }
    }

    checkForProp65Approval() {
        return new Promise((resolve, reject) => {
            const {checkProp65} = this.props;
            const {parts} = this.state;
            let chemicals = parts && parts.length > 0
                ? parts.map(p => {
                    if (p.options && p.options[p.selectedOptionIndex]
                            && p.options[p.selectedOptionIndex].attributes 
                            && p.options[p.selectedOptionIndex].attributes.some(a => a.name.toLowerCase() === 'prop65r' || a.name.toLowerCase() === 'prop65c'))
                        return p;
                    else        
                        return null;   
                })
                : null;
            
            let filteredChem = chemicals.filter(el => el !== null);
            if (!filteredChem || (filteredChem && filteredChem.length < 1)) {
                reject(false);
                return;
            }
            checkProp65().then(resp => {
                if (filteredChem && filteredChem.length > 0 && resp.response.data && !resp.response.data.accepted) {
                    resolve(true);
                } else {
                    reject(false);
                }
            });        
        })
    }

    getProducts() {
        const {selectedFacility, parts: allParts} = this.state;
        const {user: {info: {contactId}}} = this.props;
        let parts = allParts.filter(p => p.partNumber);
        const partsRequest = parts.filter(p => p.partNumber).map(p => {
            return {partNumber: slugify(p.partNumber), selectedFacilityId: selectedFacility.facilityId, customerId: selectedFacility.facilityId, requestorId: contactId};
        });

        this.setState({isLoading: true});
        axios.post(`/CatalogService/api/v1/products/GetCatalogItems`, partsRequest).then(response => {
            this.setState({isLoading: false});
            let products = _.orderBy(response.data, ['index']);
            parts = parts.map((p, i) => {
                p.quantity = p.quantity || 1;
                let product = products.find(prd => prd.value.index === i);
                
                if (product) {
                    const partOptions = (product.value && !_.isEmpty(product.value.options) && product.value.options.filter(o => getPartCondition(o.lineItemCondition) !== 'Repair' && o.price)) || [];
                    product.value.options = partOptions;
                    p.selectedOptionIndex = partOptions.length ? 0 : null;
                    p.fields = [];
                    if (partOptions && partOptions.length) {
                        p.fields = getCompanyAndVendorFields(false, false, partOptions[0], selectedFacility.facilityId).filter(f => f.isRequired || !_.isEmpty(f.value)) || [];
                    }                    
                    return _.merge({}, p, product.value);
                }
                return _.merge({}, p);
            });

            this.setState({parts: parts, productsLoaded: true});
        });
    }
    
    addToCart(isExchangeTermsChecked) {
        const {parts, selectedFacility} = this.state;
        const {user: {info: {contactId}}, loadUserCart} = this.props;
        const addToCartParts = parts.filter(p => p.product && p.options && (p.selectedOptionIndex || p.selectedOptionIndex === 0));

        const fields = addToCartParts.flatMap(part => part.fields);
        const hasMissingFields = fields.filter(x => !x.value && x.isRequired).length > 0;
        const hasInvalidFields = fields.filter(x => x.value && x.formatRegex && !new RegExp(`^${x.formatRegex}$`, '').test(x.value)).length > 0;

        if (hasMissingFields || hasInvalidFields) {
            this.setState({showErrors: true});
            return;
        }
      
        const exchangePartOptions = addToCartParts.map(p => {
            const {product: {title, displayPartNumber}} = p;
            const option = p.options[p.selectedOptionIndex];
            return option.purchaseChoice === 2 ? {...option, ...{title, displayPartNumber}} : null;
        }).filter(x => x != null);

        if(!isExchangeTermsChecked && exchangePartOptions.length > 0) {
            const selectedExchangeOptions = addToCartParts.length > 1 ? exchangePartOptions : [];
            this.setState({showExchangeAcknowledgement: true, selectedExchangeOptions});
            return;
        }

        this.setState({addingToCart: true, isLoading: true});

        let items = addToCartParts.map((item, i) => {
            const {quantity, options, product, selectedOptionIndex, isUrgency} = item;            
            let selectedOption = options[selectedOptionIndex];
            let image = selectedOption.images && selectedOption.images[0].image177;

            return item = {
                UserId: contactId,
                CompanyId: selectedFacility.facilityId,
                GroupId: selectedFacility.groupId,
                RequesterId: contactId,
                PhoneNumber: '',
                VendorId: (selectedOption && selectedOption.vendorId) || 0,
                ConditionId: (selectedOption && selectedOption.lineItemCondition) || 0,
                purchaseChoiceId: (selectedOption && selectedOption.purchaseChoice) || 0,
                ResearchRedisId: product.researchRedisId,
                Quantity: quantity,
                ProductId: product.id,
                OemId: product.manufacturerId,
                partNumber: product.partNumber,
                PartNumberStripped: product.partNumber,
                description: product.description,
                ImagePath: image,
                WarrantyId: (selectedOption && selectedOption.lineItemWarranty) || 0,
                Fields: item.fields,
                IsQuote: false,
                UrgencyId: isUrgency || false,
                customerOrderKey: sessionStorage.wo_id ? sessionStorage.wo_id : '',
                isExchangeTermsChecked: (selectedOption || {}).purchaseChoice === 2 ? isExchangeTermsChecked : false
            };
        });

        let quickOrderItems = {};
        quickOrderItems = addToCartParts.map(x => {
            return {
                'name': x.partNumber,
                'id': x.product.id,
                'price': x.outrightListPrice,
                'brand': x.product.manufacturer,
                'category': x.product.modalityId,
                'variant': `${getPartCondition(x.options[x.selectedOptionIndex].lineItemCondition)} -  ${getPurchaseChoice(x.options[x.selectedOptionIndex].purchaseChoice)}`,
                'quantity': x.quantity,
            }
        });

        addToCartParts.forEach(x => {
            dataLayer.push({
                event: 'brAddToCart',
                brProductId: x.product.id,
                brProductSku: x.product.id,
            })
        });
   
        dataLayer.push({
            event: 'addToCart',
            ecommerce: {
                add: {
                    products: quickOrderItems, 
                },
            },
        });

        axios.post('/ShoppingService/api/v1/cart/addItems', items).then(() => {
            loadUserCart();
            this.setState({addingToCart: false, isLoading: false, showAddToCartConfirmation: true, parts: addToCartParts, acknowledgementState: false});
            this.sendLog(addToCartParts);
        });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
        document.addEventListener('keyup', ::this.handleKeyUp, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
        document.removeEventListener('keyup', ::this.handleKeyUp, false);
    }

    handleClick = (e) => {
        const {showAddToCartConfirmation} = this.state;
        if (showAddToCartConfirmation || (this.popup && this.popup.contains(e.target)) || e.target.id === 'lnkQuickOrder' || e.target.nodeName === 'HTML') {
            return;
        }

        this.onCancel();
    };

    sendLog(parts) {
        const id_ins = getCookie('id_ins');
        parts.forEach((part, index) => {
            const {product, options} = part;
            let logDataDetails = [];
            logDataDetails.push({name: 'id_ins', value: id_ins});
            logDataDetails.push({name: 'OEM Number', value: product.id});
    
            options.forEach((option, i) => {
                let index = i + 1;
                logDataDetails.push({name: `Condition ${index.toString()} PS Number`, value: option.psPartNumber});
                logDataDetails.push({name: `Condition ${index.toString()} PS Number Price`, value: option.price});
            });
            logEventVerbose('QUICK ORDER', logDataDetails);
        });
    }

    componentDidMount() {
        let rectDom = document.getElementById('lnkQuickOrder');

        if (rectDom) {
            let rect = rectDom.getBoundingClientRect();
            this.setState({left: rect.right - 400 + 'px', top: rect.top + rect.height + 'px'});
        }          
    }
}
