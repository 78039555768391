import * as PropTypes from 'prop-types';
import QueryString from 'query-string';
import * as React from 'react';
import Loadable from 'react-loadable';
import {connect} from 'react-redux'
import {matchPath, withRouter} from 'react-router';
import {Redirect, Route, Switch} from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {_cmsComponentDefinitions} from 'data/ComponentDefinitions';
import * as ParamsStore from 'stores/Params';
import * as NetworkStore from 'stores/Network';
import * as Socket from 'stores/socket';
import {translateRedirect, getLocationQuery, setSigSessionId} from 'utility';
import * as BloomreachStore from 'stores/BloomReach';
import {BrPage, BrPageContext} from '@bloomreach/react-sdk';
import Page from 'pages/Page';
import * as SystemStore from 'stores/System';
import {LoginCallback} from '@okta/okta-react';
import {oktaSignInConfig} from '../oktaConfig';
import Spinner from 'components/Spinner';

import 'less/libs.css';
import 'less/layout.less';

function Loading(props) {
    const {error} = props;
    if (error && typeof location !== 'undefined' && location.hostname !== 'localhost') {
        location.reload(true);
    }
    return null;
}

Loading.propTypes = {
    error: PropTypes.any,
};

let configuration = {
    httpClient: axios.create({headers: {}}),
    endpoint: `${process.env.REACT_APP_CMS_URL}/site/resourceapi`,
    baseUrl: process.env.REACT_APP_CMS_URL,
};

const PsPlus = Loadable({loader: () => import(/* webpackChunkName: "PsPlus" */'pages/PsPlus'), loading: Loading});
const ManagementTracker = Loadable({loader: () => import(/* webpackChunkName: "ManagementTracker" */'pages/PsPlus/ManagementTracker'), loading: Loading});
const Home = Loadable({loader: () => import(/* webpackChunkName: "Home" */'pages/Home'), loading: Loading});
const CommandCenter = Loadable({loader: () => import(/* webpackChunkName: "CommandCenter" */'pages/CommandCenter/CommandCenter'), loading: Loading});
const ViewCart = Loadable({loader: () => import(/* webpackChunkName: "ViewCart" */'pages/ViewCart'), loading: Loading});
const Checkout = Loadable({loader: () => import(/* webpackChunkName: "Checkout" */'pages/Checkout/Checkout'), loading: Loading});
const OrderConfirmation = Loadable({loader: () => import(/* webpackChunkName: "OrderConfirmation" */'pages/OrderConfirmation'), loading: Loading});
const AwaitingPo = Loadable({loader: () => import(/* webpackChunkName: "AwaitingPo" */'pages/AwaitingPo'), loading: Loading});
const Orders = Loadable({loader: () => import(/* webpackChunkName: "Orders" */'pages/Orders'), loading: Loading});
const Loaners = Loadable({loader: () => import(/* webpackChunkName: "Loaners" */'pages/Loaners'), loading: Loading});
const OrderHistory = Loadable({loader: () => import(/* webpackChunkName: "OrderHistory" */'pages/OrderSearch'), loading: Loading});
const HubSpotMediaGuide = Loadable({loader: () => import(/* webpackChunkName: "HubSpotMediaGuide" */'pages/HubSpotMediaGuide'), loading: Loading});
const HubSpotContact = Loadable({loader: () => import(/* webpackChunkName: "HubSpotContact" */'pages/HubSpotContact'), loading: Loading});
const Quotes = Loadable({loader: () => import(/* webpackChunkName: "Quotes" */'pages/Quotes'), loading: Loading});
const OnSiteService = Loadable({loader: () => import(/* webpackChunkName: "Quotes" */'pages/OnSiteService/OnSiteService'), loading: Loading});
const OnSiteServiceDetail = Loadable({loader: () => import(/* webpackChunkName: "OnSiteServiceDetail" */'pages/OnSiteService/OnSiteServiceDetail'), loading: Loading});
const CreateAdvancedList = Loadable({loader: () => import(/* webpackChunkName: "CreateAdvancedList" */'pages/AdvancedLists/CreateOrUpdateAdvancedList'), loading: Loading});
const UpdateADvancedList = Loadable({loader: () => import(/* webpackChunkName: "UpdateADvancedList" */'pages/AdvancedLists/CreateOrUpdateAdvancedList'), loading: Loading});
const QuoteDetails = Loadable({loader: () => import(/* webpackChunkName: "QuoteDetails" */'pages/QuoteDetails'), loading: Loading});
const Repairs = Loadable({loader: () => import(/* webpackChunkName: "Repairs" */'pages/Repairs'), loading: Loading});
const RepairDetails = Loadable({loader: () => import(/* webpackChunkName: "RepairDetails" */'pages/RepairDetails'), loading: Loading});
const BrowsingHistory = Loadable({loader: () => import(/* webpackChunkName: "BrowsingHistory" */'pages/BrowsingHistory'), loading: Loading});
const Approvals = Loadable({loader: () => import(/* webpackChunkName: "Approvals" */'pages/Approvals'), loading: Loading});
const OrderDetail = Loadable({loader: () => import(/* webpackChunkName: "OrderDetail" */'pages/OrderDetail'), loading: Loading});
const ProfileCompanySettings = Loadable({loader: () => import(/* webpackChunkName: "ProfileCompany" */'pages/ProfileCompany'), loading: Loading});
const ProfileGroupSettings = Loadable({loader: () => import(/* webpackChunkName: "ProfileGroup" */'pages/ProfileGroup'), loading: Loading});
const ProfileCustomCatalogSettings = Loadable({loader: () => import(/* webpackChunkName: "ProfileCustomCatalog" */'pages/ProfileCustomCatalog'), loading: Loading});
const ProfileApprovalSettings = Loadable({loader: () => import(/* webpackChunkName: "ProfileApprovals" */'pages/ProfileApprovals'), loading: Loading});
const ReportsDashboardPowerBi = React.lazy(() => import('pages/PowerBi/ReportsDashboardPowerBi'));
const SingleReportsDashboardPowerBi = React.lazy(() => import('pages/PowerBi/SingleReportsDashboardPowerBi'));
const ReportsDashboardSingleWidgetPowerBi = React.lazy(() => import('pages/PowerBi/ReportsDashboardSingleWidgetPowerBi'));
const Community = Loadable({loader: () => import(/* webpackChunkName: "Community" */'pages/Community'), loading: Loading});
const Login = Loadable({loader: () => import(/* webpackChunkName: "Login" */'pages/Login'), loading: Loading});
const OktaLogin = Loadable({loader: () => import(/* webpackChunkName: "OktaLogin" */'pages/OktaLogin'), loading: Loading});
const RegisterStartPage = Loadable({loader: () => import(/* webpackChunkName: "Register" */'pages/Registration/RegistrationStartPage'), loading: Loading});
const ForgotPassword = Loadable({loader: () => import(/* webpackChunkName: "ForgotPassword" */'pages/ForgotPassword'), loading: Loading});
const ForgotUsername = Loadable({loader: () => import(/* webpackChunkName: "ForgotUsername" */'pages/ForgotUsername'), loading: Loading});
const ChangePassword = Loadable({loader: () => import(/* webpackChunkName: "ChangePassword" */'pages/ChangePassword'), loading: Loading});
const Product = Loadable({loader: () => import(/* webpackChunkName: "Product" */'pages/Product'), loading: Loading});
const CmsLandingPage = Loadable({loader: () => import(/* webpackChunkName: "CmsLandingPage" */'pages/cms/CmsLandingPage'), loading: Loading});
const Brands = Loadable({loader: () => import(/* webpackChunkName: "Brands" */'pages/Brands'), loading: Loading});
const Shop = Loadable({loader: () => import(/* webpackChunkName: "Shop" */'pages/Shop'), loading: Loading});
const Models = Loadable({loader: () => import(/* webpackChunkName: "Models" */'pages/Models'), loading: Loading});
const Catalog = Loadable({loader: () => import(/* webpackChunkName: "Catalog" */'pages/Catalog'), loading: Loading});
const Lists = Loadable({loader: () => import(/* webpackChunkName: "Lists" */'pages/AdvancedLists/Lists'), loading: Loading});
const List = Loadable({loader: () => import(/* webpackChunkName: "List" */'pages/AdvancedLists/List'), loading: Loading});
const BuyAgain = Loadable({loader: () => import(/* webpackChunkName: "BuyItAgain" */'pages/BuyItAgain'), loading: Loading});
const SettingsLanding = Loadable({loader: () => import(/* webpackChunkName: "SettingsLanding" */'pages/SettingsLanding'), loading: Loading});
const MyAccountPersonalSetting = Loadable({loader: () => import(/* webpackChunkName: "MyAccountPersonalSetting" */'pages/MyAccountPersonalSetting'), loading: Loading});
const MyAccountNotifications = Loadable({loader: () => import(/* webpackChunkName: "MyAccountNotifications" */'pages/MyAccountNotifications'), loading: Loading});
const MyAccountPaymentMethod = Loadable({loader: () => import(/* webpackChunkName: "MyAccountPaymentMethod" */'pages/MyAccountPaymentMethod'), loading: Loading});
const MyAccountOemConnect = Loadable({loader: () => import(/* webpackChunkName: "MyAccountOemConnect" */'pages/MyAccountOemConnect'), loading: Loading});
const MyAccountOemOrders = Loadable({loader: () => import(/* webpackChunkName: "MyAccountOemOrders" */'pages/MyAccountOemOrders'), loading: Loading});
const MyAccountOemConnectView = Loadable({loader: () => import(/* webpackChunkName: "MyAccountOemConnectView" */'pages/MyAccountOemConnectView'), loading: Loading});
const MyAccountOemOrderDetails = Loadable({loader: () => import(/* webpackChunkName: "MyAccountOemOrderDetails" */'pages/MyAccountOemOrderDetails'), loading: Loading});
const MyAccountAddresses = Loadable({loader: () => import(/* webpackChunkName: "MyAccountAddresses" */'pages/MyAccountAddresses'), loading: Loading});
const RgaOrders = Loadable({loader: () => import(/* webpackChunkName: "RgaOrders" */'pages/RgaOrders'), loading: Loading});
const Error = Loadable({loader: () => import(/* webpackChunkName: "Error" */'pages/Error'), loading: Loading});
const OrderSearch = Loadable({loader: () => import(/* webpackChunkName: "OrderSearch" */'pages/OrderSearch'), loading: Loading});
const Prop65Acknowledgement = Loadable({loader: () => import(/* webpackChunkName: "Prop65Acknowledgement" */'pages/Prop65Acknowledgement'), loading: Loading});
const OneSourceDocumentLookup = Loadable({loader: () => import(/* webpackChunkName: "OneSourceDocumentLookup" */'pages/OneSourceDocumentLookup'), loading: Loading});
const Policies = Loadable({loader: () => import(/* webpackChunkName: "Policies" */'pages/Policies'), loading: Loading});
const PoliciesAcceptance = Loadable({loader: () => import(/* webpackChunkName: "PoliciesAcceptance" */'pages/PoliciesAcceptance'), loading: Loading});
const RepairWizard = Loadable({loader: () => import(/* webpackChunkName: "RepairWizard" */'pages/RepairWizard'), loading: Loading});
const RepairsPackingSlip = Loadable({loader: () => import(/* webpackChunkName: "RepairsPackingSlip" */'pages/RepairsPackingSlip'), loading: Loading});
const CustomerAdmin = Loadable({loader: () => import(/* webpackChunkName: "CustomerAdmin" */'pages/CustomerAdmin/CustomerAdmin'), loading: Loading});
const CustomerAdminUsers = Loadable({loader: () => import(/* webpackChunkName: "CustomerAdminUsers" */'pages/CustomerAdmin/CustomerAdminUsers'), loading: Loading});
const CustomerAdminAddUser = Loadable({loader: () => import(/* webpackChunkName: "CustomerAdminAddUser" */'pages/CustomerAdmin/CustomerAdminAddUser'), loading: Loading});
const CustomerAdminUserDetails = Loadable({loader: () => import(/* webpackChunkName: "CustomerAdminUserDetails" */'pages/CustomerAdmin/CustomerAdminUserDetails'), loading: Loading});
const CustomerAdminLocations = Loadable({loader: () => import(/* webpackChunkName: "CustomerAdminLocations" */'pages/CustomerAdmin/CustomerAdminLocations'), loading: Loading});
const CustomerAdminAddLocation = Loadable({loader: () => import(/* webpackChunkName: "CustomerAdminAddLocation" */'pages/CustomerAdmin/CustomerAdminAddLocation'), loading: Loading});
const CustomerAdminLocationDetails = Loadable({loader: () => import(/* webpackChunkName: "CustomerAdminLocationDetails" */'pages/CustomerAdmin/CustomerAdminLocationDetails'), loading: Loading});
const RequestQuote = Loadable({loader: () => import(/* webpackChunkName: "RequestQuote" */'components/RequestQuote'), loading: Loading});
const RequestService = Loadable({loader: () => import(/* webpackChunkName: "RequestService" */'pages/OnSiteServiceRequest/RequestService'), loading: Loading});
const RSARequestForm = Loadable({loader: () => import(/* webpackChunkName: "RSARequestForm" */'pages/OnSiteServiceRequest/RSARequestForm'), loading: Loading});
const AnonymousOrderStatus = Loadable({loader: () => import(/* webpackChunkName: "AnonymousOrderStatus" */'pages/AnonymousOrderStatus'), loading: Loading});
const VariantTestHarness = Loadable({loader: () => import(/* webpackChunkName: "VariantTestHarness" */'pages/Product/VariantTestHarness'), loading: Loading});
const VisualFormulary = Loadable({loader: () => import(/* webpackChunkName: "VisualFormulary" */'pages/VisualFormulary/VisualFormulary'), loading: Loading});
const VisualFormularyEvidence = Loadable({loader: () => import(/* webpackChunkName: "VisualFormularyEvidence" */'pages/VisualFormulary/VisualFormularyEvidence'), loading: Loading});
const TeamContactCenterLanding = Loadable({loader: () => import(/* webpackChunkName: "TeamContactCenter" */'pages/TeamContactCenterLanding'), loading: Loading});
const RequestQuotedRepair = Loadable({loader: () => import(/* webpackChunkName: "TeamContactCenter" */'pages/RequestQuotedRepair'), loading: Loading});


@connect(
    state => ({
        network: state.network,
        system: state.system,
        state: state,
        isLoggedIn: state.network.isLoggedIn,
        isImpersonation: state.network.tokenInfo.isImpersonation,
        loginId: state.network.tokenInfo.loginId,
        passwordExpired: state.network.tokenInfo.passwordExpired,
        eulaRequired: state.user.info.eulaRequired,
        bloomReach: state.bloomReach,
        hasReportsAccess: state.user.settings.hasReportsAccess,
        psCommunityAccess: state.user.settings.psCommunityAccess,
        defaultHomeScreen: state.user.settings.defaultHomeScreen,
        hasPSPlusAccess: state.user.settings.hasPSPlusAccess,
        hasDashboardv2Access: state.user.settings.hasDashboardv2Access,
        showTechNames: state.user.settings.showTechNames,
        showAllRecords: state.user.settings.showAllRecords,
        showAllRepairs: state.user.settings.showAllRepairs,
        showOtherTechQuotes: state.user.settings.showOtherTechQuotes,
        viewFormularyRules: state.user.settings.viewFormularyRules,
        editFormularyRules: state.user.settings.editFormularyRules,
        oneSource: state.user.settings.oneSource,
        configuration: state.system.configuration,
        admin: state.network.admin,
        hasCustomerAdminAccess: state.user.settings.customerAdminPermission != null,
        enableOnSiteService: state.user.settings.enableOnSiteService,
        oktaAuthentication: state.system.siteSettings.oktaAuthentication,
        sessionStorageAvailable: state.network.sessionStorageAvailable,
        siteSettingsLoaded: state.system.siteSettingsLoaded,
        isProgramAccountMember: state.user.settings.isProgramAccountMember,
        siteLvlCC: state.user.settings.siteLvlCC,
    }),
    _.merge({},
        ParamsStore.actionCreators,
        Socket.actionCreators,
        BloomreachStore.actionCreators,
        NetworkStore.actionCreators,
        SystemStore.actionCreators
    )
)
class Layout extends React.Component {
    static propTypes = {
        isLoggedIn: PropTypes.bool,
        loginId: PropTypes.number,
        hasReportsAccess: PropTypes.bool,
        hasDashboardv2Access: PropTypes.bool,
        showTechNames: PropTypes.bool,
        showAllRecords: PropTypes.bool,
        showAllRepairs: PropTypes.bool,
        showOtherTechQuotes: PropTypes.bool,
        psCommunityAccess: PropTypes.bool,
        oneSource: PropTypes.bool,
        hasPSPlusAccess: PropTypes.bool,
        defaultHomeScreen: PropTypes.string,
        hasCustomerAdminAccess: PropTypes.bool,
        ...ParamsStore.ActionShape,
    };

    componentWillReceiveProps = (newProps) => {
        const {location: {pathname: oldPathname, search: oldSearch}, setParams, setPageModel, bloomReach: {pageModel}} = this.props;
        const {location: {pathname: newPathname, search: newSearch}} = newProps;
        const oldUri = `${oldPathname}${oldSearch}`;
        const newUri = `${newPathname}${newSearch}`;

        if (newPathname !== oldPathname) {
            setPageModel(pageModel === undefined ? null : undefined);
        }

        if (typeof window !== 'undefined') {
            if (newPathname == '/catalog' && !newSearch)
                location.href = '/';

            if (newPathname !== oldPathname) {
                setParams(newProps.location, newProps.history, oldUri);
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
                // $('html, body').scrollTop(0);
            }
        }

        if (typeof (ga) !== 'undefined') {
            if (newUri !== oldUri) {
                // eslint-disable-next-line no-undef
                ga('gtm1.set', 'page', newUri);
                // eslint-disable-next-line no-undef
                ga('gtm1.send', 'pageview');
            }
        }

        if (newPathname !== oldPathname && newPathname.indexOf('/shop') === 0) {
            let categoryId = newPathname.replace('/shop/', '');
            dataLayer.push({
                event: 'brPageView',
                brPageType: 'category',
                brCategory: categoryId,
                brCategoryId: categoryId,
                brIsConversion: 0,
            });
        }
    }

    componentDidMount = () => {
        const {
            setParams,
            location,
            history,
            location: {pathname, search},
            isLoggedIn,
            hasDashboardv2Access,
            showTechNames,
            showAllRecords,
            showAllRepairs,
            showOtherTechQuotes,
            configuration: {features: {notifications, dashboard}},
            connectToWebsocket,
            bloomReach: {request, pageModel},
            setPageModel,
        } = this.props;

        setParams(location, history, `${pathname}${search}`);

        let cmsPathName = pathname === '/login' ? '' : `${pathname}${window.location.search || ''}`;
        if (cmsPathName !== request.path) {
            setPageModel(pageModel === undefined ? null : undefined);
        }

        if (pathname == '/catalog' && !search)
            location.href = '/';

        dataLayer.push({
            page: pathname + search,
        });

        if (pathname.indexOf('/shop') === 0) {
            let categoryId = pathname.replace('/shop/', '');
            dataLayer.push({
                event: 'brPageView',
                brPageType: 'category',
                brCategory: categoryId,
                brCategoryId: categoryId,
                brIsConversion: 0,
            });
        }

        if (isLoggedIn && dashboard && notifications && hasDashboardv2Access) {
            connectToWebsocket(showTechNames, showAllRecords, showAllRepairs, showOtherTechQuotes);
        }

        // setSigSessionId();
    }

    render() {
        const {siteSettingsLoaded, oktaAuthentication} = this.props;
        const redirectUrl = this.getRedirectUrl();
        if (redirectUrl) {
            return <Redirect to={redirectUrl} />;
        }

        const {location: {pathname, search}, bloomReach: {pageModel, request}} = this.props;
        let cmsPathName = pathname === '/login' ? '' : `${pathname}${window.location.search || ''}`;
        configuration.path = request.path !== cmsPathName ? cmsPathName : request.path;
        
        if (pathname === '/catalog')
            configuration.endpoint = `${process.env.REACT_APP_CMS_URL}/site/resourceapi/`;
        else
            configuration.endpoint = `${process.env.REACT_APP_CMS_URL}/site/resourceapi`;

        if (siteSettingsLoaded && !oktaAuthentication && pathname == '/login' && search.indexOf('?e') > -1) {
            return <Redirect to={'/changepassword'} />;
        }

        return (
            !siteSettingsLoaded && pathname == '/login' ? 
                <Spinner /> : 
                <div id="divBodyContainer" className="container-fluid">
                    <BrPage configuration={configuration} mapping={_cmsComponentDefinitions} page={pageModel}>
                        <BrPageContext.Consumer>
                            {(page) => (
                                this.renderPage(page)
                            )}
                        </BrPageContext.Consumer>
                    </BrPage>
                </div>
        );
    }

    renderPage(page) {
        const {
            hasReportsAccess,
            psCommunityAccess,
            hasCustomerAdminAccess,
            oneSource,
            hasPSPlusAccess,
            hasDashboardv2Access,
            configuration: {features: {dashboard}},
            location: {pathname, search},
            isLoggedIn,
            passwordExpired,
            eulaRequired,
            network: {tokenInfo: {isImpersonation}},
            viewFormularyRules,
            editFormularyRules,
            enableOnSiteService,
            oktaAuthentication,
            sessionStorageAvailable,
            siteSettingsLoaded,
            isProgramAccountMember,
            siteLvlCC,
        } = this.props;
        const name = page.getComponent().getName();
        const {_view} = getLocationQuery({search});
        const showCatalog = name === 'catalog' || _view === 'all';
        const isSSR = typeof location === 'undefined';
        
        return (
            <Switch>
                {showCatalog && <Route path="/shop/:cat1/:cat2?/:cat3?/:cat4?/:cat5?">
                    <Page forceWideScreen={true} isPublic={true}><Catalog /></Page>
                </Route>}
                {showCatalog && <Route path="/catalog/:oemName?/:modelName?">
                    <Page forceWideScreen={true} isPublic={true}><Catalog /></Page>
                </Route>}
                {showCatalog && <Route exact={true} path="/models/:oemName/:modelName?">
                    <Redirect to={pathname.replace('models', 'catalog')} />
                </Route>}
                <Route path="/psplus">
                    {(isLoggedIn && hasPSPlusAccess) ? <Page><PsPlus /></Page> : <Redirect to={'/'} />}
                </Route>
                <Route path="/management-tracker/:subpage">
                    {(isLoggedIn && hasPSPlusAccess) ? <Page><ManagementTracker /></Page> : <Redirect to={'/'} />}
                </Route>
                <Route path="/command-center">
                    {(isLoggedIn && !(hasDashboardv2Access && dashboard)) ? <Redirect to={'/'} /> : <Page><CommandCenter /></Page>}
                </Route>
                {(!isImpersonation && isLoggedIn && eulaRequired && !oktaAuthentication && !isSSR) && location.pathname.toLowerCase() !== '/policies-acceptance' && location.pathname.toLowerCase() !== '/login' && <Route path="*">
                    <Redirect to={'/policies-acceptance'} />
                </Route>}
                {(!isImpersonation && isLoggedIn && siteSettingsLoaded && !oktaAuthentication && passwordExpired && !eulaRequired) && location.pathname.toLowerCase() !== '/changepassword' && location.pathname.toLowerCase() !== '/login' && <Route path="*">
                    <Redirect to={'/changepassword'} />
                </Route>}
                <Route path="/terms-and-conditions">
                    <Redirect to={'/policies'} />
                </Route>
                <Route exact={true} path="/">
                    <Page forceWideScreen={true} isPublic={true}><Home /></Page>
                </Route>
                {!isLoggedIn && <Route exact={true} path="/reports"><Page><ReportsDashboardPowerBi /></Page>
                    {<Redirect to={<Page><Login /></Page>} />}
                </Route>}
                {isLoggedIn && !hasReportsAccess && <Route path="/reports"><Page><ReportsDashboardPowerBi /></Page>
                    {<Redirect to={<Page><Home /></Page>} />}
                </Route>}
                <Route exact={true} path="/error">
                    <Page forceWideScreen={true} isPublic={true}><Error /></Page>
                </Route>
                <Route exact={true} path="/history">
                    <Page forceWideScreen={true} isPublic={true}><BrowsingHistory /></Page>
                </Route>
                <Route exact={true} path="/anonymousorderstatus">
                    <Page forceWideScreen={true} isPublic={true}><AnonymousOrderStatus /></Page>
                </Route>
                <Route
                    exact={true}
                    path="/policies/(privacypolicy|cookiespolicy|accessibility|termsandconditionofpurchase|termsandconditionofsale|termsofuse|eula|policies)">
                    <Page forceWideScreen={true} isPublic={true}><Policies /></Page>
                </Route>
                <Route exact={true} path="/policies-acceptance">
                    <Page showSecureHeaderFooter={true} forceWideScreen={true} isPublic={true}><PoliciesAcceptance /></Page>
                </Route>
                <Route exact={true} path="/brands">
                    <Page forceWideScreen={true} isPublic={true}><Brands /></Page>
                </Route>
                <Route exact={true} path="/shop">
                    <Page forceWideScreen={true} isPublic={true}><Shop /></Page>
                </Route>
                <Route exact={true} path="/models">
                    <Page forceWideScreen={true} isPublic={true}><Models /></Page>
                </Route>
                <Route exact={true} path="/catalog">
                    <Page forceWideScreen={true} isPublic={true}><Catalog /></Page>
                </Route>
                <Route exact={true} path="/catalog/preview">
                    <Page forceWideScreen={true} isPublic={true}><Catalog /></Page>
                </Route>
                <Route exact={true} path="/cart">
                    <Page isPublic={false}><ViewCart /></Page>
                </Route>
                <Route exact={true} path="/checkout">
                    <Page showSecureHeaderFooter={true} isPublic={false}><Checkout /></Page>
                </Route>
                <Route exact={true} path="/checkout/confirmation"><Page><OrderConfirmation /></Page></Route>
                <Route exact={true} path="/lists"><Page forceWideScreen={true}><Lists /></Page></Route>
                <Route exact={true} path="/lists/create/:oemSeo?/:partNumber?/:quantity?/:psPartNumber?">
                    <Page><CreateAdvancedList /></Page>
                </Route>
                <Route exact={true} path="/list/:id"><Page><List /></Page></Route>
                <Route exact={true} path="/list/:id/edit"><Page><UpdateADvancedList /></Page></Route>
                <Route exact={true} path="/buy-again/:name?">
                    <Page forceWideScreen={true}><BuyAgain /></Page>
                </Route>
                <Route exact={true} path="/orders/po"><Page><AwaitingPo /></Page></Route>
                <Route exact={true} path="/orders/rga/:tab?"><Page><RgaOrders /></Page></Route>
                <Route exact={true} path="/orders"><Page><Orders /></Page></Route>
                <Route exact={true} path="/loaners"><Page><Loaners /></Page></Route>
                <Route exact={true} path="/supplier/request-media-guide">
                    <Page isPublic={true}><HubSpotMediaGuide /></Page>
                </Route>
                <Route exact={true} path="/supplier/contact">
                    <Page isPublic={true}><HubSpotContact /></Page>
                </Route>
                <Route exact={true} path="/orders/history"><Page><OrderHistory /></Page></Route>
                <Route exact={true} path="/orders/search"><Page><OrderSearch /></Page></Route>
                <Route exact={true} path="/order"><Page><OrderDetail /></Page></Route>
                <Route exact={true} path="/order/:lineItemId"><Page><OrderDetail /></Page></Route>
                <Route exact={true} path="/aos/:lineItemId">
                    <Page isPublic={true}><AnonymousOrderStatus /></Page>
                </Route>
                <Route exact={true} path="/quotes"><Page><Quotes /></Page></Route>
                <Route exact={true} path="/on-site-service"><Page><OnSiteService /></Page></Route>
                <Route exact={true} path="/service/:id?"><Page><OnSiteServiceDetail /></Page></Route>
                <Route exact={true} path="/quotes/:lineItemId"><Page><QuoteDetails /></Page></Route>
                <Route exact={true} path="/repairs"><Page><Repairs /></Page></Route>
                <Route exact={true} path="/repairs/:lineItemId"><Page><RepairDetails /></Page></Route>
                <Route exact={true} path="/approvals"><Page><Approvals /></Page></Route>
                <Route exact={true} path="/profile/company"><Page><ProfileCompanySettings /></Page></Route>
                <Route exact={true} path="/profile/group"><Page><ProfileGroupSettings /></Page></Route>
                <Route exact={true} path="/profile/catalog"><Page><ProfileCustomCatalogSettings /></Page></Route>
                <Route exact={true} path="/profile/approvals"><Page><ProfileApprovalSettings /></Page></Route>
                <Route exact={true} path="/repairWizard"><Page><RepairWizard /></Page></Route>
                <Route exact={true} path="/orders/packingSlip"><Page><RepairsPackingSlip /></Page></Route>
                {hasReportsAccess && <Route exact={true} path="/reports"><Page><React.Suspense fallback={<>...</>}>
                    <ReportsDashboardPowerBi />
                </React.Suspense></Page>
                </Route>}
                {hasReportsAccess && <Route exact={true} path="/reports/:reportId/:useRemiAuth">
                    <Page><React.Suspense fallback={<>...</>}>
                    <ReportsDashboardSingleWidgetPowerBi />
                </React.Suspense></Page>
                </Route>}
                <Route path="/community">
                    {!isLoggedIn ? <Redirect to={'/login'} /> : (!psCommunityAccess ? <Redirect to={'/'} /> : <Page><Community /></Page>) }
                </Route>
                <Route path="/settings" exact={true}><Page><SettingsLanding /></Page></Route>
                <Route path="/settings/personal"><Page><MyAccountPersonalSetting /></Page></Route>
                <Route path="/settings/notifications"><Page><MyAccountNotifications /></Page></Route>
                {!isProgramAccountMember && !siteLvlCC && <Route path="/settings/payment"><Page><MyAccountPaymentMethod /></Page></Route>}
                <Route path="/oemconnect"><Page><MyAccountOemConnect /></Page></Route>
                <Route path="/oemconnectview/:contractId?"><Page><MyAccountOemConnectView /></Page></Route>
                <Route path="/OEMConnectOrders"><Page><MyAccountOemOrders /></Page></Route>
                <Route path="/OEMConnectOrder/:orderId?"><Page><MyAccountOemOrderDetails /></Page></Route>
                <Route path="/settings/addresses"><Page><MyAccountAddresses /></Page></Route>
                <Route path="/formulary" exact={true}>
                    {!(viewFormularyRules || editFormularyRules || isImpersonation) ? <Redirect to={'/'} /> : <Page><VisualFormulary /></Page>}                
                </Route>
                <Route path="/formulary/evidence/:id/:isExpertRecomendation?" exact={true}>
                    {!(viewFormularyRules || editFormularyRules || isImpersonation) ? <Redirect to={'/'} /> : <Page forceWideScreen={true}><VisualFormularyEvidence /></Page>}                
                </Route>
                {hasCustomerAdminAccess && <Route exact={true} path="/customeradmin"><Page><CustomerAdmin /></Page></Route>}
                {hasCustomerAdminAccess && <Route exact={true} path="/customeradmin/users"><Page><CustomerAdminUsers /></Page></Route>}
                {hasCustomerAdminAccess && <Route exact={true} path="/customeradmin/users/add"><Page><CustomerAdminAddUser /></Page></Route>}
                {hasCustomerAdminAccess && <Route exact={true} path="/customeradmin/users/:userId"><Page><CustomerAdminUserDetails /></Page></Route>}
                {hasCustomerAdminAccess && <Route exact={true} path="/customeradmin/locations"><Page><CustomerAdminLocations /></Page></Route>}
                {hasCustomerAdminAccess && <Route exact={true} path="/customeradmin/locations/add"><Page><CustomerAdminAddLocation /></Page></Route>}
                {hasCustomerAdminAccess && <Route exact={true} path="/customeradmin/location/:locationId"><Page><CustomerAdminLocationDetails /></Page></Route>}

                {!oktaAuthentication && <Route path="/login">
                    <Page showSecureHeaderFooter={true} isPublic={true}><Login /></Page>
                </Route>}
                {oktaAuthentication && <Route path="/login">
                    <Page showSecureHeaderFooter={true} isPublic={true}>
                        <OktaLogin config={oktaSignInConfig} sessionStorageAvailable={sessionStorageAvailable} />
                    </Page>
                </Route>}
                <Route path="/register">
                    <Page showSecureHeaderFooter={true} isPublic={true}><RegisterStartPage /></Page>
                </Route>
                <Route path="/Acknowledgement">
                    <Page showSecureHeaderFooter={true} isPublic={true}><Prop65Acknowledgement /></Page>
                </Route>
                <Route path="/forgot">
                    <Page showSecureHeaderFooter={true} isPublic={true}><ForgotPassword /></Page>
                </Route>
                <Route path="/forgotusername">
                    <Page showSecureHeaderFooter={true} isPublic={true}><ForgotUsername /></Page>
                </Route>
                <Route path="/changepassword">
                    <Page showSecureHeaderFooter={true} isPublic={true}><ChangePassword /></Page>
                </Route>
                <Route path="/teamcontactcenter">
                    <Page forceWideScreen={true} isPublic={false}><TeamContactCenterLanding /></Page>
                </Route> 
                <Route path="/parts/:oemName/:partNumber">
                    <Page forceWideScreen={true} isPublic={true}><Product /></Page>
                </Route>
                <Route path="/variants/:oemName/:partNumber">
                    <Page forceWideScreen={true} isPublic={true}><VariantTestHarness /></Page>
                </Route>
                {oneSource && <Route path="/onesource-document-search">
                    <Page showSecureHeaderFooter={true}><OneSourceDocumentLookup /></Page>
                </Route>}
                <Route exact={true} path="/quote/request">
                    <Page isPublic={false}><RequestQuote /></Page>
                </Route>
                <Route exact={true} path="/request-service">
                    {!enableOnSiteService ? <Redirect to={'/'} /> :<Page isPublic={false}><RequestService /></Page>}
                </Route>
                <Route exact={true} path="/request-rsa">
                    <Page isPublic={false}><RSARequestForm /></Page>
                </Route> 
                <Route exact={true} path="/request-quoted-repair">
                    <Page isPublic={false}><RequestQuotedRepair /></Page>
                </Route>               
                <Route exact={true} path="/logout">
                    <Page isPublic={true} showSecureHeaderFooter={false}>
                        <Spinner />
                    </Page>
                </Route>
                <Route exact={true} path="/login/callback" component={LoginCallback} />
                <Route path="/">
                    <Page fallbackPath={true} forceWideScreen={true} isPublic={true}><CmsLandingPage /></Page>
                </Route>
            </Switch>
        );
    }

    getRedirectUrl = () => {
        if (typeof location !== 'undefined') {
            const parsed = QueryString.parse(window.location.search);
            const match = matchPath(window.location.pathname, {
                path: '/:id',
                exact: true,
            });

            const {defaultHomeScreen} = this.props;
            return translateRedirect(parsed, match, defaultHomeScreen);
        }

        return '';
    };
}

export default withRouter(Layout);
