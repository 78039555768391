import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {Banner} from '@partssourceinc/react-ui-core/cms';
import _ from 'lodash';
import * as KevelStore from 'stores/Kevel';
import {getLocationQuery} from '../../utility';

const AdBanner = (props) => {
    const {component, page} = props;
    const params = component.getParameters() || {};
    const {zoneId, bannerStyle, keywords, adTypeId} = params;
    const preview = page.isPreview();
    const dispatch = useDispatch();
    const location = useLocation();
    const {getDecisions, fireClickImpression} = KevelStore.actionCreators;
    const [loaded, setLoaded] = useState(preview);
    const [zoneAd, setZoneAd] = useState(null);

    useEffect(() => {
        async function initialLoad() {
            const {pathname} = location;
            let isSearchPage = (pathname.indexOf('/catalog') > -1) ? true : false;
            let kw = [];
            if (!isSearchPage) {
                if (keywords)
                    kw = keywords.split(',').map(word => word.trim());
            } else {
                let urlParams = getLocationQuery(location);
                if (urlParams.q)
                    kw.push(urlParams.q.trim());

                _.flatten(Object.keys(urlParams)
                    .filter(k => k[0] !== '_' && k !== 'q')
                    .map(key => {
                        const fq = urlParams[key];
                        if (_.isArray(fq)) {
                            return fq.map(value => kw.push(value));
                        } else {
                            return kw.push(key);
                        }
                    }));
            }
            kw = [...new Set([].concat.apply([], kw))];
            try {
                const result = await dispatch(getDecisions([zoneId], [parseInt(adTypeId)], kw));
                const ad = result.find(z => z.zoneId === zoneId);

                if (ad) {
                    setZoneAd(ad);
                }

                setLoaded(true);
            } catch {
                setLoaded(true);
            }
        }

        if (zoneId && !isNaN(adTypeId) && !preview) {
            initialLoad();
        } else {
            setLoaded(true);
        }
    }, []);

    return (
        loaded ?
            (zoneAd ?
                <Banner preview={preview} bannerStyle={bannerStyle} ad={zoneAd} fireImpression={(url) => dispatch(fireClickImpression(url))} /> :
                <Banner {...props} preview={preview} />)
            :
            <div />
    );
};

export default AdBanner;

