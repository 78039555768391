import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {useState, useEffect} from 'react';

export const CreditHoldAlertType = {
    CreditHoldBanner: 'CreditHoldBanner',
    CreditHoldPartialBanner: 'CreditHoldPartialBanner',
    None: '',
}

const useCreditHold = () => {
    const location = useLocation();
    const {creditHold} = useSelector(state => state.user.settings);
    const modalURLs = ['/request-service', '/quote/request'];
    const {facility, facilities} = useSelector(state => state.user);
    const hasSelectedFacility = facility && parseInt(facility.facilityId) > 0;
    
    const [isCreditHold, setIsCreditHold] = useState(hasSelectedFacility ? facility.creditHold : creditHold);
    const [showCreditHoldModal, setShowCreditHoldModal] = useState(isCreditHold && modalURLs.some(url => url === location.pathname));

    const getCreditHoldAlert = () => {
        return facilities.every(f => f.creditHold) || (hasSelectedFacility && isCreditHold)
            ? CreditHoldAlertType.CreditHoldBanner
            : (facilities.some(f => f.creditHold) && !hasSelectedFacility) ? CreditHoldAlertType.CreditHoldPartialBanner 
                : CreditHoldAlertType.None;
    }

    const [creditHoldAlert, setCreditHoldAlert] = useState(getCreditHoldAlert());

    useEffect(() => {
        setIsCreditHold(hasSelectedFacility ? facility.creditHold : creditHold);
        setShowCreditHoldModal(isCreditHold && modalURLs.some(url => url === location.pathname));
        setCreditHoldAlert(getCreditHoldAlert());
    }, [location.pathname, facility]);

    return {isCreditHold, showCreditHoldModal, creditHoldAlert};
};

export default useCreditHold;
