import React from 'react';
import Slider from 'react-slick';
import {Phone} from 'responsive';
import {Button} from '@partssourceinc/react-ui-core';
import 'less/cms/rotatingbanner.less';
import {withRouter} from 'react-router';
import {BrManageContentButton} from '@bloomreach/react-sdk';

const RotatingBanner = (props) => {
    const {
        page,
        component,
        history,
    } = props;

    const {document: documentRef} = component.getModels() || {};
    const document = documentRef && page.getContent(documentRef);
    const banners = document && document.getData().banners || null;
    const params = component.getParameters() || {};

    const getAlignmentClass = (alignment, onlyOne, isFirst) => {
        if (alignment === 'left' || !alignment || (alignment !== 'right' && alignment !== 'center')) return; // default alignment

        let className = `${onlyOne ? 'only-one' : (isFirst ? 'first' : 'second')}-`;
        
        if (alignment === 'center') {
            className += 'center';
        } else if (alignment === 'right') {
            className += 'right';
        }

        return className;
    }

    const navigate = (e, ctaLink) => {
        e.preventDefault();
        const isExternal = /^https?:\/\//.test(ctaLink);

        if (isExternal)
            window.open(ctaLink)
        else
            history.push(ctaLink);
    }

    const renderBanner = () => (
        <div className="rotating-banner">
            <div style={{position: 'relative'}}><BrManageContentButton content={document} /></div>
            <Slider
                dots={true}
                autoplaySpeed={params.rotationSpeed || 3500}
                adaptiveHeight={true}
                autoplay={true}
            >
                {banners.map(({image, largeOverlay, smallOverlay, ctaText, ctaLink, overflowBackgroundColor, textColor, horizontalPosition, twoColumns, firstColumnAlignment, secondColumnAlignment}, i) => {
                    const onlyOneColumnAlignmentClass = !twoColumns ? getAlignmentClass(firstColumnAlignment, true) : undefined;
                    const firstColumnAlignmentClass = getAlignmentClass(firstColumnAlignment, false, true);
                    const secondColumnAlignmentClass = getAlignmentClass(secondColumnAlignment, false, false);

                    return (<div key={i} className="rotating-banner_container">
                        <Phone>
                            <div style={{
                                backgroundImage: `url(${page.getContent(image).getOriginal().getUrl()})`,
                                height: '180px',
                                backgroundColor: overflowBackgroundColor,
                                backgroundPosition: horizontalPosition ? `${parseInt(horizontalPosition)}% center` : 'center center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'}} />
                        </Phone>

                        <div
                            className={`overlay${twoColumns ? ' is-two-columns' : ''}${!onlyOneColumnAlignmentClass && firstColumnAlignmentClass ? ' ' + firstColumnAlignmentClass : ''}${!onlyOneColumnAlignmentClass && secondColumnAlignmentClass ? ' ' + secondColumnAlignmentClass : ''}${onlyOneColumnAlignmentClass ? ' ' + onlyOneColumnAlignmentClass : ''}`}
                            style={{
                                backgroundImage: `url(${page.getContent(image).getOriginal().getUrl()})`,
                                backgroundColor: overflowBackgroundColor,
                                height: '226px',
                                backgroundPosition: horizontalPosition ? `${parseInt(horizontalPosition)}% center` : 'center center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'}}>
                            <div className="fieldContainer">
                                {largeOverlay && <div className="h2-wrapper"><h2 style={{color: textColor}}>{largeOverlay}</h2></div>}
                                <div className="second-col-wrapper">
                                    {smallOverlay && <div className="h3-wrapper"><h3 className="ps-hidden-xs" style={{color: textColor}}>{smallOverlay}</h3></div>}
                                    {ctaText && <div className="button-wrapper"><Button key={ctaText} onClick={e => navigate(e, ctaLink)}>{ctaText}</Button></div>}
                                </div>
                            </div>
                        </div>
                    </div>);
                })}
            </Slider>
        </div>
    );

    return banners ? <React.Fragment>
        {renderBanner()}
    </React.Fragment> : null;
}

export default withRouter(RotatingBanner);
