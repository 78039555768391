import React, {useState} from 'react';
import {Popup, Checkbox} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import classNames from 'classnames';
import {getPartCondition} from 'productUtility';

const PopupContainer = styled(Popup)`
   max-width: 600px;
`;

const OptionListContainer = styled.div`
    margin-top: 40px;
    margin-bottom: 24px;
`;

const Option = styled.div`
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;

    &:last-of-type {
        border-bottom: none;
    }
`;

const PartDescription = styled.div`
    color: #005DA6;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
`;

const PartDetail = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;

    span {
        font-weight: 400;
    }
`;

const ContentContainer = styled.div`
    background-color: #f1f1f1;
    padding: 20px;
    margin-bottom: 32px;
    margin-top: 9px;
    display: inline-block;
`;

const ImageColumn = styled.div`
    display: inline-block;
    vertical-align: text-top;
    width: 100px;

    img {
        width: 100%;
    }
`;

const InformationColumn = styled.div`
    display: inline-block;
    width: calc(100% - 100px);
    padding-left: 30px;
    vertical-align: text-top;

    p {
        margin: 0 0 8px;

        &.bold {
          font-weight: 600;
        }
      }

      a {
        color: #0B62A6;
        font-weight: 600;
        cursor: pointer;

        &:hover{
          color: #0B62A6;
        }
      }
`;

const InformationHeader = styled.div`
    font-weight: 600;
    margin-bottom: 8px;
`;

const InformationToggle = styled.div`
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
    color: #777;

    &.open {
        color: black;
    }
`;

const ExchangeCheckboxWrapper = styled.div`
    background-color: white;
    height: 56px;
    border: 1px solid #ccc;
    margin-top: 16px;
    border-radius: 3px;
    border: 1px solid #CCC;
    background: #FFF;

    &.selected {
        border-radius: 3px;
        border: 2px solid var(--orange-1, #FF9505);
        background: rgba(255, 149, 5, 0.10);
    }
`;

const CheckboxWrapper = styled(Checkbox)`
    label {
        margin-top: 16px;
        margin-left: 15px;

        span {
            &:first-of-type {
                margin-left: 4px;
                color: #0B62A6;
                font-weight: 700;
            }
        }
    }
`;

export function ExchangeAcknolwedgementDialog({onCancel, onConfirm, parts}) {
    const [showExchangeDetails, setShowExchangeDetails] = useState(false);
    const [isExchangeTermsChecked, setIsExchangeTermsChecked] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    
    const options = parts || [];

    const onAcknowledge = () => {
        setIsSaving(true);
        onConfirm();
    }

    return (<PopupContainer
        show={true}
        onCancel={onCancel}
        onConfirm={onAcknowledge}
        cancelText="CANCEL"
        disableConfirm={!isExchangeTermsChecked}
        confirmText="PROCEED WITH EXCHANGE"
        loading={isSaving}>
        <h1 className="title">{options.length > 1 ? 'Exchange Item(s) for Purchase' : 'Exchange Item for Purchase'}</h1>
        {options.length > 0 && <OptionListContainer>
            {options.map(o => (
                <Option>
                <PartDescription>{o.title}</PartDescription>
                <PartDetail><span>Item #:</span>{o.displayPartNumber}</PartDetail>
                <PartDetail>
                    <span>Condition: </span>
                    <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '15px', padding: '0 2px'}} />
                    {getPartCondition(o.lineItemCondition)} - Exchange
                </PartDetail>
            </Option>
            ))}
        </OptionListContainer>}
        <ContentContainer>
            <ImageColumn >
                <img src="/images/exchange.svg" alt="Exchange Item" />
            </ImageColumn>
            <InformationColumn>
                <InformationHeader>Exchange Program Agreement</InformationHeader>
                <p>By checking &quot;Yes, I agree&quot; you are agreeing to the exchange program <a href="/policies/termsandconditionofsale" target="_blank">Terms and Conditions</a>.</p>
                {showExchangeDetails &&
                        <>
                            <p>Return of a non-repairable core will result in outright refurbished price listed, Non-repairablecore will be disposed not returned.</p>
                            <p>Goods sold on an &quot;Exchange&quot; basis require that Buyers return a like, repairable item withing 15 days (or the sellers specified time frame) in order to receive the price quoted.</p>
                            <p className="bold">Non-compliance will result in additional core charges.</p>
                        </>  
                }
                <InformationToggle className={classNames({open: showExchangeDetails})} onClick={() => setShowExchangeDetails(!showExchangeDetails)}>
                    {showExchangeDetails ? 'See less details' : 'See more details'}
                </InformationToggle>
                <ExchangeCheckboxWrapper className={classNames({selected: isExchangeTermsChecked})}>
                    <CheckboxWrapper label="Yes, I Agree" name="exchangeTOC" checked={isExchangeTermsChecked} onChange={() => {
                        setIsExchangeTermsChecked(!isExchangeTermsChecked);
                    } 
                    } />
                </ExchangeCheckboxWrapper>
            </InformationColumn>
        </ContentContainer>
        
    </PopupContainer>)
}
