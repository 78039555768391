import {BrManageMenuButton} from '@bloomreach/react-sdk';
import {BrComponent, BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import React, {useContext, useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {getFormattedPhoneNumber, logEvent} from 'utility';
import BottomMenu from 'components/cms/BottomMenu';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';

const FooterMenu = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const component = useContext(BrComponentContext);
    const page = useContext(BrPageContext);
    const menuRef = component.getModels().menu || null;
    const menu = menuRef ? page.getContent(menuRef) : null;
    
    const {user: {settings: {dedicatedPhoneNumber, showTeamContactCenterPage}}} = useSelector((state) => ({
        user: state.user,
    }));
    
    useEffect(() => {
        let footerElement = document.getElementsByClassName('global-footer')[0];
        let screenMdMax = 1149; // screen-md-max

        if (footerElement && ((typeof window !== 'undefined') && window.screen.width > screenMdMax)) {
            let newText;
            if (dedicatedPhoneNumber) {
                let formattedNumber = getFormattedPhoneNumber(dedicatedPhoneNumber);
                newText = '<a style="color: white; font-size: 14px; font-weight: 400;" href="tel:+1' + dedicatedPhoneNumber + '">' + formattedNumber + '</a>';
            } else {
                newText = '<a style="color: white; font-size: 14px; font-weight: 400;" href="tel:+1' + defaultPhoneNumber + '">' + defaultPhoneNumber + '</a>';
            }
            
            let indexOfNumber = footerElement.innerHTML.indexOf(defaultPhoneNumber) ;

            if (indexOfNumber !== -1) {
                footerElement.innerHTML = footerElement.innerHTML.replace(defaultPhoneNumber, newText);
            }
        }
    }, []);

    const handleTabSelected = (index) => {
        if (activeIndex !== null && index === activeIndex) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    }

    const sendLog = (event) => {
        logEvent('My Account', {
            'menu item': event.target.innerText,
        });
    }

    const renderItem = (item, index) => {
        const params = item.getParameters();
        const linkUrl = item.getLink() && item.getLink().href || '#';
        const linkText = item.getName() || '';
        const {newTab} = params;

        return ((linkUrl === '/teamcontactcenter' && !showTeamContactCenterPage) ? '' : <li key={`fi_${index}`} style={{paddingTop: '10px', paddingBottom: '10px', paddingLeft: '0px', paddingRight: '10px'}}>
            {newTab ? <a href={linkUrl} target={'_blank'} rel="noreferrer noopener">{linkText}</a> : <NavLink to={linkUrl} onClick={sendLog}>{linkText}</NavLink>}
        </li>)
    }

    const renderMobileMenu = () => {
        return (<div className="mobile-menu ps-hidden-md ps-hidden-lg ps-hidden-xl">
            {menu.getItems().map((i, index) => (<React.Fragment key={`ft_${index}`} >
                <div className="mobile-menu_header" onClick={() => handleTabSelected(index)}>
                    <div className={`mobile-menu_tab lbl-bold ${activeIndex === index && 'mobile-menu_tab--active'}`}>
                        {i.getName()}
                    </div>
                </div>

                {activeIndex === index && <div className="mobile-menu_content">
                    <ul>{i.getChildren().map(c => renderItem(c))}</ul>
                </div>}
            </React.Fragment>))}
        </div>);
    }

    return (<>
        {page.isPreview() && <div style={{position: 'relative'}}><BrManageMenuButton menu={menu} /></div>}
        <div className="ps-hidden-xs ps-hidden-sm">
            {menu.getItems().map((item, index) =>
                (<div key={`ft_${index}`} style={{width: `${item.getChildren().length > 5 ? '50%' : '25%'}`, display: 'inline-block', verticalAlign: 'top'}}>
                    <div style={{fontWeight: '600', paddingBottom: '10px'}}>{item.name}</div>
                    <ul style={{listStyle: 'none', padding: '0px', columns: `${item.getChildren().length > 5 ? '2' : '1'}`}}>
                        {item.getChildren().map((c, i) => renderItem(c, i))}
                    </ul>
                </div>))}
        </div>
        {renderMobileMenu()}
    </>);
}

const Footer = (props) => {
    const {isAdmin, ...rest} = props;

    return (
        <div {...rest}>
            {!isAdmin && <div className="mobile-advertisement full-row">
                <BrComponent path={'top-container'} />
            </div>}
            {!isAdmin && <div className="global-footer full-row" data-ea-zone="Footer~Full-Row">
                <div className="left-section ps-hidden-xs ps-hidden-sm" style={{display: 'inline-block'}}>
                    <BrComponent path={'container'} />
                </div>
                <div className="right-section">
                    <BrComponent path={'footer-menu'}>
                        <FooterMenu />
                    </BrComponent>
                </div>
                <div className="be-ix-link-block"></div>
            </div>}
            <BrComponent path={'bottom-menu'}>
                <BottomMenu />
            </BrComponent>
        </div>
    );
}

export default Footer;
