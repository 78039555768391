import React, {useEffect, useLayoutEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import useLogout from 'hooks/useLogout';
import useMenu from 'hooks/useMenu';
import GoHome from 'icons/GoHome.svg';
import Logout from 'icons/Logout.svg';
import User from 'icons/User.svg';
import ChevronLeft from 'icons/ChevronLeft.svg';
import ChevronRight from 'icons/ChevronRight.svg';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {logEvent} from 'utility';
import {theme} from '@partssourceinc/react-ui-core';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {matchPath} from 'react-router-dom';

const Container = styled.div`
    z-index: 999;
    overflow: visible;
    position: relative;
    height: auto;
    background-color: #003763;
    transition: width 0.4s ease-in-out;
    flex-grow: 0;
    flex-shrink: 0;
    
    ${props => !props.mobile && css`
        margin-top: -20px;
        margin-left: -50px;
        margin-right: 50px;
        width: ${props.isExpanded ? '210px' : '83px'};
        @media (max-width: ${theme.screenMDMax}) {
            display: none;
        }
    `}

    ${props => props.mobile && css`
        width: 100%;
    `}
    

    ${props => props.isExpanded && css`
        align-items: center;
    `}

    ${props => !props.isExpanded && css`
        span {
            display: none;
        }

        a, div {
            display: flex;
            justify-content: center;
        }

        div:first-child {
            display: flex;
            justify-content: center;
            padding: 15px 10px;
        }
    `}

    .active {
        background-color: #ffffff;
        color: #003763;

        &:hover, &:visited {
            color: #003763;
            text-decoration: none;
        }

        path, circle {
            stroke: #003763;
        }
    }
`;

const ToggleButton = styled.div`
    position: absolute;
    background-color: #003763;
    width: 30px;
    height: 30px;
    color: #ffffff;
    right: -12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    cursor: pointer;

    > svg {
        width: 5px;
        height: 9px;
    }
`;

const menuItem = css`
    background-color: #003763;
    height: 43px;
    width: 100%;
    display: flex;
    padding: 5px 8px;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
    order: ${props => props.order};

    &:hover {
        color: #ffffff;
        text-decoration: none;
    }

    &:hover, &:active, &:visited, &:focus {
        text-decoration: none;
    }

    &:visited {
        color: #ffffff;
    }

    > svg {
        width: 32px;
        height: 32px;
    }

    > span {
        padding-left: 9px;
    }
`;

const MenuLink = styled(NavLink)`
    ${menuItem}
`;

const MenuDiv = styled.div`
    ${menuItem}
`;

const Menu = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px 10px;
`;

const Footer = styled.div`
    border-top: 0.5px solid rgba(255, 255, 255, 0.5);
`;

const Header = styled.div`
    background-color: #004A84;
    color: #ffffff;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 18px;
    box-shadow: 0px 4px 4px 0px #00000040;

    svg {
        overflow: visible;
    }
`;

const UserName = styled.span`
    padding-left: 8px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
`;

const NavMenuVertical = ({authHandler, mobile = false, onNavigate}) => {
    const {logOut} = useLogout(authHandler);
    const {pathname, search} = useLocation();
    const {menuItems, showMenu} = useMenu();
    const [isExpanded, setIsExpanded] = useState(true);
    const {info: {firstName, lastName}} = useSelector(state => state.user);
    const {width} = useWindowDimensions();

    useEffect(() => {
        if (showMenu && window.localStorage) {
            localStorage.setItem('showVerticalNav', true);
        }

        return () => {
            localStorage.removeItem('showVerticalNav');
        }
    });

    useLayoutEffect(() => {
        if (showMenu) {
            const maxWidth = parseInt(theme.screenMDMax);
            
            if (width > maxWidth) {
                document.querySelector('#pageContent').style.transition = 'width 0.4s ease-in-out';
                document.querySelector('#pageContent').style.width = isExpanded ? 'calc(100% - 210px)' : 'calc(100% - 83px)';
                document.querySelector('main').style.display = 'flex';
                document.querySelector('main').style.justifyContent = 'center';
            } else {
                document.querySelector('#pageContent').style.transition = '';
                document.querySelector('#pageContent').style.width = '100%';
                document.querySelector('main').style.display = '';
                document.querySelector('main').style.justifyContent = '';
            }

        } else {
            document.querySelector('#pageContent').style.transition = '';
            document.querySelector('#pageContent').style.width = '';
            document.querySelector('main').style.display = '';
            document.querySelector('main').style.justifyContent = '';
        }
    }, [isExpanded, width, pathname]);

    const onMenuItemClick = (title) => {
        logEvent('Command Center', {'menu item': title, 'Source Descriptor': undefined});
        onNavigate?.();
    }
    
    const renderMenuItem = ({url, title, enabled, Icon, newTab = false, pathMatch = null, order}) => {
        const matches = pathMatch ? matchPath(pathname, {
            path: pathMatch,
            exact: true,
        }) : pathname.toLowerCase() === url;

        return (enabled && <MenuLink
            order={order}
            to={url} 
            title={title}
            className={`${matches ? 'active' : ''}`}
            onClick={() => onMenuItemClick(title)}
            activeClassName={`${matches ? 'active' : ''}`}
            {...(newTab ? {target: '_blank', rel: 'noreferrer noopener'} : {})}>
            <Icon />
            <span>{title}</span>
        </MenuLink>);
    }

    return (showMenu ? <Container isExpanded={isExpanded} show={true} hideOverlay={true} mobile={mobile}>
        <Header>
            <User style={{width: '26px', height: '26px'}} />
            <UserName>{`${firstName.toLowerCase()} ${lastName.charAt(0)}.`}</UserName>
            {!mobile && <ToggleButton onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <ChevronLeft /> : <ChevronRight />}
            </ToggleButton>}
        </Header>
        <Menu>
            {menuItems.map((i) => renderMenuItem(i))}
            <Footer style={{order: '16'}} />
            <MenuLink to={'/'} onClick={() => onMenuItemClick('Go Home')} order={17} activeClassName="" title="Go Home">
                <GoHome />
                <span>Go Home</span>
            </MenuLink>
            <MenuDiv onClick={() => logOut()} order={18} title="Logout">
                <Logout />
                <span>Logout</span>
            </MenuDiv>
        </Menu>
    </Container> : null);
}

export default NavMenuVertical;
