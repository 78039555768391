import * as React from 'react';
import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useLocation, useHistory, NavLink} from 'react-router-dom';
import _ from 'lodash';
import styled from 'styled-components';
import {SwitchCheckbox, Drawer} from '@partssourceinc/react-ui-core';
import Cookies from 'js-cookie';
import MobileMenu from 'components/MobileMenu';
import Bell from 'icons/Bell.svg';
import useLogout from 'hooks/useLogout';
import useCreditHold from 'hooks/useCreditHold';
import {getCookie, getFormattedPhoneNumber} from 'utility';
import Cart from 'icons/Cart.svg';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';

import {getSettings, saveUserReduxState, getLists, getCompanyFields, onCloseMobileMenu, openMobileMenu} from './../store/User';
import {loadModalities} from './../store/System';
import {getBrowsingHistory, getBrowsingHistoryFromCookieProductsData, setReduxStateFromCookie} from './../store/Cart';
import {toggleAutoRefresh, setShowUpdatesPanel} from './../store/Dashboard';
import {stopImpersonation} from './../store/Network';
import NavMenuVertical from 'components/NavMenuVertical';
import useRenderUserMenuOptions from './../hooks/useRenderUserMenuOptions';
import OnCreditHoldBanner from './OnCreditHoldBanner';
import OnCreditHoldModal from './OnCreditHoldModal';
import FacilityDialog from './FacilitySearchDialog';
import useMenu from 'hooks/useMenu';
import 'less/header.less';
import MyAccountTitle from './MyAccountTitle';

const MobileFacilityRow = styled.div`
    background: #003763;
    min-height: 40px !important;
    max-height: 40px !important;
    color: white !important;
    font-size: 12px;
`;

const MobileFacilityCart = styled.div`
    cursor: pointer;
`;

const CartLink = styled.div`
    display: inline-block;
    margin-left: 21px;
    position: relative;

    div {
        display: flex;
        width: 22px;
        height: 22px;
        padding: 1.5px 3px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 32px;
        font-weight: 400;
        fontSize: 10px;
        background-color: #005BA6;
        color: #ffffff;
        position: absolute;
        top: 3px;
        right: 0px;
    }

    svg {
        color: #005BA6;
        width: 42px;
        height: 42px
    }
`;

const Divider = styled.span`
    width: 1px;
    background-color: #CCCCCC;
    height: 31px;
    display: inline-block;
    vertical-align: middle;
    margin: 0px 21px;
`;

const MobileFacilityCartContainer = styled.div`
    padding-left: 0px !important;
    padding-right: 0px !important;
`;

const MobileFacility = styled.span`
    font-weight: 700;
    cursor: pointer;
    width: 100%;
    display: inline-block;
    height: 40px;
    font-size: clamp(8px, 12px, 16px);
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    top: 10px;
    text-overflow: ellipsis;
`;

const MobileFacilityIcon = styled.i`
    font-size: 16px !important;
    padding-right: 10px;
`;

const MobileFacilityName = styled.span`
`;

const AutoRefreshFilter = styled.span`
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 20px;
    min-width: 120px;
`;

const UpdatesPanelDisplayContainer = styled.span`
    float: right;
    margin-right: -15px !important;
    padding-right: 0 !important;
`;

const UpdatesPanelFilterButton = styled(Bell)`
    position: relative;
    top: 7px;
    font-size: 24px;
    cursor: pointer;
    color: white !important;
    width: 24px;
    height: 24px;
`;

export const CompactHeader = ({authHandler, onSettingsDataLoaded}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const {renderUserMenuOtions} = useRenderUserMenuOptions(authHandler);
    const {showMenu: showCCMenu} = useMenu();
    const {logOut} = useLogout(authHandler);
    const {isLoggedIn, sessionStorageAvailable} = useSelector(state => state.network);
    const {facility, facilities} = useSelector(state => state.user);
    const {storeDedicatedPhoneNumber, showTeamContactCenter} = useSelector(state => state.user.settings);
    const {contactId, fullName, rootCompanyId, rootCompanyName} = useSelector(state => state.user.info);
    const {isImpersonation, psFirstName, psLastName, isPunchout} = useSelector(state => state.network.tokenInfo);
    const {itemCount} = useSelector(state => state.cart);
    const {cartItems, itemHistory} = useSelector(state => state.cart);
    const autoRefresh = useSelector(state => state.dashboard.autoRefresh);

    const [showFacilityDialog, setShowFacilityDialog] = useState(false);
    const [facilityName, setFacilityName] = useState('');
    const [hasFacility, setHasFacility] = useState('');
    const {isCreditHold, showCreditHoldModal, creditHoldAlert} = useCreditHold();
    const [isCommandCenterPage, setIsCommandCenterPage] = useState(false);
    const [stoppingImpersonation, setStoppingImpersonation] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const dedicatedPhoneNumber = storeDedicatedPhoneNumber ? 
            getFormattedPhoneNumber(storeDedicatedPhoneNumber) : defaultPhoneNumber;

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(getSettings()).then(() => {
                onSettingsDataLoaded();
            });
            dispatch(getLists());
            dispatch(loadModalities());

            if (sessionStorageAvailable && !sessionStorage.fields && facilities.length > 0) {
                dispatch(getCompanyFields(facilities[0].facilityId));
            }

            if (location.pathname !== '/cart' && location.pathname.indexOf('/checkout') === -1) {
                if (isPunchout && cartItems.length > 0) {
                    history.push('/cart');
                }
            }

            if (!itemHistory || !itemHistory.views || itemHistory.views.length < 1) {
                dispatch(getBrowsingHistory());
            }

            setQualtricsData();
        } else {
            dispatch(getBrowsingHistoryFromCookieProductsData());

            if (sessionStorageAvailable && !sessionStorage.fields) {
                dispatch(getCompanyFields(38451));
            }
            onSettingsDataLoaded();
        }
    }, []);

    useEffect(() => {
        setQualtricsData();
    }, [isLoggedIn, rootCompanyId]);

    useEffect(() => {
        if (location.pathname.indexOf('/command-center') >= 0)
            setIsCommandCenterPage(true);
        else 
            setIsCommandCenterPage(false);

        if ((location.pathname.indexOf('/catalog') >= 0 || location.pathname.indexOf('/parts') >= 0)
            && (!itemHistory || !itemHistory.views || itemHistory.views.length === 0)) {
            callGetBrowsingHistory();
        }
    }, [location.pathname]);

    useEffect(() => {
        setFacilityName(facility?.facilityName || 'Your Facility');
        setHasFacility(facility?.facilityName && facility?.facilityName.length > 0);
    }, [facility]);

    const onSelectFacility = (facility) => {
        dispatch(saveUserReduxState({facility: facility}));
        setShowFacilityDialog(false);
    }

    const setQualtricsData = () => {
        if (isLoggedIn) {
            window.qualtricsData = {
                ...window.qualtricsData,
                userId: contactId,
                userName: fullName,
                parentId: rootCompanyId,
                parentName: rootCompanyName,
            };
        }
    }

    const callGetBrowsingHistory = () => {
        if (isLoggedIn) {
            dispatch(getBrowsingHistory());
        } else if (!itemHistory || !itemHistory.views || itemHistory.views.length === 0) {
            let browsingCookie = [];
            const browsingHistoryStr = getCookie('browsingHistory');

            if (browsingHistoryStr !== '') {
                try {
                    browsingCookie = JSON.parse(browsingHistoryStr);
                } catch (err) {
                    browsingCookie = [];
                }
            }

            if (browsingCookie.length > 0) {
                browsingCookie = browsingCookie.sort(function (x, y) {
                    return new Date(y.date) - new Date(x.date);
                });

                dispatch(setReduxStateFromCookie(browsingCookie));
                dispatch(getBrowsingHistoryFromCookieProductsData());
            }
        }
    }

    const halndleToggleAutoRefresh = () => {
        dispatch(toggleAutoRefresh());
    }

    const openUpdatesPanelMenu = () => {
        dispatch(setShowUpdatesPanel(true));
    }

    const onStopImpersonation = () => {
        setStoppingImpersonation(true);
        Cookies.remove('impersonate');
        dispatch(stopImpersonation()).then(() => {
            history.push('/admin');
        });
    }

    const onOpenMobileMenu = () => {
        dispatch(openMobileMenu());
        setMenuOpen(true);
    }

    return (<>
        <div style={{marginBottom: '20px'}}>
            <div className="ps-hidden-sm ps-hidden-xs ps-hidden-md">
                <div className="row header top-row" data-ea-zone="Header~MainMenu">
                    <div className="col-md-12">
                        {isImpersonation && <span className="authentication-message">
                            Logged in as {_.startCase((psFirstName || '').toLowerCase())}{' '}
                            {_.startCase((psLastName || '').toLowerCase())} -
                            <span className="stop-auth" onClick={onStopImpersonation}>
                                {!stoppingImpersonation ? <span>Stop Impersonating</span> :
                                    <i className="fa fa-circle-o-notch fa-spin fa-fw" />}
                            </span>
                        </span>}
                        <span className="top-header-row">
                            <i className="fa fa-phone" />
                            <span style={{marginLeft: '5px'}}><a href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a></span>
                            {showTeamContactCenter && <NavLink className="top-header-row-link" to="/teamcontactcenter">Team Contact Center</NavLink>}
                            <a data-ea-exit-link="Customer Training" className="top-header-row-link" target="_blank" rel="noreferrer noopener"
                                href="https://go.partssource.com/customer-training.html">Help</a>
                            <a data-ea-exit-link="Corporate Site" className="top-header-row-link" target="_blank" rel="noreferrer noopener"
                                href="https://corporate.partssource.com">About Us</a>
                        </span>
                    </div>
                </div>
                <div className="row header menu-row" data-ea-zone="Header~MainMenu">
                    <div className="col-md-12">
                        <NavLink to="/">
                            <img
                                src={`${process.env.REACT_APP_PUBLIC_URL}/images/partssourcelogo300.png`}
                                alt="PartsSource"
                                style={{
                                    height: '16px',
                                    width: '200px',
                                }}
                            />
                        </NavLink>
                        {
                            isLoggedIn && 
                                <div className="right-column" style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="non-chevron-link pad" style={{marginRight: '0px', marginLeft: '10px'}}>
                                        <NavLink to="/">
                                            <span>Marketplace</span>
                                        </NavLink>
                                    </div>
                                    <CartLink>
                                        <NavLink
                                            to="/cart"
                                            style={{textDecoration: 'none'}}>
                                            <Cart />

                                            {itemCount > 0 && <div>
                                                {itemCount > 99 ? '99+' : itemCount}
                                            </div>}
                                        </NavLink>
                                    </CartLink>
                                    <Divider />
                                    <span
                                        className="facility-cart"
                                        title={facilityName}>
                                        <span className="facility" style={{paddingRight: '20px'}} onClick={() => setShowFacilityDialog(true)}>
                                            <i className="fa fa-map-marker" />
                                            <span className={hasFacility ? 'facility-label' : 'facility-label empty'}>
                                                Selected Facility
                                            </span>
                                            <span className={hasFacility ? 'facility-name' : 'facility-name empty'}>
                                                {facilityName}
                                            </span>
                                        </span>
                                    </span>
                                    { renderUserMenuOtions()}
                                </div>
                        }
                    </div>
                </div>
                {isCreditHold && <OnCreditHoldBanner alertTypeCode={creditHoldAlert} />}
                <div className='row header my-account-title'>
                    <MyAccountTitle />
                </div>
            </div >
            <div className="ps-hidden-lg ps-hidden-xl">
                <div className="row mobile-header" data-ea-zone="Header~MobileMainMenu" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                    <div className="col-md-12 mobile-logo-row">
                        <i className="fa fa-bars" onClick={() => onOpenMobileMenu()} />
                        <span className="badge" onClick={() => onOpenMobileMenu()} />
                        <NavLink to="/">
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/partssourcelogo300.png`}
                                className="mobile-logo" alt="logo" />
                        </NavLink>
                        <NavLink to="/cart" className="mobile-cart">
                            <span className="cart">
                                <i className="fa fa-shopping-cart" aria-hidden="true" />
                                <span>Cart</span>

                                {itemCount > 0 ? (
                                    <span className="item-count">
                                        ({itemCount > 99 ? '99+' : itemCount})
                                    </span>
                                ) : null}
                            </span>
                        </NavLink>
                    </div>
                    {isLoggedIn ? (
                        <MobileFacilityRow className="col-sm-12">
                            <div className="row">
                                <MobileFacilityCartContainer className='col-7'>
                                    <MobileFacilityCart>
                                        <MobileFacility
                                            onClick={() => setShowFacilityDialog(true)}
                                        >
                                            <MobileFacilityIcon className="fa fa-map-marker" />
                                            <MobileFacilityName>{facilityName}</MobileFacilityName>
                                        </MobileFacility>
                                    </MobileFacilityCart>
                                </MobileFacilityCartContainer>
                                {isCommandCenterPage && <div className='col-5 right-column'>
                                    <UpdatesPanelDisplayContainer>
                                        <div>
                                            <UpdatesPanelFilterButton onClick={openUpdatesPanelMenu} />
                                        </div>
                                    </UpdatesPanelDisplayContainer>
                                    <AutoRefreshFilter>
                                        <SwitchCheckbox
                                            label="Auto Refresh"
                                            value={autoRefresh}
                                            onChange={halndleToggleAutoRefresh}
                                            className="auto-refresh-toggle"
                                        />
                                    </AutoRefreshFilter>
                                </div>}
                            </div>
                        </MobileFacilityRow>
                    ) : null}
                </div>
                {showCCMenu ? <Drawer style={{overflowY: 'auto', paddingLeft: '0px', paddingRight: '0px'}} show={menuOpen} onClose={() => setMenuOpen(false)}>
                    <NavMenuVertical authHandler={authHandler} mobile={true} onNavigate={() => setMenuOpen(false)} />
                </Drawer> : <MobileMenu onCloseMobileMenu={() => dispatch(onCloseMobileMenu())} onLogout={() => logOut()} />}
                {isCreditHold && <OnCreditHoldBanner alertTypeCode={creditHoldAlert} />}
            </div >
            {
                showFacilityDialog && (
                    <FacilityDialog onSubmit={(facility) => onSelectFacility(facility)} onCancel={() => setShowFacilityDialog(false)} />
                )
            }
        </div >
        {showCreditHoldModal && <OnCreditHoldModal />}
    </>);
};
