import * as React from 'react';
import * as PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

const Link = ({href, children, onClick, className}) => {
    const [,, openInNewTab] = /([^?]*)(\?newtab)?$/.exec(href);
    const isExternal = /^(https|http)?:\/\//.test(href);
    let sanitizedHref = href.indexOf('?newtab') > -1 ? href.replace('?newtab', '') : href;

    return isExternal
        ? (
            <a
                href={sanitizedHref}
                target={openInNewTab ? '_blank' : undefined}
                rel={openInNewTab ? 'noopener noreferrer' : undefined}
                onClick={onClick}
                className={className}
            >
                {children}
            </a>
        )
        : (
            <NavLink
                to={sanitizedHref}
                target={openInNewTab ? '_blank' : undefined}
                rel={openInNewTab ? 'noopener noreferrer' : undefined}
                onClick={onClick}
                className={className}
                activeClassName="active"
            >
                {children}
            </NavLink>
        );
};

Link.propTypes = {
    href: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Link;
