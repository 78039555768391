import React, {useContext} from 'react';
import {BrPageContext, BrComponentContext, BrManageContentButton} from '@bloomreach/react-sdk';
import {useHistory, useLocation} from 'react-router';
import styled, {css} from 'styled-components';
import {semiBoldBrandFont, theme} from '@partssourceinc/react-ui-core';

const TopMenu = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${theme.screenXsMax}) {
        flex-direction: column;
    }
`;

const Logo = styled.div`
    height: 100%;

    img {
        height: 100%;
        width: 150px;
    }
`;

const Item = styled.div`
    height: 100%;
    display: inline-block;
    padding: 20px 10px 20px 0px;

    a {
        ${semiBoldBrandFont('16px')};
        text-decoration: none;
        color: #000;
        font-size: 16px;
        line-height: 22px;
        &:hover, &:active, &:visited, &:focus {
            text-decoration: none;
        }
    }

    &:last-child {
        padding-right: 0px;
    }

    ${props => props.$activeSection && css`
        a {
            color: ${theme.grey3};
        }
    `}
`;

const Menu = styled.div`
    text-align: right;
    height: 60px;

    @media (max-width: ${theme.screenXsMax}) {
        overflow-x: scroll;
        height: 100%;
        display: flex;

        &::-webkit-scrollbar {
            display: none;
        }
        &::-moz-scrollbar {
            display: none;
        }
    }
`;

const OemShowcaseMenu = () => {
    const page = useContext(BrPageContext);
    const component = useContext(BrComponentContext);
    const location = useLocation();
    const history = useHistory();

    const models = component.getModels() || {};
    const document = models.document || null;
    const content = models && models.document ? page.getContent(component.getModels().document).getData() : null;
    const imageUrl = content && content.image ? page.getContent(content.image).getOriginal().getUrl() : null;
    const links = content && content.links ? content.links.map(l => l) : [];

    const activeSection = page.isPreview() ? (localStorage.getItem('activeSection') || '0')
        : location && location.state && location.state.activeSection || '0';

    const onClick = (index) => {
        if (page.isPreview()) {
            localStorage.setItem('activeSection', index);
            window.location.reload();
        } else {
            history.push({
                pathname: location.pathname,
                state: {activeSection: index},
            });
        }
    }

    if (!content) return null;

    const renderMenuItem = (item, index) => {
        return (
            <Item $activeSection={index.toString() === activeSection}>
                <a onClick={() => onClick(index.toString())} href="javascript:void(0);">{item.text}</a>
            </Item>
        );
    }

    return (<>
        {document && <div style={{position: 'relative'}}><BrManageContentButton content={page.getContent(document)} /></div>}
        <TopMenu className="full-row">
            {links || imageUrl ? <React.Fragment>
                {imageUrl && <Logo>
                    <img src={imageUrl} />
                </Logo>}
                <Menu>
                    {links && links.length > 0 && links.map((i, index) => renderMenuItem(i, index))}
                </Menu>
            </React.Fragment>
                : null}
        </TopMenu>
    </>);
};

export default OemShowcaseMenu;
