import {BrManageMenuButton, BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import _ from 'lodash';
import React, {useState, useContext, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as CompanyStore from 'stores/Companies';
import * as KevelStore from 'stores/Kevel';
import {classNames, getCmsImageUrlByPath} from 'utility';
import useInView from 'react-cool-inview';
import Link from 'components/Link';
import 'less/cms/flyoutmenu.less';

const FlyoutMenu = (props) => {
    const component = useContext(BrComponentContext);
    const page = useContext(BrPageContext);

    const menuRef = component.getModels().menu;
    const menu = menuRef ? page.getContent(menuRef) : null;
    const items = menu.getItems();

    const menuTitleItem = items.find(x => x.model.name === 'menu-title') ? items.find(x => x.model.name === 'menu-title').getParameters() : {};
    const siteMenuItems = items.filter(x => x.model.name !== 'menu-title') || [];
    const menuTitle = (menuTitleItem && menuTitleItem.header) || '';
    const [highlightedMenuItems, menuItems] = _.partition(siteMenuItems, 'model.parameters.highlighted');

    const dispatch = useDispatch();
    const companies = useSelector(state => state.companies);
    const {user: {settings: {showTeamContactCenterPage}}} = useSelector((state) => ({
        user: state.user,
    }));
    const {getDecisions, fireClickImpression} = KevelStore.actionCreators;
    const [zoneAds, setZoneAds] = useState([]);
    const [impressedZoneIds, setImpressedZoneIds] = useState([]);

    const menuId = `lnk${menuTitle}`;
    const columnCount = menuTitle === 'Solutions' ? 1 : 2;

    const [subMenu, setSubMenu] = useState(null);
    const [hoverKey, setHoverKey] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [cachedCategories, setCachedCategories] = useState({});

    const {observe} = useInView({
        threshold: 0.65,
        onEnter: ({unobserve, entry}) => {
            if (entry.target.id === '') {
                unobserve();
                return;
            }
            // See if it already triggered impression
            const alreadyImpressed = impressedZoneIds.find(i => `div${i}` === entry.target.id);
            if (alreadyImpressed) {
                unobserve();
            } else {
                let zoneId = entry.target.id.substr(3);
                let impressed = [...impressedZoneIds];
                impressed.push(zoneId);
                setImpressedZoneIds(impressed);
                const zoneAd = zoneAds.find(z => z.zoneId === zoneId);
                if (zoneAd)
                    dispatch(fireClickImpression(zoneAd.impressionUrl));
            }
        },
    });

    useEffect(() => {
        async function getAds(zoneIds, adTypeIds) {
            const ads = await dispatch(getDecisions(zoneIds, adTypeIds, []));
            setZoneAds(ads);
        }

        let zoneIds = [];
        let adTypeIds = [];
        siteMenuItems.map(item => {
            const childMenuItems = item.children || [];
            const [rightMenuItems] = _.partition(childMenuItems, (i) => {
                return i.model.name === 'menu-image';
            });

            const [rightMenuItem] = rightMenuItems;
            const params = rightMenuItem ? rightMenuItem.getParameters() : {};
            const {image = ''} = params;

            if (image) {
                const imageUrl = getCmsImageUrlByPath(image);
                const url = new URL(imageUrl);
                const zoneId = url.searchParams.get('k-zone-id');
                let adTypeId = parseInt(url.searchParams.get('k-ad-type-id'));

                if (zoneId && adTypeId && !isNaN(adTypeId)) {
                    zoneIds.push(zoneId);
                    adTypeIds.push(adTypeId);
                }
            }
        });

        // Remove duplicates, if any
        zoneIds = [...new Set(zoneIds)];
        adTypeIds = [...new Set(adTypeIds)];

        // Get Ads
        if (zoneIds.length && adTypeIds.length) {
            getAds(zoneIds, adTypeIds);
        }
    }, []);

    useEffect(() => {
        const [, hoveredItemIsHighlighted, hoveredIndex] = /(h)?mi_(\d+)/.exec(hoverKey) || [];
        const sm = hoveredIndex && (hoveredItemIsHighlighted
            ? highlightedMenuItems[hoveredIndex]
            : menuItems[hoveredIndex]);

        setSubMenu(sm);
    }, [hoverKey])

    useEffect(() => {
        setCachedCategories(_.clone(companies.categories));
    }, [companies.categories]);

    // We introduce an artificial delay to account for user accidents
    // https://partssource.atlassian.net/browse/AP-584
    const showMenu = () => setTimeout(() => {
        if (_.find(document.querySelectorAll(':hover'), {id: menuId})) {
            setMenuVisible(true);
        }
    }, 200);

    const hideMenu = ({type: eventType}) => setTimeout(() => {
        if (eventType === 'click' || !_.find(document.querySelectorAll(':hover'), {id: menuId})) {
            setHoverKey('');
            setMenuVisible(false);
        }
    }, 200);

    const desluggify = (menuItem) => {
        const linkUrl = menuItem.getLink() && menuItem.getLink().href || '#';

        const {categories: passedCategories} = companies;
        const categories = passedCategories.map(c => c.sluggedPath);

        if (cachedCategories[linkUrl]) return cachedCategories[linkUrl];
        if (linkUrl.indexOf('/shop') === -1) return linkUrl;

        const removeTrailingSlash = s => s.replace(/(.*)\/$/, '$1');
        const category = removeTrailingSlash(linkUrl.substr('/shop/'.length));

        let potentialCategory = categories.find(c => c.replace(/\//g, '-') === category);
        if (!potentialCategory) {
            // In this case, the linked category is a parent category which contains other categories,
            // so we'll take one child category and chop off the end
            potentialCategory = categories.find(c => c.replace(/\//g, '-').indexOf(category) > -1);
            if (!potentialCategory) {
                // There is no category for the category. Usually when this happens, raven ran out of memory
                return linkUrl;
            }
            potentialCategory = potentialCategory.substr(0, category.length + 1);
        }
        const href = '/shop/' + potentialCategory;

        setCachedCategories(_.merge(cachedCategories, {[linkUrl]: href}));
        return href;
    };

    const renderMenuItem = (item, i) => {
        const params = item.getParameters();
        const linkUrl = item.getLink() && item.getLink().href || '#';
        const categoryName = item.getName() || '';
        const {highlighted} = params;

        const key = highlighted ? `hmi_${i}` : `mi_${i}`;
        const itemClasses = {
            'left-menu-item': true,
            'left-menu-item--highlighted': highlighted,
            'left-menu-item--hover': key === hoverKey,
        };
        const labelClasses = {
            'lbl': true,
            'no-flyout': (item.children || []).length === 0,
        };

        return (
            (linkUrl === '/teamcontactcenter' && !showTeamContactCenterPage) ? '' : <div key={key} className={classNames(itemClasses)} onMouseEnter={setMouseHoverKey(key)}>
                <Link href={linkUrl} className={classNames(labelClasses)} onClick={hideMenu}>
                    {categoryName}
                </Link>
            </div>
        );
    };

    const renderRightMenu = item => {
        const categoryName = item.getName() || '';
        const childMenuItems = item.children || [];

        const [rightMenuItems, subMenuItems] = _.partition(childMenuItems, (i) => {
            return i.model.name === 'menu-image';
        });
        const [rightMenuItem] = rightMenuItems;
        const params = rightMenuItem ? rightMenuItem.getParameters() : {};
        const {image = ''} = params;

        const imageLinkUrl = rightMenuItem && rightMenuItem.getLink() ? rightMenuItem.getLink().href : '#';
        const menuClasses = classNames({
            'slide-out-menu': true,
            'no-image': !image,
            'single-column': columnCount === 1,
            'expanded': subMenuItems.length > 0,
        });

        let dropDownMenu = {clientHeight: 0};

        if (typeof document !== 'undefined') {
            dropDownMenu = document.querySelector(`#${menuId} .dropdown-menu`) || {clientHeight: 0};
        }

        const menuStyles = {minHeight: (dropDownMenu.clientHeight + 2) + 'px'};
        const boxShadowCoverStyles = {minHeight: (dropDownMenu.clientHeight + 1) + 'px'};

        let imageUrl = '';
        let hasAd = false;
        let zoneAd = null;

        if (image) {
            imageUrl = getCmsImageUrlByPath(image);
            const url = new URL(imageUrl);
            const zoneId = url.searchParams.get('k-zone-id');
            let adTypeId = parseInt(url.searchParams.get('k-ad-type-id'));

            if (zoneId && adTypeId && !isNaN(adTypeId)) {
                // Get ad from state
                zoneAd = zoneAds ? zoneAds.find(ad => ad.zoneId === zoneId) : null;
                if (zoneAd)
                    hasAd = true;
            }
        }

        return (
            <React.Fragment>
                {childMenuItems.length > 0 && <div className="box-shadow-cover" style={boxShadowCoverStyles} />}
                <div className={menuClasses} style={menuStyles}>
                    <div>
                        <span className="menu-title">{categoryName}</span>
                        <ul className="right-menu">
                            {subMenuItems.map((i, index) => renderSubMenuItem(i, index))}
                        </ul>
                    </div>
                    {hasAd ? 
                        zoneAd && <Link href={`${zoneAd.clickUrl}`} onClick={() => dispatch(fireClickImpression(zoneAd.clickUrl))}>
                            <div id={`div${zoneAd.zoneId}`} ref={observe} className="menu-image" dangerouslySetInnerHTML={{__html: zoneAd.content}} />
                        </Link> :
                        image && <div className="menu-image">
                            <Link href={imageLinkUrl} onClick={hideMenu}>
                                <img src={imageUrl} alt={`Featured ${categoryName} product`} />
                            </Link>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    };

    const renderSubMenuItem = (item, i) => {
        const linkUrl = desluggify(item) || '#';
        const linkText = item.getName() || '';
        const params = item.getParameters();
        const {header} = params;

        return (<li className="right-menu-item" key={`smi_${i}`}>
            {header && <div className="right-menu-header">{header}</div>}
            <Link href={linkUrl} onClick={hideMenu}>
                {linkText}
            </Link>
        </li>);
    };

    const setMouseHoverKey = hk => () => setHoverKey(hk);

    return (
        <ul className="navbar-nav mr-auto">
            <div style={{position: 'relative', top: '-2rem'}}>
                {page.isPreview() && <BrManageMenuButton menu={menu} />}
            </div>
            {menuTitle && menuTitle !== '' && <div
                id={menuId}
                className="chevron-link pad"
                onMouseEnter={showMenu}
                onMouseLeave={hideMenu}
            >
                {menuTitle}
                {menuItems.length > 0 && menuVisible ?
                    <div className="product-dropdown-menu dropdown-menu">
                        <div className="left-menu">
                            {menuItems.map((item, i) => renderMenuItem(item, i))}
                            {highlightedMenuItems.length > 0 ?
                                <div className="left-menu--highlighted">
                                    {highlightedMenuItems.map((item, i) => renderMenuItem(item, i))}
                                </div> :
                                null}
                        </div>
                    </div> :
                    null}
                {menuVisible && subMenu ?
                    renderRightMenu(subMenu) :
                    null}
            </div>}
        </ul>
    );
};

FlyoutMenu.propTypes = {
    ...CompanyStore.ActionShape,
    ...KevelStore.ActionShape,
};

export default FlyoutMenu;
