import React from 'react';
import styled from 'styled-components';
import {theme} from '@partssourceinc/react-ui-core';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import useOneSourceOemMappings from 'hooks/useOneSourceOemMappings';
import {logEvent} from 'utility';

const Container = styled.div`
    padding: 30px 0;
    border-top: 2px solid ${theme.grey4};
    border-bottom: 2px solid ${theme.grey4};
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const Img = styled.img`
    height: 36px;
    width: auto;
    display: inline-block;
    margin-right: 18px;
    margin-bottom: 5px;
`;

const A = styled(Link)`
    color: black;
    text-decoration: underline;

    &:hover {
        color: black;
    }
`;

export default function OneSourceDocumentSearch({component}) {
    const params = component.getParameters() || {};
    const psOemId = params.oemId;
    const hasOneSourceAccess = useSelector(state => state.user.settings.oneSource);

    const [oneSourceOems] = useOneSourceOemMappings();
    const oneSourceMapping = oneSourceOems
        .filter(o => o.partsSourceRefs.length > 0)
        .find(d => _.some(d.partsSourceRefs, ['id', psOemId]));

    function logOneSourceClick() {
        logEvent('oneSource Click', {
            'Source': 'OEM Page',
            'Source Descriptor': window.location.href,
        });
    }

    if (!psOemId) return null;
    if (!hasOneSourceAccess || !oneSourceMapping) return null;
    return (
        <Container>
            <Img alt="One Source" src="/images/logo_one-source.svg" />
            <A to={`/onesource-document-search?oemId=${psOemId}`} onClick={logOneSourceClick} target="_blank">
                Access your oneSOURCE Library
            </A>
        </Container>
    );
}
