import axios from 'axios';
import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {ProductImage} from '@partssourceinc/react-ui-core';
import {GridPagination} from '@partssourceinc/react-ui-core';
import {BrManageContentButton} from '@bloomreach/react-sdk';

import 'less/carousel/carousel.less';
import 'less/carousel/slick-theme.less';
import 'less/lists.less';

@connect((state) => ({network: state.network, user: state.user}))
export class ProductGrid extends React.Component {

    constructor(props) {
        super(props);

        const {network: {isLoggedIn}, user: {facility}, user} = props;
        const facilityId = !isLoggedIn ? 38451 : facility.facilityId > 0 ? facility.facilityId : user.facilities[0].facilityId;

        this.state = {
            products: [],
            configuration: null,
            loading: false,
            pageSize: 15,
            activePage: 1,
            facilityId: facilityId,
        };
    }

    componentDidMount() {
        this.refreshState(this.props);
    }

    componentWillReceiveProps(newProps) {
        const {facilityId} = this.state;

        if (newProps.user.facility.facilityId !== facilityId && newProps.user.settings.hasFacilityLevelPricing) {
            this.refreshState(newProps);
        }
    }

    refreshState(props) {
        const {component, network: {isLoggedIn}, user: {facility}, user} = props;
        const params = component.getParameters() || {};
        const facilityId = !isLoggedIn ? 38451 : facility.facilityId > 0 ? facility.facilityId : user.facilities[0].facilityId;

        const {carouselType, query, maxItems} = params;
        const request = {
            start: 0,
            limit: maxItems || 50,
            query: query,
            facilityId: facilityId,
        };

        if (!carouselType) return;

        this.setState({loading: true});
        switch (carouselType) {
            case 'buy-again':
                axios.get('CatalogService/api/v1/buy-again', request).then(response => this.setState({products: response.data, loading: false}));
                break;
            case 'most-popular':
                axios.post('CatalogService/api/v1/popular', request).then(response => this.setState({products: response.data.products, loading: false}));
                break;
            case 'left-off':
                axios.get('CatalogService/api/v1/recent', request).then(response => this.setState({products: response.data.views.map(v => v.product), loading: false}));
                break;
            case 'recommended':
                axios.get('CatalogService/api/v1/personal', request).then(response => this.setState({products: response.data.products, loading: false}));
                break;
            default:
                console.log('Unsupported carouselType: ' + carouselType);
                break;
        }
    }

    renderItem(item, index) {
        return (<div key={`cp_${index}`} className="list-item" style={{height: 'unset', paddingBottom: '30px'}}>
            <div className="pList-image">
                {item.imageUrl ? <ProductImage url={item.imageUrl} style={{width: '150px'}} /> : <ProductImage url={item.thumbnailUrl} style={{width: '150px'}} />}
            </div>
            {item.title && <NavLink className="pList-title" to={item.detailUrl || '#'} key={index}>{item.title}</NavLink>}
            {item.brand && <div><span className="lbl-subtle">{item.brand}</span></div>}
        </div>)
    }

    onPageSizeChange(pageSize) {
        this.setState({pageSize: pageSize.value, activePage: 1});
    }

    onPageChange(newPage) {
        const {pageSize} = this.state;
        this.setState({activePage: newPage.value});
    }

    render() {
        const {component, page} = this.props;
        let {products, loading, pageSize, activePage} = this.state;
        const params = component.getParameters() || {};
        const {title} = params;

        const models = component.getModels() && component.getModels.parts && page.getContent(models.parts).getData() || {};
        let parts = {...models};

        if (parts && parts.parts) {
            (parts.parts || []).forEach(part => part.imageUrl = page.getContent(part.thumbnailUrl).getOriginal().getUrl());
            products = parts.parts;
        }

        if (!products) {
            return null;
        }

        let pagedProducts = products.slice((activePage - 1) * pageSize, activePage * pageSize);

        return (<React.Fragment>
            <div className="list-page" style={{paddingBottom: '0px', minHeight: '200px'}}>
                <div style={{position: 'relative'}}><BrManageContentButton content={page.getContent(models.parts)} /></div>
                {loading && <div className="loading-data">
                    <div className="loader" />
                </div>}
                {!loading && <h1>{title}</h1>}
                {!loading && (pagedProducts || []).map((item, index) => this.renderItem(item, index))}
                {(!loading && pagedProducts) &&
                    <GridPagination
                        displayPages={10}
                        totalRecords={products.length}
                        pageSize={pageSize}
                        onPageSizeChange={::this.onPageSizeChange}
                        onSelect={::this.onPageChange}
                        currentPage={activePage}
                        pageSizeOptions={[{value: 15}, {value: 30}, {value: 50}]}
                    />}
            </div>
        </React.Fragment>);
    }
}
