import React, {useContext, useState} from 'react';
import CmsResource from 'components/cms/CmsResource';
import Footer from 'components/Footer';
import {Header} from 'components/Header';
import {CompactHeader} from 'components/CompactHeader';
import NavMenuVertical from 'components/NavMenuVertical';
import {SecureFooter} from 'components/SecureFooter';
import {SecureHeader} from 'components/SecureHeader';
import {ErrorBoundary} from 'pages/ErrorBoundary';
import {classNames} from 'utility';
import {BrComponent, BrPageContext} from '@bloomreach/react-sdk';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {PageNotFound} from 'pages/cms/PageNotFound';
import {useOktaAuth} from '@okta/okta-react';
import {AuthHandlerContext} from 'contexts/AuthHanderContext';
import {isEmpty, isNil} from 'lodash';
import useMenu from 'hooks/useMenu';

const Page = (props) => {
    let {children: page} = props;

    const pageModel = useContext(BrPageContext);
    const authHandler = useContext(AuthHandlerContext);
    const {isPublic = false, forceWideScreen = false, showSecureHeaderFooter = false} = props;
    const {pathname} = useLocation();
    const {authState} = useOktaAuth();
    const {menuItems, showMenu} = useMenu();
    const isLoggedIn = useSelector(state => state.network.isLoggedIn);
    const useOktaAuthentication = useSelector(state => state.network.useOktaAuthentication);
    const admin = useSelector(state => state.network.admin);
    const isPreview = useSelector(state => state.bloomReach.isPreview);
    const sessionStorageAvailable = useSelector(state => state.network.sessionStorageAvailable);
    const mainClasses = classNames({
        'col-md-12 body-content body-content--wide-screen': forceWideScreen,
        'secure-footer-padding': showSecureHeaderFooter,
        'col-md-12 body-content': !forceWideScreen,
    });
    const [showFooter, setShowFooter] = useState(false);

    if(authState != null && authState != undefined) {
        if (((authState.isAuthenticated === false && useOktaAuthentication) || (!useOktaAuthentication && !isLoggedIn)) && !isPublic && !isPreview) {
            if (sessionStorageAvailable) {
                sessionStorage.prevUrl = pathname;
            }
           
            if(isNil(admin) || isEmpty(admin) || admin?.isLoggedIn === false){
                location.href = '/login';
            }

            return null;
        }
    }

    let contentUrl = new URL(pageModel.getUrl());
    page = pathname !== '/login' && contentUrl.pathname !== pathname && !pageModel.isPreview() ? null : page;

    const pageNotFound = props.fallbackPath && pageModel.root.model.name === 'pagenotfound';
    
    const onSettingsDataLoaded = () => {
        setShowFooter(true);
    };
      
    return (
        <div>
            <ErrorBoundary>
                <BrComponent path="resources">
                    <CmsResource />
                </BrComponent>
                {showSecureHeaderFooter ? <SecureHeader /> : (showMenu ? <CompactHeader authHandler={authHandler} onSettingsDataLoaded={onSettingsDataLoaded} /> : <Header authHandler={authHandler} onSettingsDataLoaded={onSettingsDataLoaded} />)}
                {forceWideScreen
                    ? <>
                        <div className="row" style={{display: 'flex', justifyContent: 'center'}}>
                            <main role="main" className={mainClasses} data-ea-zone="Body~Main">
                                <div>{pageNotFound ? <PageNotFound /> : page}</div>
                            </main>
                        </div>
                        {showSecureHeaderFooter && <SecureFooter />}
                        {!showSecureHeaderFooter && showFooter && <BrComponent path={'footer'}>
                            <Footer className={`col-md-12`} style={{clear: 'both'}}/>
                        </BrComponent>}
                    </>
                    : <main role="main" className={mainClasses} data-ea-zone="Body~Main">
                        <NavMenuVertical authHandler={authHandler} />
                        <div id="pageContent">
                            <div style={{minHeight: '100vh'}}>{pageNotFound ? <PageNotFound /> : page}</div>
                            {showSecureHeaderFooter && <SecureFooter />}
                            {!showSecureHeaderFooter && showFooter && <BrComponent path={'footer'}>
                                <Footer />
                            </BrComponent>}
                        </div>
                    </main>}
            </ErrorBoundary>
        </div>
    );
}

export default Page;
