import * as PropTypes from 'prop-types';
import * as React from 'react';
import Error from 'pages/Error';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {connect} from 'react-redux';
import * as NetworkStore from 'stores/Network';

@connect((state) => ({network: state.network}), NetworkStore.actionCreators)
export class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.node,
    };

    componentDidMount() {
        if (typeof window !== 'undefined' && process.env.REACT_APP_INSIGHTS_KEY) {
            const reactPlugin = new ReactPlugin();
            window.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
                    enableAutoRouteTracking: true,
                    enableCorsCorrelation: true,
                    correlationHeaderDomains: ['partsfinder.com', 'partssource.com'],
                    correlationHeaderExcludedDomains: ['dev-sisense.partssource.com','partssource.sisense.com','api.hubspot.com'],
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    extensions: [reactPlugin],
                },
            });
            window.appInsights.loadAppInsights();
            const telemetryInitializer = (envelope) => {
                envelope.tags['ai.cloud.role'] = 'Apollo Browser';
            }
            window.appInsights.addTelemetryInitializer(telemetryInitializer);        
            window.appInsights.trackPageView();
        }
    }

    componentDidCatch(exception, info) {
        const {setHasError} = this.props;
        setHasError(true);
        try {
            window.appInsights.trackException({error: exception});
        } catch (e) {
            console.log('Failed to track exception in app insights.');            
        }
    }

    render() {
        const {children, network: {hasError}} = this.props;

        return hasError ? <Error /> : children;
    }
}
