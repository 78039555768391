import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MobileFlyoutMenu from 'components/cms/MobileFlyoutMenu';
import OnCreditHoldModal from 'components/OnCreditHoldModal';
import * as CartStore from 'stores/Cart';
import * as NetworkStore from 'stores/Network';
import * as UserStore from 'stores/User';
import * as DashboardStore from 'stores/Dashboard';
import * as Socket from 'stores/socket';
import {Drawer} from '@partssourceinc/react-ui-core';
import {logEvent, getFormattedPhoneNumber} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import $ from 'jquery';
import 'less/MobileMenu.less';
import {BrComponent} from '@bloomreach/react-sdk';
import styled, {css} from 'styled-components';
import User from 'icons/User.svg';

const Submenu = styled.span`
    font-size: 12px;
    font-weight: 600;
`;
const Subbreak = styled.br`
    line-height:0px !important; 
`;
const MobileMenuView = {
    Main: 'Main',
    Departments: 'Departments',
    Solutions: 'Solutions',
    MyAccount: 'MyAccount',
    Lists: 'Lists',
    Pro: 'PRO',
    PsPlus: 'PsPlus',
};

const Header = styled.div`
    background-color: #004A84;
    color: #ffffff;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 18px;
    box-shadow: 0px 4px 4px 0px #00000040;

    svg {
        overflow: visible;
    }
`;

const UserName = styled.span`
    padding-left: 8px;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
`;

@withRouter
@connect(
    state => ({network: state.network, cart: state.cart, user: state.user, configuration: state.system.configuration}),
    _.merge({}, NetworkStore.actionCreators, UserStore.actionCreators, CartStore.actionCreators, DashboardStore.actionCreators, Socket.actionCreators)
)
export default class MobileMenu extends React.Component {
    static propTypes = {
        ...NetworkStore.ActionShape,
        ...UserStore.ActionShape,
        ...CartStore.ActionShape,
        ...DashboardStore.ActionShape,
        network: NetworkStore.StateShape,
        user: UserStore.StateShape,        
        onShowNewList: PropTypes.func,
    };

    static displayName = 'MobileMenu';

    constructor(props) {
        super(props);

        this.state = {
            currentView: MobileMenuView.Main,
            showCreditHoldModal: false,
        };
    }

    closeHamburgerMenu() {
        $(document.body).removeClass('modal-open');
        setTimeout(() => {
            const {closeMobileMenu} = this.props;
            closeMobileMenu();
            this.setState({currentView: MobileMenuView.Main});
        }, 400);
    }

    logOut = async () => {
        const {onLogout, disconnectWebsocket, stopNotifications} = this.props;

        this.closeHamburgerMenu();
        disconnectWebsocket();
        stopNotifications();
        onLogout();        
    }

    onShowDepartmentMenu() {
        const {openMobileMenu} = this.props;
        openMobileMenu(UserStore.MobileMenu.Departments);
    }

    onShowSolutionsMenu() {
        const {openMobileMenu} = this.props;
        openMobileMenu(UserStore.MobileMenu.Solutions);
    }

    onShowProMenu() {
        const {openMobileMenu} = this.props;
        openMobileMenu(UserStore.MobileMenu.Pro);
    }

    goToMainMenu() {
        this.setState({currentView: MobileMenuView.Main});
    }

    addToHistory(link, state) {
        const {history} = this.props;
        history.push(link, state);
        this.closeHamburgerMenu();
    }

    goToPage(link) {
        this.addToHistory(link);
        logEvent('My Account', {'menu item': link});
    }

    goToCommunity(link) {
        this.addToHistory(link);
        logEvent('Community Visit', {});
    }

    onShowRequestService() {
        const {history, user: {facility, settings: {creditHold}}} = this.props;
        const hasCreditHold = (facility && parseInt(facility.facilityId) > 0) ? facility.creditHold : creditHold;

        if (!hasCreditHold) {
            this.addToHistory('/request-service', {from: history.location.pathname});
            logEvent('Request Service', {});
        } else {
            this.setState({showCreditHoldModal: true});
            this.closeHamburgerMenu();
        }
    }

    onShowRequestQuote() {
        const {history, user: {facility, settings: {creditHold}}} = this.props;
        const hasCreditHold = (facility && parseInt(facility.facilityId) > 0) ? facility.creditHold : creditHold;

        if (!hasCreditHold) {
            this.addToHistory('/quote/request', {from: history.location.pathname});
            logEvent('Request Quote', {});
        } else {
            this.setState({showCreditHoldModal: true});
            this.closeHamburgerMenu();
        }
    }

    onShowNewList() {
        const {onShowNewList} = this.props;
        onShowNewList();
        this.closeHamburgerMenu();
    }

    showPsPlus() {
        this.setState({currentView: MobileMenuView.PsPlus});
    }

    render() {
        let {currentView, showCreditHoldModal} = this.state;
        const {
            user: {info, settings, mobileMenuOpen, lists, facilities},
            network: {isLoggedIn},
            configuration: {
                features: {oemConnectOrder},
            },
        } = this.props;
        const myOemAccess = settings.myOem;

        const userName = info && info.firstName ? _.startCase(info.firstName.toLowerCase()) : 'User';
        let initials = info && info.firstName ? info.firstName.charAt(0) + info.lastName.charAt(0) : '';

        if (mobileMenuOpen) currentView = mobileMenuOpen;
        const enableTfyp = facilities.filter((x) => x.showTfyp).length > 0;

        const showApprovalMenu = settings.approver;
        const showAwaitingPoMenu = settings.showAwaitingPoMenu;

        const dedicatedPhoneNumber = settings.dedicatedPhoneNumber ? 
            getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : defaultPhoneNumber;

        return (<>
            <Drawer style={{overflowY: 'auto', paddingLeft: '0px', paddingRight: '0px'}} currentView={currentView} show={mobileMenuOpen !== ''} onClose={() => this.closeHamburgerMenu()}>
                <div className="hamburger-menu">
                    <div>
                        <div className="main-menu-top">
                            {isLoggedIn ? (
                                <Header>
                                    <User style={{width: '26px', height: '26px'}} />
                                    <UserName>{`${info.firstName.toLowerCase()} ${info.lastName.charAt(0)}.`}</UserName>
                                </Header>
                            ) : (
                                <Header>
                                    <User style={{width: '26px', height: '26px'}} />
                                    <span className="sign-in" onClick={() => this.goToPage('/login')}>
                                        Sign In
                                    </span>
                                    <span className="sign-in-divider" />
                                    <span className="register" onClick={() => this.goToPage('/register')}>
                                        Register
                                    </span>
                                </Header>
                            )}

                            {isLoggedIn && (
                                <div className="hamburger-item">
                                    <span className="hamburger-item-desc right-chevron" onClick={() => this.setState({currentView: MobileMenuView.MyAccount})}>
                                        My Account
                                    </span>
                                </div>
                            )}
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc right-chevron" onClick={::this.onShowDepartmentMenu}>
                                    Departments
                                </span>
                            </div>
                            {!isLoggedIn && <div className="hamburger-item">
                                <span className="hamburger-item-desc right-chevron" onClick={::this.onShowSolutionsMenu}>
                                    Solutions
                                </span>
                            </div>}
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc right-chevron" onClick={::this.onShowProMenu}>
                                    PRO
                                </span>
                            </div>
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/history')}>
                                    Browsing History
                                </span>
                            </div>
                            {isLoggedIn && (
                                <div className="hamburger-item">
                                    <span className="hamburger-item-desc" onClick={() => this.goToPage('/buy-again')}>
                                        Buy It Again
                                    </span>
                                </div>
                            )}
                            {settings.enableTfyp && isLoggedIn && (
                                <div className="hamburger-item">
                                    <span className="hamburger-item-desc" onClick={::this.onShowRequestService}>
                                        Request Service<Subbreak/><Submenu>On-Site</Submenu>
                                    </span>
                                </div>
                            )}
                            {settings.enableTfyp && isLoggedIn && (
                                <div className="hamburger-item">
                                    <span className="hamburger-item-desc" onClick={::this.onShowRequestQuote} data-ea-cta-link="CTA Link Clicked">
                                        Request Quote<Subbreak/><Submenu>Parts & Depot</Submenu>
                                    </span>
                                </div>
                            )}
                            {isLoggedIn && (
                                <div className="hamburger-item">
                                    <span className="hamburger-item-desc right-chevron" onClick={() => this.setState({currentView: MobileMenuView.Lists})}>
                                        Lists
                                    </span>
                                </div>
                            )}
                            <div style={{paddingBottom: '25px'}} />
                            <div className="hamburger-divider" />
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc">
                                    <i className="fa fa-phone" />
                                    <a href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a>
                                </span>
                            </div>
                            {isLoggedIn && (<div className="hamburger-item" onClick={::this.logOut}>
                                <i className="fa fa-sign-out" aria-hidden="true" />
                                <span>Logout</span>
                            </div>)}
                        </div>
                    </div>

                    <div className={currentView === MobileMenuView.Departments ? 'sub-menu open' : 'sub-menu'}>
                        <BrComponent path={'header/departments-menu'}>
                            <MobileFlyoutMenu />
                        </BrComponent>
                    </div>
                    <div className={currentView === MobileMenuView.Solutions ? 'sub-menu open' : 'sub-menu'}>
                        <BrComponent path={'header/solutions-menu'}>
                            <MobileFlyoutMenu />
                        </BrComponent>
                    </div>
                    <div className={currentView === MobileMenuView.Pro ? 'sub-menu open' : 'sub-menu'}>
                        <BrComponent path={'header/pro-menu'}>
                            <MobileFlyoutMenu />
                        </BrComponent>
                    </div>
                    <div className={currentView === MobileMenuView.MyAccount ? 'sub-menu open' : 'sub-menu'}>
                        <div className="sub-menu-title left-chevron" onClick={::this.goToMainMenu}>
                            Main Menu
                        </div>
                        <div className="hamburger-divider" />
                        <div className="sub-menu-header">My Account</div>
                        {settings.hasDashboardv2Access && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/command-center')}>
                                    Command Center
                                </span>
                            </div>
                        )}
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc" onClick={() => this.goToPage('/orders')}>
                                Orders
                            </span>
                        </div>
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc" onClick={() => this.goToPage('/quotes')}>
                                Parts Quotes
                            </span>
                        </div>
                        {showApprovalMenu && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/approvals')}>
                                    Approvals
                                </span>
                            </div>
                        )}
                        {showAwaitingPoMenu && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/orders/po')}>
                                    Awaiting PO
                                </span>
                            </div>
                        )}
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc" onClick={() => this.goToPage('/repairs')}>
                                Depot Repairs & Quotes
                            </span>
                        </div>
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc" onClick={() => this.goToPage('/on-site-service')}>
                                On-Site Service
                            </span>
                        </div>
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc" onClick={() => this.goToPage('/orders/rga')}>
                                Exchanges & Returns
                            </span>
                        </div>
                        {settings.hasPSPlusAccess && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.showPsPlus()}>
                                    Plus+ Program
                                </span>
                            </div>
                        )}
                        {settings.hasReportsAccess && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/reports')}>
                                    Visual Analytics
                                </span>
                            </div>
                        )}
                        {oemConnectOrder && myOemAccess && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/OEMConnectOrders')}>
                                    OEMConnect
                                </span>
                            </div>
                        )}
                        {settings.psCommunityAccess && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/community')}>
                                    Community
                                </span>
                            </div>
                        )}
                        {settings.oneSource && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/onesource-document-search')}>
                                    oneSOURCE Docs
                                </span>
                            </div>
                        )}
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc" onClick={() => this.goToCommunity('/settings')}>
                                Settings
                            </span>
                        </div>
                        {settings.customerAdminPermission && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/customeradmin')}>
                                    Admin
                                </span>
                            </div>
                        )}
                        {(settings.viewFormularyRules || settings.editFormularyRules) && (
                            <div className="hamburger-item">
                                <span className="hamburger-item-desc" onClick={() => this.goToPage('/formulary')}>
                                    Formulary
                                </span>
                            </div>
                        )}
                    </div>
                    <div className={currentView === MobileMenuView.PsPlus ? 'sub-menu open' : 'sub-menu'}>
                        <div className="sub-menu-title left-chevron" onClick={::this.goToMainMenu}>
                            Main Menu
                        </div>
                        <div className="hamburger-divider" />
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc" onClick={() => this.goToPage('/management-tracker/month-over-month')}>
                                Month over Month Spend
                            </span>
                        </div>
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc" onClick={() => this.goToPage('/management-tracker/prior-rebates')}>
                                Prior Rebates
                            </span>
                        </div>
                    </div>
                    <div className={currentView === MobileMenuView.Lists ? 'sub-menu open' : 'sub-menu'}>
                        <div className="sub-menu-title left-chevron" onClick={::this.goToMainMenu}>
                            Main Menu
                        </div>
                        <div className="hamburger-divider" />
                        <div className="sub-menu-header" style={{cursor: 'pointer'}} onClick={() => this.goToPage('/lists')}>
                            My Lists
                        </div>

                        {lists && lists !== undefined && lists.length !== 0
                            ? lists
                                .filter((x) => x.groupName === 'mylist' && x.name)
                                .map((key) => (
                                    <div key={`list_${key.ravenId}`} className="hamburger-item">
                                        <span className="hamburger-item-desc" key={key.ravenId} onClick={() => this.goToPage(`/list/${key.ravenId}`)}>
                                            {key.name}
                                        </span>
                                    </div>
                                ))
                            : null}
                        <div className="hamburger-item">
                            <span className="hamburger-item-desc right-chevron" style={{fontWeight: 700}} onClick={() => this.goToPage('/lists/create?origin=menu')}>
                                Create New List{' '}
                            </span>
                        </div>
                    </div>
                </div>
            </Drawer>
            {showCreditHoldModal && <OnCreditHoldModal onSubmit={() => this.setState({showCreditHoldModal: false})} />}
        </>);
    }
}
