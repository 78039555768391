import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {ProductCarousel as Carousel} from '@partssourceinc/react-ui-core';
import {useSelector} from 'react-redux';

import 'less/carousel/carousel.less';
import 'less/carousel/slick-theme.less';

const ProductCarousel = (props) => {
    const {component, page} = props;
    const itemHistory = useSelector(state => state.cart.itemHistory);
    const isLoggedIn = useSelector(state => state.cart.isLoggedIn);
    const user = useSelector(state => state.cart.user);
    const facility = useSelector(state => state.cart.facility);
    const [products, setProducts] = useState([]);
    const params = component.getParameters();
    const {title, subTitle, link, minItems, maxItems, query, carouselType, hideInMobile, parts: partsDoc} = params;
    const models = component.getModels() || {};
    let parts = {};

    if (models && models.parts) {
        let model = page.getContent(models.parts).getData();
        parts = {...model};
    }

    const onCarosuelClick = (index) => {
        const item = products[index];
        dataLayer.push({
            'event': 'promotionClick',
            'ecommerce': {
                'promoClick': {
                    'promotions': [{
                        'id': component.getId(),
                        'name': carouselType,
                        'creative': item.id,
                        'position': index,
                    }],
                },
            },

        });
    }

    useEffect(() => {
        const facilityId = !isLoggedIn ? 38451 : facility.facilityId > 0 ? facility.facilityId : user.facilities[0].facilityId;

        const request = {
            start: 0,
            limit: maxItems || 50,
            query: query,
            facilityId: facilityId,
        };

        if (carouselType) {
            switch (carouselType) {
                case 'also-bought':
                    axios.get(`/CatalogService/api/v1/similar/${request.query}`).then(response => setProducts(response.data.products));
                    break;
                case 'buy-again':
                    axios.get('/CatalogService/api/v1/buy-again', request).then(response => setProducts(response.data));
                    break;
                case 'most-popular':
                    axios.post('/CatalogService/api/v1/popular', request).then(response => setProducts(response.data.products));
                    break;
                case 'left-off':
                    if (itemHistory && itemHistory.views && itemHistory.views.length > 0)
                        setProducts(itemHistory.views.map(v => v.product));
                    break;
                case 'recommended':
                    axios.get('/CatalogService/api/v1/personal', request).then(response => setProducts(response.data.products));
                    break;
                default:
                    console.log('Unsupported carouselType: ' + carouselType);
                    break;
            }
        } else {
            (parts.parts || []).forEach(part => part.thumbnailUrl = page.getContent(part.thumbnailUrl).getOriginal().getUrl());
            setProducts(parts.parts || []);
        }
    }, [carouselType, partsDoc]);

    return (<Carousel
        onProductSelected={onCarosuelClick}
        products={products}
        title={title}
        subTitle={subTitle}
        link={link}
        minItems={minItems}
        maxItems={maxItems}
        carouselType={carouselType}
        hideInMobile={hideInMobile}
    />);
}

export default ProductCarousel;
