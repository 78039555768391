import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import _ from 'lodash';
import {getCookie, logEvent} from 'utility';

import useLogout from './useLogout';

const useRenderUserMenuOptions = (authHandler) => {
    const {logOut} = useLogout(authHandler);

    const {firstName, lastName} = useSelector(state => state.user.info);
    const {isImpersonation} = useSelector(state => state.network.tokenInfo);
    const {dashboard, oemConnectOrder} = useSelector(state => state.system.configuration.features);
    const settings = useSelector(state => state.user.settings);
    
    const [showAccountMenu, setShowAccountMenu] = useState(false);

    const onShowAccountMenu = () => {
        setTimeout(function () {
            if (!$('#lnkYourAccount').is(':hover')) {
                return;
            }
            setShowAccountMenu(true);
        }.bind(this), 200);
    }

    const onHideAccountMenu = () => {
        setTimeout(function () {
            if ($('#lnkYourAccount').is(':hover')) {
                return;
            }
            setShowAccountMenu(false);
        }.bind(this), 200);
    }

    const sendLog = (event) => {
        logEvent('My Account', {
            id_ins: getCookie('id_ins'),
            'menu item': event.target.innerText,
            'Source Descriptor': event.target.innerText === 'oneSOURCE Docs' ? window.location.href : undefined,
        });
    }

    const sendCommunityLog = () => {
        logEvent('Community Visit', {id_ins: getCookie('id_ins')});
    }

    const renderUserMenuOtions = () => {
        return (
            <span className="no-right-gutter">
                <span
                    id="lnkYourAccount"
                    className="your-account"
                    onMouseEnter={onShowAccountMenu}
                    onMouseLeave={onHideAccountMenu}>
                    <span>
                        <span className="user-icon">{firstName ? firstName.charAt(0) + lastName.charAt(0) : ''}</span>
                        <span className="account-label">My Account</span>
                        <span className="username">{`Hello, ${firstName ? _.startCase(firstName.toLowerCase()) : 'User'}`}</span>
                    </span>
                    {showAccountMenu && (
                        <div id="account-menu" className="account-menu">
                            {dashboard && settings?.hasDashboardv2Access && (
                                <>
                                    <NavLink to="/command-center" onClick={(event) => sendLog(event)}>
                                        <div className="account-menu-item">Command Center</div>
                                    </NavLink>
                                </>
                            )}
                            <NavLink to="/orders" onClick={(event) => sendLog(event)}>
                                <div className="account-menu-item">Orders</div>
                            </NavLink>
                            <NavLink to="/quotes" onClick={(event) => sendLog(event)}>
                                <div className="account-menu-item">Parts Quotes</div>
                            </NavLink>

                            {settings?.approver && (
                                <NavLink to="/approvals" onClick={(event) => sendLog(event)}>
                                    <div className="account-menu-item">Approvals</div>
                                </NavLink>
                            )}
                            {settings?.showAwaitingPoMenu && (
                                <NavLink to="/orders/po" onClick={(event) => sendLog(event)}>
                                    <div className="account-menu-item">Awaiting PO</div>
                                </NavLink>
                            )}
                            <NavLink to="/repairs" onClick={(event) => sendLog(event)}>
                                <div className="account-menu-item">Depot Repairs & Quotes</div>
                            </NavLink>
                            {settings?.enableLoanerFunctionality && settings?.allowLoaner && (<NavLink to="/loaners" onClick={(event) => sendLog(event)}>
                                <div className="account-menu-item">Loaners</div>
                            </NavLink>)} 
                            {
                                settings?.enableOnSiteService && (
                                    <NavLink to="/on-site-service" onClick={(event) => sendLog(event)}>
                                        <div className="account-menu-item">On-Site Service</div>
                                    </NavLink>
                                )
                            }
                            <NavLink to="/orders/rga" onClick={(event) => sendLog(event)}>
                                <div className="account-menu-item">Exchanges & Returns</div>
                            </NavLink>
                            {settings?.hasPSPlusAccess && (
                                <NavLink to="/psplus" onClick={(event) => sendLog(event)}>
                                    <div className="account-menu-item">Plus+ Program</div>
                                </NavLink>
                            )}
                            {oemConnectOrder && settings?.myOem && (
                                <NavLink to="/OEMConnectOrders" onClick={(event) => sendLog(event)}>
                                    <div className="account-menu-item">OEMConnect</div>
                                </NavLink>
                            )}
                            {settings?.hasReportsAccess && (
                                <NavLink to="/reports" onClick={(event) => sendLog(event)}>
                                    <div className="account-menu-item">Visual Analytics</div>
                                </NavLink>
                            )}
                            {settings?.psCommunityAccess && (
                                <NavLink
                                    to="/community"
                                    onClick={sendCommunityLog}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <div className="account-menu-item">Community</div>
                                </NavLink>
                            )}
                            {settings?.oneSource && (
                                <NavLink
                                    to="/onesource-document-search"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    onClick={(event) => sendLog(event)}
                                >
                                    <div className="account-menu-item">
                                        oneSOURCE Docs
                                    </div>
                                </NavLink>
                            )}
                            <NavLink to="/settings" onClick={(event) => sendLog(event)}>
                                <div className="account-menu-item">Settings</div>
                            </NavLink>
                            {settings?.customerAdminPermission && (
                                <NavLink to="/customeradmin" onClick={(event) => sendLog(event)}>
                                    <div className="account-menu-item">Admin</div>
                                </NavLink>
                            )}
                            {(settings?.viewFormularyRules || settings?.editFormularyRules) && <NavLink to="/formulary" onClick={(event) => sendLog(event)}>
                                <div className="account-menu-item">Formulary</div>
                            </NavLink>}
                            <div className="divider" />
                            <div className="account-logout-menu-item"
                                onClick={() => logOut()}>
                                <i className="fa fa-sign-out" aria-hidden="true" />
                                <span>Logout</span>
                            </div>
                            {isImpersonation && <div style={{marginBottom: '10px'}}>
                                <div className="divider" style={{marginTop: 0}} />
                                <NavLink to="/formulary" onClick={(event) => sendLog(event)}>
                                    <div className="account-menu-item">Formulary</div>
                                </NavLink>
                            </div>}
                        </div>
                    )
                    }
                </span>
            </span >
        );
    };

    return {renderUserMenuOtions};
}

export default useRenderUserMenuOptions;
