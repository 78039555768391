import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {BannerCluster} from '@partssourceinc/react-ui-core/cms';
import _ from 'lodash';
import * as KevelStore from 'stores/Kevel';
import {getLocationQuery} from '../../utility';

const AdBannerCluster = (props) => {
    const {getDecisions, fireClickImpression} = KevelStore.actionCreators;
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const {component, page} = props;

    const params = component.getParameters() || {};
    const {title, clusterStyle, layout, hideBottomMargin} = params;
    const [isLoading, setIsLoading] = useState(true);

    const mapBanners = () => {
        const models = component.getModels() || [];

        let banners = Object.keys(models).map((key) => {
            const keyIndex = key.slice(-1);
            const content = page.getContent(models[`banner${keyIndex}`]);
            const banner = content && content.getData() || null;

            if (banner) {
                const html = banner.content && banner.content.value ? page.rewriteLinks(banner.content.value) : null;
                const image = page.getContent(banner.image);
                const imageUrl = image && image.getOriginal().getUrl() || null;
                return {
                    ...banner,
                    zoneId: params[`zoneId${keyIndex}`],
                    adTypeId: params[`adTypeId${keyIndex}`],
                    position: params[`position${keyIndex}`],
                    keywords: params[`keywords${keyIndex}`],
                    html,
                    linkText: banner.link && banner.link.text || '',
                    linkUrl: banner.link && banner.link.link || '#',
                    imageUrl,
                    bannerStyle: clusterStyle,
                }
            }
            return {};
        });
        return _.orderBy(banners, ['key', 'asc']);
    };

    const [banners, setBanners] = useState(page.isPreview() ? [] : mapBanners());
    let zoneIds = banners.filter(banner => banner.zoneId).map(banner => banner.zoneId) || [];
    let adTypeIds = banners.filter(banner => banner.adTypeId && !isNaN(banner.adTypeId)).map(banner => banner.adTypeId) || [];

    // Get all array of keywords
    let isSearchPage = (pathname.indexOf('/catalog') > -1) ? true : false;
    let keywordArrays = []
    if (!isSearchPage) {
        keywordArrays = banners.filter(banner => banner.keywords).map(banner => {
            let kw = [];
            if (banner.keywords) {
                kw = banner.keywords.split(',').map(word => word.trim());
            }
            return kw;
        });
    } else {
        let urlParams = getLocationQuery(location);
        if (urlParams.q)
            keywordArrays.push(urlParams.q.trim());

        _.flatten(Object.keys(urlParams)
            .filter(k => k[0] !== '_' && k !== 'q')
            .map(key => {
                const fq = urlParams[key];
                if (_.isArray(fq)) {
                    return fq.map(value => keywordArrays.push(value));
                } else {
                    return keywordArrays.push(key);
                }
            }));
    }
    // Flatten the array, remove duplicates
    let keywords = [...new Set([].concat.apply([], keywordArrays))];

    const loadDecisions = async () => {
        const zones = await dispatch(getDecisions(zoneIds, adTypeIds, keywords));
        let zonedBanners = banners.map((banner, index) => {
            if (!banner.zoneId) {
                return banner;
            }

            const ad = zones.find(z => z.zoneId === banner.zoneId);

            if (ad) {
                return {
                    ...banner,
                    html: '',
                    imageUrl: '',
                    title: ad.title,
                    subtitle: '',
                    linkText: '',
                    linkUrl: '',
                    ad: ad,
                    fireImpression: (url) => dispatch(fireClickImpression(url)),
                }
            }
            return banner;
        });

        setBanners(zonedBanners);
        setIsLoading(false);
    };

    const onBannerClick = (e, banner) => {
        dispatch(fireClickImpression(banner.clickUrl));
    }

    useEffect(() => {
        if (zoneIds.length && adTypeIds.length) {
            loadDecisions(zoneIds);
        } else {
            setIsLoading(false);
        }
    }, []);

    return !isLoading && zoneIds.length ?
        <BannerCluster
            onBannerClick={onBannerClick}
            hideBottomMargin={hideBottomMargin}
            clusterStyle={clusterStyle}
            banners={banners}
            layout={layout}
            title={title} />
        : !isLoading ? <BannerCluster {...props} /> : null
};

export default AdBannerCluster;
