import * as PropTypes from 'prop-types';
import React from 'react';

import PageMetaData from 'components/PageMetaData';

const Seo = props => {
    const {component} = props;
    const params = component.getParameters() || {};
    const {documentTitleBeanProperties: title, documentDescriptionBeanProperties: description} = params;

    return (<PageMetaData
        title={title}
        description={description}
        canonical={window.location.origin.replace('http:','https:') + window.location.pathname}
        extend={true} 
        trackAdobeAnalytics={false}
    />)
};

Seo.propTypes = {
    configuration: PropTypes.shape({
        _meta: PropTypes.shape({
            paramsInfo: PropTypes.shape({
                documentTitleBeanProperties: PropTypes.string,
                documentDescriptionBeanProperties: PropTypes.string,
            }),
        }),
    }),
};

export default Seo;
