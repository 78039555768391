import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {Button} from '@partssourceinc/react-ui-core';
import {useHistory} from 'react-router';
import styled from 'styled-components'



const Container = styled.div`
    width: 100%;
    text-align: center;
`

export default function RSAFormLink() {
    const isLoggedIn = useSelector(state => state.network.isLoggedIn);
    const settings = useSelector(state => state.user.settings);
    const history = useHistory();
    
    function onClickStartButton() {
        history.push('/request-rsa', {from: history.location.pathname});
    }
    
    function redirectToLogInScreen() {
        history.push('/login');
    }
    
    if (!isLoggedIn) {
        return (
            <Container>
                <Button onClick={redirectToLogInScreen}>
                    Sign in and Request Quote
                </Button>
            </Container>
        );
    } else if (settings.enableOnSiteService) {
        return (
            <Container>
                <Button onClick={onClickStartButton}>
                    Start Here
                </Button>
            </Container>
        );
    }
    
    return null;
}
