import axios from 'axios';
import moment from 'moment';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import _ from 'lodash';
import {TextField, Button, Popup} from '@partssourceinc/react-ui-core';
import FlyoutMenu from 'components/cms/FlyoutMenu';
import FacilityDialog from 'components/FacilitySearchDialog';
import OnCreditHoldBanner from './OnCreditHoldBanner';
import OnCreditHoldModal from './OnCreditHoldModal';
import {ProductImage} from '@partssourceinc/react-ui-core';
import QuickOrder from 'pages/QuickOrder';
import * as CartStore from 'stores/Cart';
import * as NetworkStore from 'stores/Network';
import * as UserStore from 'stores/User';
import * as DashboardStore from 'stores/Dashboard';
import * as Socket from 'stores/socket';
import * as CompanyStore from 'stores/Companies';
import {getCookie, setCookie, isValidListName, clearTokenInfo, logEvent, getFormattedPhoneNumber, getPersonalizationCookiesEnabled} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import MobileMenu from 'components/MobileMenu';
import * as SystemStore from 'stores/System';
import $ from 'jquery';
import 'less/header.less';
import {BrComponent} from '@bloomreach/react-sdk';
import Cookies from 'js-cookie';
import styled from 'styled-components';

const Submenu = styled.span`
    font-size: 12px;
    font-weight: 600;
`;
const Subbreak = styled.br`
    line-height:0px !important; 
`;
const momentCalendarSettings = {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[Last] dddd',
    nextWeek: '[Next] dddd',
    sameElse: 'L',
};

@withRouter
@connect(
    state => ({
        network: state.network,
        cart: state.cart,
        user: state.user,
        configuration: state.system.configuration,
        siteSettings: state.system.siteSettings,
        sessionStorageAvailable: state.network.sessionStorageAvailable,
        categories: state.companies.categories,
    }),
    _.merge({},
        NetworkStore.actionCreators,
        UserStore.actionCreators,
        CartStore.actionCreators,
        DashboardStore.actionCreators,
        SystemStore.actionCreators,
        Socket.actionCreators,
        CompanyStore.actionCreators
    )
)
export class Header extends React.Component {
    static propTypes = {
        ...NetworkStore.ActionShape,
        ...UserStore.ActionShape,
        ...CartStore.ActionShape,
        ...DashboardStore.ActionShape,
        network: NetworkStore.StateShape,
        cart: CartStore.StateShape,
        user: UserStore.StateShape,
    };

    static displayName = 'Header';

    constructor(props) {
        super(props);
        this.navigateToProduct = this.navigateToProduct.bind(this);
        this.navigateToSuggestion = this.navigateToSuggestion.bind(this);
        this.sendLog = this.sendLog.bind(this);
        this.state = {
            showMyListsMenu: false,
            showAccountMenu: false,
            showFacilityDialog: false,            
            hideMenuNav: props.hideMenuNav,
            onSettingsDataLoaded: props.onSettingsDataLoaded,
            term: null,
            suggestions: [],
            historySuggestions: [],
            highlightIndex: null,
            searchDepartment: {display: 'All', category: null},
            browsingHistoryItems: [],
            showBrowsingHistoryMenu: false,
            showNewList: false,
            searchFocused: false,
            showQuickOrder: false,
            myLists: [],
            // Keep track of this because QuickOrder component will
            // only null out and reset on page change.
            pageChanged: false,
            invalidListName: false,
            stoppingImpersonation: false,
            isCreditHold: false,
            showCreditHoldModal: false,
            alertTypeCode: ''
        };
    }

    componentDidUpdate = (prevProps) => {
        const {location} = this.props;
        if (location !== prevProps.location) {
            this.setState({pageChanged: true});
        }
    }

    componentDidMount = () => {
        const {
            network: {
                isLoggedIn,
                tokenInfo: {isPunchout},
            },
            user: {facilities, facility, settings: {creditHold}},
            history,
            location: {pathname},
            getCompanyFields,
            itemHistory,
            getBrowsingHistory,
            getBrowsingHistoryFromCookieProductsData,
            cart: {cartItems},
            getSettings,
            getLists,
            loadModalities,
            sessionStorageAvailable,
            onSettingsDataLoaded,
        } = this.props;

        if (isLoggedIn) {
            getSettings().then(() => {
                onSettingsDataLoaded();
            });
            getLists();
            loadModalities();

            if (sessionStorageAvailable && !sessionStorage.fields && facilities.length > 0) {
                getCompanyFields(facilities[0].facilityId);
            }

            if (pathname !== '/cart' && pathname.indexOf('/checkout') === -1) {
                if (isPunchout && cartItems.length > 0) {
                    history.push('/cart');
                }
            }

            if (!itemHistory || !itemHistory.views || itemHistory.views.length < 1) {
                getBrowsingHistory();
            }

            this.setQualtricsData();
        } else {
            getBrowsingHistoryFromCookieProductsData();

            if (sessionStorageAvailable && !sessionStorage.fields) {
                getCompanyFields(38451);
            }
            onSettingsDataLoaded();
        }

        let isCH = (facility && parseInt(facility.facilityId) > 0) ? facility.creditHold : creditHold;
        const creditHoldAlert = facilities.every(f => f.creditHold) 
            ? 'CreditHoldBanner' 
            : facilities.some(f => f.creditHold) 
                ? Object.keys(facility).length === 0
                    ? 'CreditHoldPartialBanner'
                    : facility && facility.creditHold
                        ? 'CreditHoldBanner'
                        : ''
                : '';

        this.setState({isCreditHold: isCH});
        this.setState({alertTypeCode: creditHoldAlert})
        if (isCH && (location.pathname === '/request-service' || location.pathname === '/quote/request')) {            
            this.setState({showCreditHoldModal: true, pageChanged: false});
            return;
        }
        this.loadHistorySuggestions();
    }

    componentWillReceiveProps = (newProps) => {
        const {
            network: {isLoggedIn},
            user: {info: {rootCompanyId}, facility, settings: {creditHold}},
            cart: {
                itemHistory,
            },
            user: {facilities}
        } = newProps;
        const {location, history} = this.props;
        localStorage.removeItem('restrictCreditHold'); 
        let ch = (facility && parseInt(facility.facilityId) > 0) ? facility.creditHold : creditHold;
        const creditHoldAlert = facilities.every(f => f.creditHold) 
        ? 'CreditHoldBanner' 
        : facilities.some(f => f.creditHold) 
            ? Object.keys(facility).length === 0
                ? 'CreditHoldPartialBanner'
                : facility && facility.creditHold
                    ? 'CreditHoldBanner'
                    : ''
            : '';

        this.setState({isCreditHold: ch});
        this.setState({alertTypeCode: creditHoldAlert})

        if (ch && (newProps.location.pathname === '/request-service' || newProps.location.pathname === '/quote/request')) {
            this.setState({showCreditHoldModal: true});

            if (location.pathname==='/request-service' || location.pathname==='/quote/request'){
                return;
            }
        } 
        
        if (newProps.location.pathname !== location.pathname
            && !(newProps.location.pathname.indexOf('/catalog') >= 0)
            && !(newProps.location.pathname.indexOf('/parts') >= 0)) {
            this.search.value = '';
        }

        if (newProps.location.pathname !== location.pathname
            && (newProps.location.pathname.indexOf('/catalog') >= 0 || newProps.location.pathname.indexOf('/parts') >= 0)
            && (!itemHistory || !itemHistory.views || itemHistory.views.length === 0)) {
            this.getBrowsingHistory();
        }

        if (newProps.network.isLoggedIn != isLoggedIn ||
            newProps.user.info.rootCompanyId !== rootCompanyId)
            this.setQualtricsData();
    }

    setQualtricsData = () => {
        const {
            network: {isLoggedIn},
            user: {info: {contactId, fullName, rootCompanyId, rootCompanyName}},
        } = this.props;

        if (isLoggedIn) {
            window.qualtricsData = {
                ...window.qualtricsData,
                userId: contactId,
                userName: fullName,
                parentId: rootCompanyId,
                parentName: rootCompanyName,
            };
        }
    }

    openMobileMenu = () => {
        const {openMobileMenu} = this.props;
        openMobileMenu();
    }

    closeMobileMenu = () => {
        const {closeMobileMenu} = this.props;
        closeMobileMenu();
    }

    onSelectFacility = (facility) => {
        const {saveUserReduxState} = this.props;
        saveUserReduxState({facility: facility});
        this.setState({showFacilityDialog: false});
    }

    onStopImpersonation = () => {
        this.setState({stoppingImpersonation: true});
        const {stopImpersonation, history} = this.props;
        Cookies.remove('impersonate');
        stopImpersonation().then(() => {
            history.push('/admin');
        });
    }

    renderLoginRegister = () => {
        return (
            <span className="no-right-gutter">
                <span className="login-register">
                    <span className="account-label">
                        <NavLink to="/login">Sign In</NavLink>
                    </span>
                    <span className="divider" />
                    <span className="account-label">
                        <NavLink to="/register">Register</NavLink>
                    </span>
                </span>
            </span>
        );
    }

    onShowMyListsMenu = () => {
        setTimeout(function () {
            if (!$('#lnkMyLists').is(':hover')) {
                return;
            }
            this.setState({showMyListsMenu: true});
        }.bind(this), 200);
    }

    onHideMyListsMenu = () => {
        setTimeout(function () {
            if ($('#lnkMyLists').is(':hover')) {
                return;
            }
            this.setState({showMyListsMenu: false});
        }.bind(this), 200);
    }

    onShowAccountMenu = () => {
        setTimeout(function () {
            if (!$('#lnkYourAccount').is(':hover')) {
                return;
            }
            this.setState({showAccountMenu: true});
        }.bind(this), 200);
    }

    onHideAccountMenu = () => {
        setTimeout(function () {
            if ($('#lnkYourAccount').is(':hover')) {
                return;
            }
            this.setState({showAccountMenu: false});
        }.bind(this), 200);
    }

    sendLog = (event) => {
        logEvent('My Account', {
            id_ins: getCookie('id_ins'),
            'menu item': event.target.innerText,
            'Source Descriptor': event.target.innerText === 'oneSOURCE Docs' ? window.location.href : undefined,
        });
    }

    sendCommunityLog = () => {
        logEvent('Community Visit', {id_ins: getCookie('id_ins')});
    }

    renderUserLoggedIn = () => {
        const {user: {info, settings}, configuration: {features: {oemConnectOrder, dashboard}}} = this.props;
        const userName = info && info.firstName ? _.startCase(info.firstName.toLowerCase()) : 'User';
        const showApprovalMenu = settings.approver;
        const showAwaitingPoMenu = settings.showAwaitingPoMenu;
        const hideMyAccountMenu = settings.hideMyAccountMenu;
        const {showAccountMenu} = this.state;
        let initials = info && info.firstName ? info.firstName.charAt(0) + info.lastName.charAt(0) : '';
        const myOemAccess = settings.myOem;
        const {network: {isLoggedIn, tokenInfo: {userId, isImpersonation}}} = this.props;

        const yourAccountClass = hideMyAccountMenu ? 'your-account hide-my-account' : 'your-account' ;
        
        return (
            <span className="no-right-gutter">
                <span
                    id='lnkYourAccount'
                    className={yourAccountClass}
                    onMouseEnter={!hideMyAccountMenu && this.onShowAccountMenu}
                    onMouseLeave={!hideMyAccountMenu && this.onHideAccountMenu}>
                    <span>
                        <span className="user-icon">{initials}</span>
                        {!hideMyAccountMenu && (
                            <>
                                <span className="account-label">My Account</span>
                                <span className="username">{`Hello, ${userName}`}</span>
                            </>
                        )}
                        {hideMyAccountMenu && (
                            <span className="username no-my-account">{`Hello, ${userName}`}</span>
                        )}
                    </span>
                    {showAccountMenu && (
                        <div id="account-menu" className="account-menu">
                            {dashboard && settings.hasDashboardv2Access && (
                                <React.Fragment>
                                    <NavLink to="/command-center" onClick={this.sendLog}>
                                        <div className="account-menu-item">Command Center</div>
                                    </NavLink>
                                </React.Fragment>
                            )}
                            <NavLink to="/orders" onClick={this.sendLog}>
                                <div className="account-menu-item">Orders</div>
                            </NavLink>
                            <NavLink to="/quotes" onClick={this.sendLog}>
                                <div className="account-menu-item">Parts Quotes</div>
                            </NavLink>

                            {showApprovalMenu && (
                                <NavLink to="/approvals" onClick={this.sendLog}>
                                    <div className="account-menu-item">Approvals</div>
                                </NavLink>
                            )}
                            {showAwaitingPoMenu && (
                                <NavLink to="/orders/po" onClick={this.sendLog}>
                                    <div className="account-menu-item">Awaiting PO</div>
                                </NavLink>
                            )}
                            <NavLink to="/repairs" onClick={this.sendLog}>
                                <div className="account-menu-item">Depot Repairs & Quotes</div>
                            </NavLink>
                            {settings.enableLoanerFunctionality && settings.allowLoaner && (<NavLink to="/loaners" onClick={this.sendLog}>
                                <div className="account-menu-item">Loaners</div>
                            </NavLink>)} 
                            {
                                settings.enableOnSiteService && (
                                    <NavLink to="/on-site-service" onClick={this.sendLog}>
                                        <div className="account-menu-item">On-Site Service</div>
                                    </NavLink>
                                )
                            }
                            <NavLink to="/orders/rga" onClick={this.sendLog}>
                                <div className="account-menu-item">Exchanges & Returns</div>
                            </NavLink>
                            {settings.hasPSPlusAccess && (
                                <NavLink to="/psplus" onClick={this.sendLog}>
                                    <div className="account-menu-item">Plus+ Program</div>
                                </NavLink>
                            )}
                            {oemConnectOrder && myOemAccess && (
                                <NavLink to="/OEMConnectOrders" onClick={this.sendLog}>
                                    <div className="account-menu-item">OEMConnect</div>
                                </NavLink>
                            )}
                            {settings.hasReportsAccess && (
                                <NavLink to="/reports" onClick={this.sendLog}>
                                    <div className="account-menu-item">Visual Analytics</div>
                                </NavLink>
                            )}
                            {settings.psCommunityAccess && (
                                <NavLink
                                    to="/community"
                                    onClick={this.sendCommunityLog}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <div className="account-menu-item">Community</div>
                                </NavLink>
                            )}
                            {settings.oneSource && (
                                <NavLink
                                    to="/onesource-document-search"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    onClick={this.sendLog}
                                >
                                    <div className="account-menu-item">
                                        oneSOURCE Docs
                                    </div>
                                </NavLink>
                            )}
                            <NavLink to="/settings" onClick={this.sendLog}>
                                <div className="account-menu-item">Settings</div>
                            </NavLink>
                            {settings.customerAdminPermission && (
                                <NavLink to="/customeradmin" onClick={this.sendLog}>
                                    <div className="account-menu-item">Admin</div>
                                </NavLink>
                            )}
                            {(settings.viewFormularyRules || settings.editFormularyRules) && <NavLink to="/formulary" onClick={this.sendLog}>
                                <div className="account-menu-item">Formulary</div>
                            </NavLink>}
                            <div className="divider" />
                            <div className="account-logout-menu-item"
                                onClick={this.logOut}>
                                <i className="fa fa-sign-out" aria-hidden="true" />
                                <span>Logout</span>
                            </div>
                            {isImpersonation && <div style={{marginBottom: '10px'}}>
                                <div className="divider" style={{marginTop: 0}} />
                                <NavLink to="/formulary" onClick={this.sendLog}>
                                    <div className="account-menu-item">Formulary</div>
                                </NavLink>
                            </div>}
                        </div>
                    )
                    }
                </span>
            </span >
        );
    }

    onSearchKeyUp = (event) => {
        let {suggestions, highlightIndex, searchFocused, historySuggestions} = this.state;
        let historySearch = false;

        if (searchFocused && suggestions.length === 0) {
            suggestions = historySuggestions;
            historySearch = false;
        }

        if (event.key === 'Unidentified' || event.key === undefined || event.target.id === 'persSettingCurrPw') {
            return;
        }
        if (event.key === 'ArrowDown') {
            if (suggestions) {
                let newIndex = highlightIndex === null ? 0 : highlightIndex + 1;
                newIndex = newIndex >= suggestions.length ? 0 : newIndex;
                this.setState({highlightIndex: newIndex});
            }
        } else if (event.key === 'ArrowUp') {
            if (suggestions && highlightIndex !== null) {
                let newIndex = highlightIndex - 1 < 0 ? suggestions.length - 1 : highlightIndex - 1;
                this.setState({highlightIndex: newIndex});
            }
        } else if (event.keyCode === 13) {
            if (historySearch && !this.search.value) {
                let suggestion = historySuggestions[highlightIndex];
                this.saveSearchHistory(suggestion);
                this.performSearch(suggestion.searchTerm);
                this.search.value = suggestion.searchTerm;
            } else if (!this.refs.currentSuggestion) {
                this.saveSearchHistory({
                    display: event.target.value,
                    query: event.target.value,
                    type: 'search',
                    searchTerm: event.target.value,
                });
                this.performSearch(event.target.value);
            } else {
                this.setState({term: null, suggestions: [], highlightIndex: null});
                this.refs.currentSuggestion.click();
            }
        } else if (event.target.value && event.target.value.length > 2) {
            this.setState({searchTerm: event.target.value});
            this.getSuggestions(event.target.value);
        } else {
            this.setState({
                searchTerm: event.target.value,
                term: null,
                suggestions: [],
            });
        }
    }

    onInputBlur = () => {
        setTimeout(() => {
            this.setState({
                term: null,
                suggestions: [],
                highlightIndex: null,
                searchFocused: false,
            });
        }, 250);
    }

    onInputFocus = () => this.setState({searchFocused: true});

    onSearchIconClick = (term) => {
        const {searchTerm} = this.state;
        this.saveSearchHistory({display: searchTerm, query: searchTerm, type: 'search', searchTerm: searchTerm});
        this.performSearch(term);
    }

    performSearch = (term) => {
        this.setState({term: null});
        document.activeElement.blur();
        const {history} = this.props;
        let {searchDepartment, searchTerm} = this.state;
        let query = searchDepartment.category
            ? `/shop/${searchDepartment.category}`
            : '/catalog';

        if (typeof term === 'object') term = this.search.value;

        if (term) searchTerm = term;

        if (searchTerm && searchTerm.trim() !== '') {
            query = query + `?q=${encodeURIComponent(searchTerm.toLowerCase())}`;
        }

        this.sendBloomReachTrackingPixel(true);

        if (query === '/catalog') history.push('/');
        // temp since there is no open search on catalog
        else history.push(query);
    }

    getSuggestions = (term) => {
        if (term && term !== '') {
            this.setState({term: term});
            axios
                .get(`/CatalogService/api/v1/suggest?limit=10&query=${term}`)
                .then(result => {
                    const {term: oldTerm} = this.state;

                    // lost the race
                    if (oldTerm !== term) return;

                    const {data: {suggestions, filters, products}} = result;
                    if (!suggestions || !filters || !products) {
                        this.setState({term: null, suggestions: []});
                        return;
                    }
                    suggestions.forEach(e => {
                        e.type = 'suggestion'
                    });
                    filters.forEach(e => {
                        e.type = 'filter'
                    });
                    products.forEach(e => {
                        e.type = 'product'
                    });

                    this.setState({
                        term: term,
                        suggestions: [...filters.slice(0, 3), ...suggestions.slice(0, 4), ...products.slice(0, 2)],
                    });
                });
        } else {
            this.setState({
                term: null,
                suggestions: [],
            });
        }
    }

    navigate = (suggestion) => {
        if (suggestion.type === 'product') {
            this.navigateToProduct(suggestion);

        } else if (suggestion.type === 'filter') {
            this.navigateToSuggestionFilter(suggestion);
            this.saveSearchHistory(suggestion);
        } else {
            suggestion.searchTerm = suggestion.searchTerm || suggestion.display;
            this.navigateToSuggestion(suggestion);
            this.saveSearchHistory(suggestion);
            this.search.value = suggestion.searchTerm;
        }
    }

    saveSearchHistory = (suggestion) => {
        const {network: {tokenInfo: {isImpersonation}}} = this.props;
        const {searchTerm} = this.state;

        if (isImpersonation) return;

        if ((suggestion.display || '').trim() === '') return;

        const cookieName = 'searchHistory';
        if (!suggestion.searchTerm)
            suggestion.searchTerm = this.search.value || searchTerm;

        let searchHistory = [];
        let searchHistoryStr = getCookie(cookieName);

        if (searchHistoryStr) {
            try {
                searchHistory = JSON.parse(searchHistoryStr);
            } catch (err) {
                // do nothing
            }
        }

        _.remove(searchHistory, x => x.display === suggestion.display);
        searchHistory.unshift(suggestion);

        if (getPersonalizationCookiesEnabled())
            setCookie(cookieName, JSON.stringify(searchHistory.slice(0, 3)), 30);
        this.setState({historySuggestions: searchHistory.slice(0, 3)});
    }

    navigateToProduct = (product) => {
        const {history} = this.props;
        const fullUrl = `${product.detailUrl}`;

        this.sendBloomReachTrackingPixel(false, product.id);
        history.push(fullUrl);
    }

    navigateToSuggestion = (suggestion) => {
        const {history} = this.props;
        const fullUrl = `/catalog?q=${encodeURIComponent(suggestion.query)}`;
        this.sendBloomReachTrackingPixel(suggestion.type === 'search', suggestion.display, suggestion.searchTerm);
        this.search.value = suggestion.searchTerm;
        history.push(fullUrl);
    }

    navigateToSuggestionFilter(suggestion) {
        const {history} = this.props;
        this.sendBloomReachTrackingPixel(suggestion.type === 'search', suggestion.display, suggestion.searchTerm);
        this.setState({searchTerm: suggestion.searchTerm});
        this.search.value = suggestion.searchTerm || this.search.value;
        history.push(suggestion.query);
    }

    productSearchResultTitle(title) {
        let byIndex = title.lastIndexOf(' by ');
        let partDesc = title.substring(0, byIndex);
        let oem = byIndex > -1 ? title.substring(byIndex + 4, title.length) : title;

        return (
            <div className="product-result-info">
                <div className="desc">{partDesc}</div>
                <div className="oem">{oem}</div>
            </div>
        );
    }

    loadHistorySuggestions() {
        const {network: {isLoggedIn, tokenInfo: {userId}}} = this.props;
        const cookieName = 'searchHistory';
        let searchHistoryStr = getCookie(cookieName);
        if (searchHistoryStr !== '') {
            try {
                const historySuggestions = JSON.parse(searchHistoryStr);
                this.setState({historySuggestions});
            } catch (err) {
                // do nothing
            }
        }
    }

    renderSuggestions = () => {
        let {term, suggestions, highlightIndex, searchFocused, historySuggestions} = this.state;

        if (searchFocused && historySuggestions.length > 0 && !this.search.value && !this.mobileSearch.value) {
            suggestions = historySuggestions;
            term = '';
        }

        const hasResults = suggestions.length > 0;

        if (!hasResults) {
            return null;
        }

        const renderSuggestion = (x, index) => (
            x.type === 'product' ?
                <div className={`search-result prod ${highlightIndex === index ? 'search-result--hover' : ''}`}
                    ref={highlightIndex === index ? 'currentSuggestion' : null} onClick={() => this.navigate(x)}>
                    <span className="image">
                        <ProductImage url={x.thumbnailUrl} style={{width: '56px'}} />
                    </span>
                    <div className="productDetails">
                        {this.productSearchResultTitle(x.title)}
                    </div>
                </div> : <div className={`search-result ${highlightIndex === index ? 'search-result--hover' : ''}`}
                    ref={highlightIndex === index ? 'currentSuggestion' : null}
                    onClick={() => this.navigate(x)}>
                    {!term &&
                        <i className="fa fa-clock-o" aria-hidden="true" style={{color: '#949494', paddingRight: '5px'}} />}
                    {this.getHighlightedText(x.display, term)}
                </div>
        );

        return (
            <div className="search-suggestions">
                {suggestions.map((s, i) => renderSuggestion(s, i))}
            </div>
        );
    }

    createNewGroup = () => {
        const {newListName} = this.state;
        const {user: {lists}} = this.props;

        if (!isValidListName(newListName)) {
            this.setState({invalidListName: true});
            return;
        }

        let newList = _.clone(lists);

        if (newList[newListName] === undefined) {
            newList[newListName] = [];
        }

        this.setState({showLists: false, showNewList: false, newListName: ''});
        logEvent('CREATE LIST', {id_ins: getCookie('id_ins'), 'List Name': newListName});
    }

    enterPressed = (event) => {
        if (event.keyCode === 13) {
            this.createNewGroup();
        }
    }

    getHighlightedText(text, highlight) {
        if (highlight === '' || text === '') return text;

        if (/\W|[_]/g.test(highlight)) {
            // if highlight has any symbols
            highlight = highlight.replace(/\W|_/g, '[$&]'); // use brackets [ ] for the symbols
        }

        // Split on highlight term and include term into parts, ignore case
        let parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <span>
                &nbsp;
                {parts.map((part, i) => (
                    <span key={i}
                        style={(part || '').toLowerCase() === (highlight || '').toLowerCase() ? {fontWeight: '600'} : {}}>
                        {part}
                    </span>
                ))}
                &nbsp;
            </span>
        );
    }
    
    showFacilityDialog = () => {
        const {cart: {loadingPricing}} = this.props;
        if (loadingPricing) return;
        this.setState({showFacilityDialog: true});
    }

    logOut = async () => {
        const {user, disconnectWebsocket} = this.props;

        if (user.settings.hasReportsAccess) {
            this.sisenseLogout();
        }

        disconnectWebsocket();
        this.finishLogout();
    }

    finishLogout = async () => {
        const {stopNotifications, sessionStorageAvailable} = this.props;

        if (sessionStorageAvailable) {
            sessionStorage.removeItem('prevUrl');
        }
        
        await stopNotifications();
        clearTokenInfo();
        logEvent('My Account', {'menu item': 'logOut'})

        const {authHandler} = this.props;
        authHandler.triggerLogout();
        location.href = '/login';
    }

    sisenseLogout = () => {
        return new Promise(resolve => {
            let iframe = document.createElement('iframe');
            iframe.setAttribute('id', 'sso-logout'); // assign an id
            iframe.style.visibility = 'hidden';
            iframe.addEventListener('load', resolve);
            document.body.appendChild(iframe); // to place at end of document
            iframe.setAttribute('src', `${process.env.REACT_APP_SISENSE_URL}/api/auth/logout`);

            resolve();
        });
    }

    sendBloomReachTrackingPixel = (isSearch, selectedText, historySearch) => {
        const searchText = this.search.value || historySearch;

        if (isSearch) {
            if (!searchText) return;
            dataLayer.push({
                event: 'brSearchEvent',
                brPageType: 'search',
                brSearch: {q: searchText},
                brSuggest: undefined,
                brSearchTerm: searchText,
                brIsConversion: 0,
            });
        } else {
            dataLayer.push({
                event: 'brSuggestionEvent',
                brPageType: 'search',
                brSearch: undefined,
                brSuggest: {aq: searchText, q: selectedText},
                brSearchTerm: searchText,
                brIsConversion: 0,
            });
        }
    }

    onBrowsingHistoryHover = () => {
        setTimeout(function () {
            let browsingHistoryLinkHover = $('#lnkBrowsingHistory').is(':hover');
            let $browsingHistoryMenu = $('#browsingHistoryMenu');
            let browsingHistoryMenuHover = $browsingHistoryMenu.length && $browsingHistoryMenu.is(':hover') || false;

            if (!browsingHistoryLinkHover && !browsingHistoryMenuHover) {
                return;
            }

            this.setState({showBrowsingHistoryMenu: true});
        }.bind(this), 200);
    }

    getBrowsingHistory = () => {
        const {network: {isLoggedIn}, getBrowsingHistory, getBrowsingHistoryFromCookieProductsData, itemHistory, setReduxStateFromCookie} = this.props;

        if (isLoggedIn) {
            getBrowsingHistory().then(b => {
                this.setState({browsingHistoryItems: b.response.data.views || []});
            })
        } else if (!itemHistory || !itemHistory.views || itemHistory.views.length === 0) {
            let browsingCookie = [];
            let browsingHistoryStr = getCookie('browsingHistory');

            if (browsingHistoryStr !== '') {
                try {
                    browsingCookie = JSON.parse(browsingHistoryStr);
                } catch (err) {
                    browsingCookie = [];
                }
            }

            if (browsingCookie.length > 0) {
                browsingCookie = browsingCookie.sort(function (x, y) {
                    return new Date(y.date) - new Date(x.date);
                });

                setReduxStateFromCookie(browsingCookie);
                getBrowsingHistoryFromCookieProductsData();
            }
        }
    }

    onBrowsingHistoryLeave = () => {
        setTimeout(function () {
            let browsingHistoryLinkHover = $('#lnkBrowsingHistory').is(':hover');
            let browsingHistoryMenuHover = $('#browsingHistoryMenu').length && $('#browsingHistoryMenu').is(':hover') || false;

            if (browsingHistoryLinkHover || browsingHistoryMenuHover) {
                return;
            }
            this.setState({showBrowsingHistoryMenu: false});
        }.bind(this), 200);
    }

    renderBrowsingHistoryMenu = (historyItems) => {
        historyItems = historyItems.sort(function (x, y) {
            return new Date(y.dateViewed) - new Date(x.dateViewed);
        });
        let lastFourItems = historyItems.slice(0, 4);

        const history = _.groupBy(lastFourItems, e => e.dateViewed.slice(0, 10));
        const days = Object.keys(history).map(key => this.renderBrowsingHistoryDay(key, history[key]));

        return <div className="browsing-timeLine-row">{days}</div>;
    }

    renderBrowsingHistoryDay = (dateKey, historyItems) => {
        const tiles = historyItems.map((d) =>
            this.renderBrowsingHistoryTile(d)
        );

        return (
            <div className="browsing-timeLine-wrapper">
                <div className="browsing-timeLine">
                    <div className="time-span">
                        <span className="day-label">
                            {moment(dateKey).calendar(null, momentCalendarSettings)}
                        </span>
                        <span className="line" />
                        <span className="end" />
                    </div>
                </div>
                {tiles}
            </div>
        );
    }

    goToPDP = (product) => {
        const {history} = this.props;
        this.setState({showBrowsingHistoryMenu: false});
        history.push(product.detailUrl);

        logEvent('BROWSING HISTORY CLICK', {'O-String': product.id});
    }

    renderBrowsingHistoryTile = (detail) => {
        let {product, orderDetailUrl, datePurchased} = detail;

        if (product && product.id) {
            return (
                <div className="list-item" key={`pdiv_${product.id}`}>
                    <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={() => this.onDeleteBrowsingHistoryItem(product.id)}
                    />
                    <div className="pList-image" onClick={() => this.goToPDP(product)}>
                        {product.thumbnailUrl ? (
                            <ProductImage url={product.thumbnailUrl} style={{height: '125px'}} />
                        ) : (
                            <ProductImage style={{height: '125px'}} />
                        )}
                    </div>
                    <div className="pList-title" onClick={() => this.goToPDP(product)}>{product.title}</div>
                    {orderDetailUrl && <NavLink
                        className="pList-purchaseLink"
                        to={orderDetailUrl} onClick={() => this.setState({showBrowsingHistoryMenu: false})}>
                        Purchased {moment(datePurchased).calendar(null, momentCalendarSettings)}
                    </NavLink>}
                </div>
            );
        }
    }

    goToBrowsingHistory = () => {
        const {history} = this.props;

        this.setState({showBrowsingHistoryMenu: false});
        history.push('/history');
    }

    goToBuyAgain = () => {
        const {history} = this.props;
        history.push('/buy-again');
    }

    goToRequestService = () => {
        const {history} = this.props;
        history.push('/request-service');
    }

    onDeleteBrowsingHistoryItem = (id) => {
        const {network: {isLoggedIn}, setReduxStateFromCookie, getBrowsingHistory, getBrowsingHistoryFromCookieProductsData, cart: {itemHistory}} = this.props;
        const {browsingHistoryItems: browsingHistory} = this.state;

        if (isLoggedIn) {
            _.remove(browsingHistory, item => item.product.id.toString() === id.toString());
            this.setState({browsingHistoryItems: browsingHistory});

            axios.delete('/CatalogService/api/v1/recent/' + id).then(getBrowsingHistory);
        } else {
            let browsingCookie = [];
            let browsingHistoryStr = getCookie('browsingHistory');

            if (browsingHistoryStr !== '') {
                try {
                    browsingCookie = JSON.parse(browsingHistoryStr);

                    _.remove(browsingCookie, b => b.productOString === id);
                } catch (err) {
                    // do nothing
                }

                _.remove(itemHistory.views, {product: {id: id}});

                this.setState({
                    itemHistory: itemHistory,
                    viewIsEmpty: itemHistory.views.length === 0,
                    start: 0,
                });

                setReduxStateFromCookie(browsingCookie);
                getBrowsingHistoryFromCookieProductsData();
            }
        }
    }

    onNewListFieldChange = (e) => {
        this.setState({newListName: (e.target.value || '').trim()});
    }

    render() {
        const {
            showFacilityDialog, showBrowsingHistoryMenu, invalidListName, stoppingImpersonation,
            showNewList, duplicateListName, newListName, showMyListsMenu, showQuickOrder, pageChanged, isCreditHold, alertTypeCode,showCreditHoldModal
        } = this.state;

        const {
            network: {
                isLoggedIn,
                tokenInfo: {isImpersonation, psFirstName, psLastName},
            },
            user: {facility, settings, lists, facilities},
            cart: {itemCount, loadingPricing, itemHistory},
            history,
        } = this.props;

        const facilityName = facility.facilityName || 'Your Facility';
        const hasFacility = facility.facilityName && facility.facilityName.length > 0;
        const enableTfyp = facilities.filter(x => x.showTfyp).length > 0;
        const enableOnSiteService = settings.enableOnSiteService;
        const showTeamContactCenter = settings.showTeamContactCenterPage;
        const dedicatedPhoneNumber = settings.dedicatedPhoneNumber ? 
            getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : defaultPhoneNumber;

        return (
            <div style={{marginBottom: '20px'}}>
                <div className="ps-hidden-sm ps-hidden-xs ps-hidden-md">
                    <div className="row header top-row" data-ea-zone="Header~MainMenu">
                        <div className="col-md-12">
                            {isImpersonation && <span className="authentication-message">
                                Logged in as {_.startCase((psFirstName || '').toLowerCase())}{' '}
                                {_.startCase((psLastName || '').toLowerCase())} -
                                <span className="stop-auth" onClick={this.onStopImpersonation}>
                                    {!stoppingImpersonation ? <span>Stop Impersonating</span> :
                                        <i className="fa fa-circle-o-notch fa-spin fa-fw" />}
                                </span>
                            </span>}
                            <span className="top-header-row">
                                <i className="fa fa-phone" />
                                <span style={{marginLeft: '5px'}}><a href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a></span>
                                {showTeamContactCenter && <NavLink className="top-header-row-link" to="/teamcontactcenter">Team Contact Center</NavLink>}
                                <a data-ea-exit-link="Customer Training" className="top-header-row-link" target="_blank" rel="noreferrer noopener"
                                    href="https://go.partssource.com/customer-training.html">Help</a>
                                <a data-ea-exit-link="Corporate Site" className="top-header-row-link" target="_blank" rel="noreferrer noopener"
                                    href="https://corporate.partssource.com">About Us</a>
                            </span>
                        </div>
                    </div>
                    <div className="row header" data-ea-zone="Header~MainMenu">
                        <div className="col-md-12 second-header-row">
                            <NavLink to="/">
                                <img
                                    src={`${process.env.REACT_APP_PUBLIC_URL}/images/partssourcelogo300.png`}
                                    alt="PartsSource"
                                    style={{
                                        height: '16px',
                                        width: '200px',
                                    }}
                                />
                            </NavLink>
                            <div className={isLoggedIn ? 'search-bar-wrapper mini' : 'search-bar-wrapper'}>
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search Keyword or Item Number"
                                        className="form-control"
                                        onKeyUp={this.onSearchKeyUp}
                                        onBlur={this.onInputBlur}
                                        onFocus={this.onInputFocus}
                                        ref={search => (this.search = search)}
                                    />

                                    <Button secondary={true} style={{width: '60px', borderRadius: '0px 5px 5px 0px'}}
                                        onClick={this.onSearchIconClick} tabIndex={0}>
                                        <i className="glyphicon glyphicon-search"
                                            style={{fontSize: '16px', display: 'flex', justifyContent: 'center'}} />
                                    </Button>
                                </div>
                                {this.renderSuggestions()}
                            </div>
                            <NavLink
                                to="/cart"
                                className="float-right"
                                style={{textDecoration: 'none'}}
                            >
                                <span className="cart">
                                    <i className="fa fa-shopping-cart" aria-hidden="true" />
                                    <span>Cart</span>
                                    {itemCount > 0 && <span className="item-count">
                                        ({itemCount > 99 ? '99+' : itemCount})
                                    </span>}
                                </span>
                            </NavLink>
                            {isLoggedIn && !settings.noBuy && <span id="lnkQuickOrder" className="non-chevron-link pad floatRight"
                                onClick={() => this.setState({
                                    showQuickOrder: true,
                                    pageChanged: false,
                                })}>Quick Order</span>}
                            {isLoggedIn && enableTfyp ? <span data-ea-cta-link="CTA Link Clicked" className="non-chevron-link pad floatRight" style={{paddingRight: '10px', marginRight: '10px'}} onClick={() => (isCreditHold) ? this.setState({
                                showCreditHoldModal: true,
                                pageChanged: false,
                            }) : history.push('/quote/request', {from: history.location.pathname})}> Request Quote<Subbreak /><Submenu>Parts & Depot</Submenu> </span> :
                                <span className="non-chevron-link pad floatRight" style={{opacity: 0, cursor: 'default', paddingRight: '10px', marginRight: '10px'}} data-ea-cta-link="CTA Link Clicked">
                                    Request Quote
                                </span>}
                            {isLoggedIn && enableOnSiteService && <span className="non-chevron-link pad floatRight" style={{paddingRight: '10px', marginRight: '10px'}} 
                                onClick={() => (isCreditHold) ? this.setState({
                                    showCreditHoldModal: true,
                                    pageChanged: false,
                                }) : this.goToRequestService()}>Request Service<Subbreak /><Submenu>On-Site</Submenu></span>}
                        </div>
                    </div>
                    <div className="row header menu-row" data-ea-zone="Header~Menu-Row">
                        <div className="col-md-12">
                            <BrComponent path={'header/departments-menu'}>
                                <FlyoutMenu />
                            </BrComponent>
                            {!isLoggedIn && <BrComponent path={'header/solutions-menu'}>
                                <FlyoutMenu />
                            </BrComponent>}
                            <BrComponent path={'header/pro-menu'}>
                                <FlyoutMenu />
                            </BrComponent>
                            <div id="lnkBrowsingHistory"
                                className={`browsing-history-link ${showBrowsingHistoryMenu ? ' browsing-history-link--hover' : ''}`}
                                onMouseEnter={this.onBrowsingHistoryHover}
                                onMouseLeave={this.onBrowsingHistoryLeave}
                            >
                                <span>Browsing History</span>
                            </div>
                            {isLoggedIn && <div
                                className="non-chevron-link pad sub buyItAgain"
                                onClick={this.goToBuyAgain}>
                                Buy it Again
                            </div>}
                            {
                                isLoggedIn ? (
                                    <div className="right-column">
                                        <span
                                            className={loadingPricing ? 'facility-cart disabled' : 'facility-cart'}
                                            title={facilityName}>
                                            <span className="facility" style={{paddingRight: '20px'}} onClick={this.showFacilityDialog}>
                                                <i className="fa fa-map-marker" />
                                                <span className={hasFacility ? 'facility-label' : 'facility-label empty'}>
                                                    Selected Facility
                                                </span>
                                                <span className={hasFacility ? 'facility-name' : 'facility-name empty'}>
                                                    {facilityName}
                                                </span>
                                            </span>
                                        </span>
                                        <div id="lnkMyLists" className="chevron-link pad" style={{marginRight: '0px', marginLeft: '10px'}}
                                            onMouseEnter={this.onShowMyListsMenu}
                                            onMouseLeave={this.onHideMyListsMenu}>
                                            <span>My Lists</span>
                                            {showMyListsMenu && <div className="product-dropdown-menu dropdown-menu lists">
                                                <div className="left-menu">
                                                    {lists && lists.length !== 0 ? lists.filter(x => x.groupName === 'mylist').slice(0, 10).map(key => (
                                                        <div className="left-menu-item list-item" >
                                                            <NavLink key={key.ravenId} to={`/list/${key.ravenId}`}>{key.name}</NavLink>
                                                        </div>
                                                    )) : null}
                                                    {lists !== null && lists !== undefined && lists.length > 10 && <div className="left-menu-item">
                                                        <NavLink to={`/lists?tab=MyList`} className="show-more"><div>Show More Lists</div></NavLink>
                                                    </div>}
                                                    {Object.keys(lists).length > 0 && <div className="dropdown-list-divider" />}

                                                    <div
                                                        className="left-menu-item"
                                                        style={{fontSize: '16px', paddingTop: '5px', paddingBottom: '5px'}}
                                                        onClick={() => history.push('/lists/create?origin=menu')}
                                                    >
                                                        Create New List
                                                    </div>
                                                    <div
                                                        className="list-item-default"
                                                        style={{fontSize: '16px'}}
                                                    >
                                                        <div className="left-menu-item list-item" style={{paddingTop: 5}}>
                                                            <NavLink to={`/lists?tab=MyList`}><div>My Lists</div></NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                        { this.renderUserLoggedIn()}
                                    </div>
                                ) : <div className="right-column">{this.renderLoginRegister()}</div>
                            }
                        </div>
                    </div >
                    {(isCreditHold || (alertTypeCode && isLoggedIn)) ? <OnCreditHoldBanner alertTypeCode={alertTypeCode}/> : null}
                    {showBrowsingHistoryMenu && itemHistory && itemHistory.views ?
                        <div className="row header menu-row fly-out-row show" id="browsingHistoryMenu" onMouseLeave={this.onBrowsingHistoryLeave}>
                            <div className="col-md-12">
                                {itemHistory.views.length > 0 && <div>
                                    <div className="browsing-title">
                                        Browsing History{' '}
                                        <span onClick={this.goToBrowsingHistory}>See All Browsing History</span>
                                    </div>
                                    {itemHistory.views.length > 0 &&
                                        this.renderBrowsingHistoryMenu(itemHistory.views)}
                                </div>}
                                {itemHistory.views.length == 0 && <h3> Browsing history is empty. </h3>}
                            </div>
                        </div > : null
                    }
                </div >
                <div className="ps-hidden-lg ps-hidden-xl">
                    <div className="row mobile-header" data-ea-zone="Header~MainMenu">
                        <div className="col-md-12 mobile-logo-row">
                            <i className="fa fa-bars" onClick={this.openMobileMenu} />
                            <span className="badge" onClick={this.openMobileMenu} />
                            <NavLink to="/">
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/partssourcelogo300.png`}
                                    className="mobile-logo" alt="logo" />
                            </NavLink>
                            <NavLink to="/cart" className="mobile-cart">
                                <span className="cart">
                                    <i className="fa fa-shopping-cart" aria-hidden="true" />
                                    <span>Cart</span>

                                    {itemCount > 0 ? (
                                        <span className="item-count">
                                            ({itemCount > 99 ? '99+' : itemCount})
                                        </span>
                                    ) : null}
                                </span>
                            </NavLink>
                        </div>
                        <div className="col-md-12">
                            <div className="search-bar-wrapper">
                                <div className="search-bar">
                                    <input
                                        type="text"
                                        placeholder="Search Keyword or Item Number"
                                        className="form-control"
                                        onKeyUp={this.onSearchKeyUp}
                                        onBlur={this.onInputBlur}
                                        onFocus={this.onInputFocus}
                                        ref={mobileSearch => (this.mobileSearch = mobileSearch)}
                                    />
                                    <Button secondary={true}
                                        onClick={this.onSearchIconClick}
                                        style={{
                                            width: '60px',
                                            borderRadius: '0px 5px 5px 0px',
                                            position: 'absolute',
                                        }}>
                                        <i className="glyphicon glyphicon-search" style={{fontSize: '16px', display: 'flex', justifyContent: 'center'}} />
                                    </Button>
                                </div>
                                {this.renderSuggestions()}
                            </div>
                        </div>
                        {isLoggedIn ? (
                            <div className="col-md-12">
                                <div className="full-row" />
                                <div>
                                    <div className="facility-cart">
                                        <span
                                            id="ddlMobileFacility"
                                            className="facility"
                                            onClick={() =>
                                                this.setState({showFacilityDialog: true})
                                            }
                                        >
                                            <i className="fa fa-map-marker" />
                                            <span className="facility-name">{facilityName}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    <MobileMenu onCloseMobileMenu={() => this.closeMobileMenu()} onShowNewList={() => this.setState({showNewList: true})} onLogout={this.logOut} />
                    {(isCreditHold || (alertTypeCode && isLoggedIn)) ? <OnCreditHoldBanner alertTypeCode={alertTypeCode}/> : null}
                </div >
                {
                    showFacilityDialog && (
                        <FacilityDialog onSubmit={this.onSelectFacility} onCancel={() => this.setState({showFacilityDialog: false})} />
                    )}
                {
                    pageChanged || !isLoggedIn || !showQuickOrder ? null :
                        <QuickOrder onCancel={() => this.setState({showQuickOrder: false, pageChanged: false})}
                            show={showQuickOrder} />
                }
                {
                    showNewList && (
                        <Popup
                            confirmText="Save"
                            cancelText="Cancel"
                            show={true}
                            hideButtons={false}
                            disableConfirm={!newListName}
                            onCancel={() => this.setState({showNewList: false, newListName: ''})}
                            className="add-group-dialog"
                            onConfirm={this.createNewGroup}>
                            <div>
                                <h1 style={{'font-family': 'Source Sans Pro', 'font-weight': '300', 'font-size': '24px'}}> Create New List</h1>
                            </div>
                            {
                                invalidListName &&
                                <div style={{
                                    color: '#FF0000',
                                    fontWeight: '600',
                                }}>The list name can't contain special characters. </div>
                            }
                            <TextField
                                id="groupAdd_displayName"
                                onChange={this.onNewListFieldChange}
                                label="List Name"
                                maxLength={25}
                                autoFocus={true}
                                placeholder="New List Name"
                                text={newListName}
                                className="group-name"
                                tabIndex={0}
                                showErrorMessage={duplicateListName}
                                onKeyUp={this.enterPressed} />
                        </Popup >
                    )
                }
                {showCreditHoldModal && <OnCreditHoldModal onSubmit={() => this.setState({showCreditHoldModal: false})}/>}
            </div >
        );
    }
}
