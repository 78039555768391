import React, {useContext} from 'react';
import {useHistory} from 'react-router';
import {Button} from '@partssourceinc/react-ui-core';
import {BrManageMenuButton, BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import {useDispatch} from 'react-redux';
import * as NetworkStore from 'stores/Network';
import 'less/cms/errormenu.less';

const ErrorMenu = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const component = useContext(BrComponentContext);
    const page = useContext(BrPageContext);
    const menuRef = component.getModels().menu || null;
    const menu = menuRef ? page.getContent(menuRef) : null;

    const onMenuItemClick = (linkUrl) => {
        const {setHasError} = NetworkStore.actionCreators;
        dispatch(setHasError(false));
        history.push(linkUrl);
    }

    const renderItem = (item) => {
        const linkUrl = item.getLink() && item.getLink().href || '#';
        const linkText = item.getName() || '';

        return <Button key={linkText} secondary={true} onClick={() => onMenuItemClick(linkUrl)}>{linkText}</Button>;
    }

    return menu && <div className="error-menu center-block" style={{display: 'flex', justifyContent: 'center', marginTop: '30px'}}>
        {menu && menu.items && menu.items.length && menu.items.map(i => renderItem(i))}
        {page.isPreview() && <BrManageMenuButton menu={menu} />}
    </div>;
}

export default ErrorMenu;
