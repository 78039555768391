import axios from 'axios';
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Button, brandFont, theme} from '@partssourceinc/react-ui-core';
import {isSessionStorageAvailable} from 'utility';

const Container = styled.div`
    text-align: center;
    margin: 15px;
    display: flex;
    padding-bottom: 15px;
`;

const Message = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    
    @media (max-width: ${theme.screenMDMax}) {
        flex-direction: column;
    }
`;

const MessageContent = styled.div`
    display: flex;

    .fa-exclamation {
        align-self: flex-start;
        border: 2px solid red;
        border-radius: 50%;
        color: red;
        height: 20px;
        margin-right: 5px;
        padding: 2px 10px 10px 6px;
        width: 20px;
    }
`;

const Section100 = styled.div`
    width: 100%;
    display: flex;
    line-height: initial;    
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 10px;
    background-color: rgb(239, 195, 202);
    font-weight: 700;

    @media (max-width: ${theme.screenMDMax}) {
        flex-direction: column;
    }
`;

const SecondaryLink = styled.a`
    ${brandFont('14px')};
    color: ${theme.grey3};
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    margin-left: 20px !important;
    margin-right: 20px !important;
    outline: none;

    &:hover {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }

    &:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }

    &:active:focus {
        color: ${theme.grey3};
        text-decoration: underline;
        outline: none;
    }
`;

const OnCreditHoldBanner = ({alertTypeCode}) => {
    const [banner, setBanner] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const sessionStorageAvailable = isSessionStorageAvailable();

    useEffect(() => {
        if (sessionStorageAvailable && sessionStorage.creditHoldBanner && sessionStorage.creditHoldBanner.alertMessage) {
            setBanner(sessionStorage.creditHoldBanner || {});
            setIsLoaded(true);
        } else {
            if (alertTypeCode)
                axios.get(`/ShoppingService/api/v1/company/creditholdmessage/${alertTypeCode}`).then((x) => {
                    sessionStorage.setItem('creditHoldBanner', x.data[0]);
                    setBanner(x.data[0]);
                    setIsLoaded(true);
                });
        }
    }, [alertTypeCode]);

    return (
        <>{isLoaded && <Container>
            <Section100>  
                
                <Message>
                    <MessageContent>
                        <i className="fa fa-exclamation" />
                        <div>{banner.alertMessage}</div>
                    </MessageContent>
                    <div>
                        <SecondaryLink tabIndex={0} href="/policies/termsandconditionofsale" target="_blank" rel="noreferrer noopener">
                            LEARN MORE <i className="fa fa-external-link" aria-hidden="true" style={{marginLeft: '5'}} />
                        </SecondaryLink>
                        {banner.actionButtonText && <Button style={{height: '40px'}} tabIndex={0} onClick = {() => window.location.href = `mailto:${banner.actionButtonUrl}`}>{banner.actionButtonText}</Button>}
                    </div>
                </Message>
            </Section100> 
        </Container>}</>  
    );
}
export default OnCreditHoldBanner;
