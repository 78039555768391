import {RichContent} from '@partssourceinc/react-ui-core/cms';
import React, {useState} from 'react';
import 'less/cms/readmore.less';

const ReadMore = (props) => {
    const {component} = props;
    const params = component.getParameters() || {};
    const {introText = ''} = params;
    const [expanded, setExpanded] = useState(false);

    return introText ? (<div className="read-more_container">
        <span className="read-more_intro-text" dangerouslySetInnerHTML={{__html: introText}} />
        {expanded && <RichContent {...props} />}
        <span
            onClick={() => setExpanded(!expanded)}
            className="read-more_toggle"
        >
            {expanded ? '-' : '+'} Read {expanded ? 'less' : 'more'}
        </span>
    </div>) : <span>Click here to configure Read More</span>;
}

export default ReadMore;
